/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client2.adt

import org.scalajs.dom

object PropertyPanelADT {
  sealed trait Property
  case class KeyValue(name: String, value: String, optClass: Option[String]) extends Property
  case class HelpText(text: String) extends Property
}
