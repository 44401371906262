/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client2.adt

import com.dalineage.common

import org.scalajs.dom

object TreeExplorerADT {
  trait TreeExplorerUserAction

  case class NodeSelected(node: TreeNode) extends TreeExplorerUserAction
  case class NodeOpened(node: TreeNode) extends TreeExplorerUserAction
  case class ExplorerKeyboardEvent(e: dom.KeyboardEvent) extends TreeExplorerUserAction

  trait NodeType

  case class TreeNode(
    `type`: NodeType,
    key: String,
    name: String,
    data: List[(String, String)],
    children: List[TreeNode] = List(),
    position: Option[common.adt.ADT.Range] = None
  )
}
