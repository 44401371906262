/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client.diagram

import scala.collection.immutable._
import scala.util.chaining._

import typings.gojs.{mod => go}
import org.scalablytyped.runtime.StringDictionary

import com.dalineage.client

import typings.gojs.mod.ObjectData

object LineageModelOps {
  import client.diagram.DiagramOps._
  def restrictColumn(tableKey: String, columnKey: String)(implicit diagram: go.Diagram): Unit = {
    val (visIn, _) = setLinkInVisible(tableKey, columnKey, Set(tableKey), Set(columnKey))
    val (visOut, _) = setLinkOutVisible(tableKey, columnKey, Set(), Set())
    val visibleTables = visIn ++ visOut
    diagram.model.nodeDataArray.map{ node =>
      val obj = node.asInstanceOf[go.ObjectData]
      val key = obj.get("key").get.toString
      if( !visibleTables.contains(key) ) {
        diagram.model.setDataProperty(obj, "visible", false)
      }
    }
  }

  def restrictTable(tableKey: String)(implicit diagram: go.Diagram): Unit = {
    val keys = diagram.model.nodeDataArray.map{ node =>
      val obj = node.asInstanceOf[go.ObjectData]
      (obj.get("key").get.toString, false)
    }.toMap
    val outKeys = setOutTVisible(keys, tableKey)
    val inKeys = setInTVisible(outKeys, tableKey)
    diagram.model.nodeDataArray.map{ node =>
      val obj = node.asInstanceOf[go.ObjectData]
      val key = obj.get("key").get.toString
      val visible = inKeys(key)
      if( !visible ) {
        val data = diagram.model.findNodeDataForKey(tableKey).asInstanceOf[go.ObjectData]
        diagram.model.setDataProperty(obj, "visible", false)
      }
    }
  }

  def restoreLineage()(implicit diagram: go.Diagram): Unit = {
    diagram.model.nodeDataArray.map{ node =>
      val obj = node.asInstanceOf[go.ObjectData]
      diagram.model.setDataProperty(obj, "visible", true)
    }
  }
}
