package com.dalineage.client.old.explorer

import scala.collection.immutable._


import org.scalajs.dom

import typings.gojs.{mod => go}
import scala.scalajs.js
import org.scalablytyped.runtime.StringDictionary


import com.dalineage.client
import client.old.template.ToolboxTemplate
import client.UserActions.{UserAction, ToolboxUserAction}
import client.ExplorerUserActions.{LoadBatch, LoadBatchClose, RefreshExplorer}

object ExplorerToolbox extends client.old.Toolbox {
  val model = client.old.explorer.model.ExplorerToolboxModel
}
