/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client

import scala.collection.immutable._

import org.scalajs.dom
import org.scalajs.dom.html

object Window {
  trait Window {
    val div: html.Div = dom.document.createElement("div").asInstanceOf[html.Div]
    div.style.whiteSpace = "nowrap"
    div.style.display = "inline-block" //tbd change for vertical
    div.style.verticalAlign = "top"

    def windowCount: Int

    var optHelpText: Option[String] = None
    var optHelpDiv: Option[html.Div] = None

    val helpHeight = 28

    def init(rootDiv: html.Div): Unit = {
      rootDiv.appendChild(div)
    }

    def hide(): Unit = {
      div.style.display = "none"
    }

    def show(): Unit = {
      div.style.display = "inline-block"
    }

    def isVisible(): Boolean = {
      div.style.display != "none"
    }

    def resizeContent(width: Double, height: Double): Unit

    def resize(width: Double, height: Double): Unit = {
      div.style.width = s"${width}px"
      div.style.height = s"${height}px"

      optHelpDiv.map{ helpDiv =>
        helpDiv.style.display = "block"
        helpDiv.style.border = "1px solid black"
        helpDiv.style.backgroundColor = "lightgray"
        helpDiv.style.textAlign = "center"
        helpDiv.style.verticalAlign = "middle"
        helpDiv.style.lineHeight = s"${helpHeight}px"
        updateHelpText( optHelpText.get )
      }

      val contentHeight = optHelpText.map(_ => height - helpHeight).getOrElse(height)

      resizeContent(width, contentHeight)
    }

    def updateHelpText(helpText: String): Unit = {
      optHelpDiv.getOrElse{
        val helpDiv: html.Div = dom.document.createElement("div").asInstanceOf[html.Div]
        optHelpDiv = Some(helpDiv)
        div.appendChild(helpDiv)
      }
      optHelpText = Some(helpText)
      optHelpDiv.get.innerHTML = helpText
    }
  }

  case class WindowPair(
    left: SingleWindow,
    right: Window,
  ) extends Window {

    def windowCount = left.windowCount + right.windowCount

    div.appendChild(left.div)
    div.appendChild(right.div)

    def toggleLeft(): Unit = {
      if ( left.isVisible() )
        left.hide()
      else
        left.show()
    }

    def resizeContent(width: Double, height: Double): Unit =
      val windowWidth = width / this.windowCount
      if( left.isVisible() ) {
        left.resize(windowWidth, height)
        right.resize(width - windowWidth, height)
      } else {
        right.resize(width, height)
      }
  }

  case class SingleWindow() extends Window {

    def windowCount = if( isVisible() ) 1 else 0

    val contentDiv = dom.document.createElement("div").asInstanceOf[html.Div]
    div.appendChild(contentDiv)

    def resizeContent(width: Double, height: Double): Unit = {
      contentDiv.style.width = s"${width}px"
      contentDiv.style.height = s"${height}px"
    }
  }
}
