/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client.objectbrowser

import com.dalineage.common
import common.adt.ADT

import org.scalajs.dom

import scala.concurrent.{Future, Promise}
import scala.scalajs.js
import scala.scalajs.js.annotation.JSExportTopLevel
import scala.scalajs.js.JSConverters._
import scala.util.{Success, Failure}

import upickle.default._

import scala.concurrent.ExecutionContext.Implicits.global

object IndexedDB {

  var unprocessedBatch: Map[String, String] = Map.empty

  implicit val positionRW: ReadWriter[ADT.Position] = macroRW
  implicit val rangeRW: ReadWriter[ADT.Range] = macroRW

  def openDatabase(): Future[dom.IDBDatabase] = {
    val promise = Promise[dom.IDBDatabase]()

    openDatabaseRequest(false).onComplete {
      case Success((requestOpen, _)) =>
        requestOpen.onsuccess = (_: dom.Event) => {
          val db = requestOpen.result.asInstanceOf[dom.IDBDatabase]
          println("Database opened successfully.")
          promise.success(db)
        }

        requestOpen.onerror = (_: dom.Event) => {
          println("Error opening database: " + requestOpen.error)
          promise.failure(new Exception(requestOpen.error.toString))
        }

      case Failure(e) =>
        println("Error opening database: " + e)
        promise.failure(e)
    }

    promise.future
  }

  def openDatabaseRequest(upgradeVersion:Boolean)
      : Future[(dom.IDBOpenDBRequest[dom.IDBDatabase], List[String])] = {
    val dbName = "dbimage"
    val promise = Promise[(dom.IDBOpenDBRequest[dom.IDBDatabase], List[String])]()

    dom.window.indexedDB.toOption match {
      case Some(indexedDB) =>
        val requestOpen = indexedDB.open(dbName)
        println(s"=== database opened ===")
        if (upgradeVersion) {
          requestOpen.onsuccess = (_: dom.Event) => {
            val db = requestOpen.result.asInstanceOf[dom.IDBDatabase]
            val dbVersion = db.version

            // iterate over each store and delete it
            // if the value of _keep_alive_ is less than current time
            // this is to remove old stores that are no longer needed
            val futures = db.objectStoreNames.toSeq.map { storeName =>
              if (storeName.startsWith("image_") || storeName.startsWith("sources_")) {
                val store = db.transaction(storeName, dom.IDBTransactionMode.readwrite)
                  .objectStore(storeName)
                val request = store.get("_keep_alive_")
                val promise = Promise[String]()
                request.onsuccess = (_: dom.Event) => {
                  val result = request.result.asInstanceOf[js.Dynamic]
                  if ( result == js.undefined ) {
                    promise.success("")
                  } else {
                    val keepAlive = result.timestamp
                    val now = js.Date.now()
                    println(s"Keep alive for $storeName: $keepAlive, now $now")
                    if (keepAlive.asInstanceOf[Double] + 60*10*1000 < now) {
                      promise.success(storeName)
                    } else {
                      promise.success("")
                    }
                  }
                }
                promise.future
              } else {
                Future.successful("")
              }
            }

            Future.sequence(futures).onComplete {
              case Success(storesToDelete) =>
                println(s"Existing stores to delete: $storesToDelete")
                db.close()
                val existingDbRequest = indexedDB.open(dbName, dbVersion + 1)
                println(s"=== database opened === v$dbVersion")
                println("Existing database opened successfully.")
                promise.success((existingDbRequest, storesToDelete.toList.filter(_.nonEmpty)))
              case Failure(e) =>
                println(s"Error deleting existing stores: $e")
                promise.failure(e)
            }
          }

          requestOpen.onerror = (_: dom.Event) => {
            val createDbRequrest = indexedDB.open(dbName, 1)
            println(s"=== database opened === v1")
            println("New database opened successfully.")
            promise.success((createDbRequrest, Nil: List[String]))
          }
        } else promise.success((requestOpen, Nil: List[String]))

      case None =>
        println("IndexedDB is not supported by this browser.")
        promise.failure(new Exception("IndexedDB not supported"))
    }

    promise.future
  }

  def setupDatabase(batchId: String): Future[dom.IDBDatabase] = {
    val dbName = "dbimage"
    val dbVersion = 1
    val promise = Promise[dom.IDBDatabase]()

    openDatabaseRequest(true).onComplete {
      case Success((requestOpen, storesToDelete)) =>
        requestOpen.onupgradeneeded = (event: dom.Event) => {
          val db = requestOpen.result.asInstanceOf[dom.IDBDatabase]
          val options = js.Dynamic.literal("keyPath" -> "key")
          val createOptions = options.asInstanceOf[dom.IDBCreateObjectStoreOptions]

          storesToDelete.map { storeName =>
            println(s"Deleting store $storeName")
            db.deleteObjectStore(storeName)
          }

          val imageStoreName = ObjectBrowserData.getStoreName("image", batchId)
          if (db.objectStoreNames.contains(imageStoreName)) {
            db.deleteObjectStore(imageStoreName)
          }
          val imageStore = db.createObjectStore(imageStoreName, createOptions)
          val indexOptions = js.Dynamic.literal(
            "unique" -> false,
            "multiEntry" -> true)
            .asInstanceOf[dom.IDBCreateIndexOptions]
          imageStore.createIndex("keysIndex", "keys", indexOptions)

          val sourcesStoreName = ObjectBrowserData.getStoreName("sources", batchId)
          if (db.objectStoreNames.contains(sourcesStoreName)) {
            db.deleteObjectStore(sourcesStoreName)
          }
          val sourcesStore = db.createObjectStore(sourcesStoreName, createOptions)
          sourcesStore.createIndex("keysIndex", "keys", indexOptions)

          println(s"Object stores $sourcesStoreName and $imageStoreName created successfully.")
        }

        requestOpen.onsuccess = (_: dom.Event) => {
          val db = requestOpen.result.asInstanceOf[dom.IDBDatabase]
          println("Database opened successfully.")
          promise.success(db)
        }

        requestOpen.onerror = (_: dom.Event) => {
          println("Error opening database: " + requestOpen.error)
          promise.failure(new Exception(requestOpen.error.toString))
        }

      case Failure(e) =>
        println("Error opening database: " + e)
        promise.failure(e)
    }

    promise.future
  }

  def getMultipleKeys(
        db: dom.IDBDatabase,
        storeName: String,
        keys: Seq[String]): Future[Seq[js.Dynamic]] = {
    val transaction = db.transaction(storeName, dom.IDBTransactionMode.readonly)
    val store = transaction.objectStore(storeName)

    val futures = keys.map { key =>
      val promise = Promise[js.Dynamic]()
      val request = store.get(key)

      request.onsuccess = { (e: dom.Event) =>
        val result = request.result.asInstanceOf[js.Dynamic]
        promise.success(result)
      }

      request.onerror = { (e: dom.Event) =>
        promise.failure(js.JavaScriptException(request.error))
      }

      promise.future
    }
    Future.sequence(futures)
  }

  def getMatchingKeys(
      db: dom.IDBDatabase,
      storeName: String,
      searchString: String,
      limit: Int = 10): Future[Seq[String]] = {

    val transaction = db.transaction(storeName, dom.IDBTransactionMode.readonly)
    val objectStore = transaction.objectStore(storeName)
    val index = objectStore.index("keysIndex")

    val lowerBound = searchString
    val upperBound = searchString + "\uFFFF"
    val keyRange = dom.IDBKeyRange.bound(lowerBound, upperBound, false, true)

    println(s"starting search '$searchString' in $storeName")
    val request = index.openCursor(keyRange)

    val promise = Promise[Seq[String]]()
    val results = js.Array[String]()

    import dom._

    request.onsuccess = (event: IDBEvent[IDBCursorWithValue[IDBObjectStore | IDBIndex]]) => {
      val cursor = event.target.result.asInstanceOf[IDBCursorWithValue[IDBObjectStore | IDBIndex]]
      //val cursor = request.result.asInstanceOf[dom.IDBCursorWithValue[js.Object]]
      if (cursor != null) {
        val value = cursor.value.asInstanceOf[IDBValue]
        val key = value.asInstanceOf[js.Dynamic].key.asInstanceOf[String]
        if (results.length >= limit) {
          promise.success(results.toSeq)
        } else {
          results.push(key)
          cursor.continue()
        }
      } else {
        println(s"finished search '$searchString' in $storeName, found ${results.length} matches")
        promise.success(results.toSeq)
      }
    }

    request.onerror = (e: dom.Event) => {
      promise.failure(new Exception("Error reading from database"))
    }

    promise.future
  }
}
