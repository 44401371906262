/* (c) Miroslav Semora <semora@seznam.cz> 2020-2022, all rights reserved */
package com.dalineage.common.adt

object ADT {
  import Identifiers._
  import Datatypes._

  trait ParserNode extends Product with scala.Serializable

  trait Positioned extends ParserNode {
    var position: Range = null
  }

  case class Position(line: Int, column: Int) extends Ordered[Position] with scala.Serializable {
    override def toString = s"$line|$column"

    import scala.math.Ordered.orderingToOrdered

    def compare(that: Position): Int =
      (this.line, this.column) compare (that.line, that.column)
  }

  case class Range(sourceId: Int, from: Position, to: Position) extends scala.Serializable {
    override def toString = s"$sourceId;$from-$to"
  }

  case class Keyword(name: String) extends ParserNode

  trait Expression extends ParserNode

  trait Constant extends Expression with Positioned

  case class StringConstant(value: String) extends Constant

  case class IntConstant(value: String) extends Constant

  case class FloatConstant(value: String) extends Constant

  case class BoolConstant(str: String) extends Constant

  case class ColumnReference(items: List[ReferenceItem])
      extends Expression with Positioned {
    def name: Identifier = items.last match {
      case ReferenceItem(id, None) => id
      case _                       => throw new Exception(s"This is an array reference")
    }
    def table: Option[TableReference] = items.init match {
      case Nil  => None
      case list => Some(TableReference(list))
    }
  }

  object ColumnReference {
    def apply(name: Identifier, table: Option[TableReference]): ColumnReference =
      ColumnReference(
        table.map(_.items).getOrElse(Nil) ++ (ReferenceItem(name, None) :: Nil)
      )
    def unapply(cr: ColumnReference): Some[(Identifier, Option[TableReference])] =
      Some((cr.name, cr.table))
  }

  object ArrayReference {
    def unapply(cr: ColumnReference): Option[(Identifier, Expression, Option[TableReference])] =
      cr.items.last match {
        case ReferenceItem(_, None) => None
        case ReferenceItem(name, Some(index)) => cr.items.init match {
            case Nil   => Some((name, index, None))
            case items => Some((name, index, Some(TableReference(items))))
          }
      }
  }

  // BQ: A scalar subquery must select a single column.
  case class ScalarSubquery(qte: QueryTableExpression) extends Expression

  case class UnaryNegative(expression: Expression) extends Expression

  trait BinaryOperator extends Expression {
    val operator: String
    val left: Expression
    val right: Expression
  }

  case class Null() extends Constant

  case class MultiplicativeOperator(
      operator: String,
      left: Expression,
      right: Expression) extends BinaryOperator

  case class AdditiveOperator(
      operator: String,
      left: Expression,
      right: Expression) extends BinaryOperator

  case class RelationalOperator(
      operator: String,
      left: Expression,
      right: Expression) extends BinaryOperator

  case class LogicalOperator(
      operator: String,
      left: Expression,
      right: Expression) extends BinaryOperator

  case class Not(
      expression: Expression) extends Expression

  case class Exists(
      qte: QueryTableExpression) extends Expression

  case class FunctionCall(
      name: TableReference,
      parameters: List[Expression]) extends Expression

  case class IsNull(expression: Expression) extends Expression

  case class TableFunctionCall(
      tr: TableReference,
      parameters: List[Expression],
      table: Option[TableReference],
      columns:  List[(Identifier, Datatype)]) extends FromItem

  case class TableReference(
      items: List[ReferenceItem]) extends FromItem

  case class ReferenceItem(
      identifier: Identifier,
      arrayIndex: Option[Expression])

  case class TableReferenceWildcard(
      tr: TableReference) extends FromItem

  case class SchemaReference(
      schema: Option[Identifier],
      database: Option[Identifier],
      instance: Option[Identifier]) extends ParserNode

  trait FromItem extends ParserNode with Positioned

  case class TableAlias(name: Identifier) extends ParserNode with Positioned

  case class Aliased(fi: FromItem, alias: TableAlias) extends FromItem

  case class NamedColumns(fi: FromItem, columns: List[Identifier]) extends FromItem

  sealed trait Join extends FromItem {
    val left: FromItem
    val right: FromItem
  }

  case class JoinUsing(
      left: FromItem,
      right: FromItem,
      `using`: List[Identifier]) extends Join

  case class JoinOn(left: FromItem, right: FromItem, on: Option[Expression])
      extends Join

  case class Unpivot(fi: FromItem, unpivot: UnpivotClause) extends FromItem

  case class Pivot(fi: FromItem, pivot: PivotClause) extends FromItem

  case class UnpivotItem(
      valueColumn: Identifier,
      unpivotColumns: List[Identifier],
      alias: Option[Expression])

  case class UnpivotClause(
      items: List[UnpivotItem],
      nameColumn: Identifier, //Unpivot column. Unpivoted column names goes here
      alias: Option[Identifier]) extends ParserNode

  case class PivotAggFnSpec(
      aggregateFunction: AggregateFunctionCall,
      alias: Option[Identifier]) extends ParserNode

  case class PivotClause(
      aggregateFunctionSpec: List[PivotAggFnSpec],
      forColumn: ColumnReference,
      columns: List[Expression],
      alias: Option[Identifier]) extends ParserNode

  case class WhereClause(condition: Expression) extends ParserNode

  case class GroupByItem(expression: Expression) extends ParserNode

  case class GroupByClause(items: List[GroupByItem]) extends ParserNode

  case class HavingClause(expression: Expression) extends ParserNode

  case class QualifyClause(expression: Expression) extends ParserNode

  case class OrderByItem(expression: Expression) extends ParserNode

  case class OrderByClause(items: List[OrderByItem]) extends ParserNode

  trait Statement extends ParserNode

  case class LoopControl(str: String) extends Statement

  case class CommonStatement(
    name: String,
    tableReferences: List[(TableReference, List[Identifier])],
    expressions: List[Expression]) extends Statement
      with DDLADT.DDLQuery //need to pass it to DDLBuilder for createTable calls

  object CommonStatement {
    def apply( name: String, tables: List[TableReference] )
    : CommonStatement = CommonStatement(name, tables.map((_, Nil)), Nil)
    def apply( name: String )
    : CommonStatement = CommonStatement(name, Nil, Nil)
  }

  case class StatementSequence( statements: List[Statement] ) extends Statement with Positioned

  case class BeginEndBlock(statementSequence: StatementSequence) extends Statement
      with Positioned {
    this.position = statementSequence.position
  }

  case class IfExists( // TSQL
      sq: SelectQuery,
      statement: Statement,
      not: Boolean = false)
      extends Statement

  case class IfThenElse(
      condition: Expression,
      thenBranch: StatementSequence,
      elsif: List[(Expression, StatementSequence)],
      elseBranch: Option[StatementSequence]) extends Statement with Positioned

  trait Query extends Statement with Positioned

  case class IgnoredQuery() extends Query

  case class ColumnAlias(name: Identifier) extends ParserNode

  trait Selected extends Positioned

  case class SelectedExpression(
      expression: Expression,
      alias: Option[ColumnAlias]) extends Selected

  case class SelectedWildcard(
      table: Option[TableReference],
      except: Option[List[Identifier]] = None,
      replace: Option[List[(Expression, Identifier)]] = None)
      extends Selected

  abstract class QueryTableExpression extends Query
      with FromItem {
    val withClause: List[With]
  }

  case class With(
      name: Identifier,
      columns: Option[List[Identifier]],
      qte: QueryTableExpression) extends Positioned

  trait SelectQueryModifier
  case object Distinct extends SelectQueryModifier

  case class SelectQuery(
      modifiers: Set[SelectQueryModifier],
      columns: List[Selected],
      from: List[FromItem],
      intoTable: Option[TableReference],
      intoVariable: Option[Identifier],
      where: Option[WhereClause],
      groupBy: Option[GroupByClause],
      having: Option[Expression],
      qualify: Option[Expression],
      orderBy: Option[OrderByClause],
      withClause: List[With]) extends QueryTableExpression

  case class UnionQuery(
      left: QueryTableExpression,
      right: QueryTableExpression,
      withClause: List[With],
      orderBy: Option[OrderByClause]) extends QueryTableExpression

  sealed trait TableType
  case object VIEW  extends TableType
  case object TABLE extends TableType

  case class TruncateTable(
      name: TableReference) extends Query

  case class Batch( scripts: List[Script]) extends Statement

  case class Script(
      statements: List[Statement]) extends Statement with Positioned

  case class DMLAssignment(
      column: Option[ColumnReference],
      value: Expression)

  case class UpdateQuery(
      withClause: List[With],
      table: FromItem,
      items: List[DMLAssignment],
      from: Option[List[FromItem]],
      where: Option[WhereClause]) extends Query

  case class WhenClause(
      condition: Option[Expression],
      items: List[DMLAssignment])

  case class MergeQuery(
      withClause: List[With],
      into: FromItem,
      using: FromItem,
      on: Expression,
      when: List[WhenClause],
      /*tsql*/
      output: Option[List[SelectedExpression]] = None) extends Query with FromItem

  case class DeleteQuery(
      tables: List[FromItem],
      where: Option[WhereClause]) extends Query

  case class If(
      condition: Expression,
      ifYes: Expression,
      ifNot: Expression) extends Expression

  case class InList(
      expression: Expression,
      list: List[Expression]) extends Expression

  case class Like(
      expression: Expression,
      expressions: List[Expression],
      escape_character: Option[Expression]) extends Expression

  case class Between(
      expression: Expression,
      from: Expression,
      to: Expression) extends Expression

  case class InSelect(
      expressions: List[Expression],
      ji: FromItem) extends Expression

  case class CaseExpression(
      expression: Option[Expression],
      when: List[(Expression, Expression)],
      `else`: Option[Expression]) extends Expression

  case class Cast(
      expression: Expression,
      datatype: Datatype) extends Expression

  case class Substring(
    string: Expression,
    start: Expression,
    count: Option[Expression]
  ) extends Expression

  case class Format(
      format: Expression,
      expression: List[Expression]) extends Expression

  trait AggregateFunctionCall extends Expression

  case class Sum(expression: Expression) extends AggregateFunctionCall

  case class Min(expression: Expression) extends AggregateFunctionCall

  case class Max(expression: Expression) extends AggregateFunctionCall

  case class Avg(expression: Expression) extends AggregateFunctionCall

  case class Corr(expressions: (Expression, Expression)) extends AggregateFunctionCall

  case class CovarPop(expressions: (Expression, Expression)) extends AggregateFunctionCall

  case class CovarSamp(expressions: (Expression, Expression)) extends AggregateFunctionCall

  case class RegrAvgx(expressions: (Expression, Expression)) extends AggregateFunctionCall

  case class RegrAvgy(expressions: (Expression, Expression)) extends AggregateFunctionCall

  case class RegrCount(expressions: (Expression, Expression)) extends AggregateFunctionCall

  case class RegrIntercept(expressions: (Expression, Expression)) extends AggregateFunctionCall

  case class RegrR2(expressions: (Expression, Expression)) extends AggregateFunctionCall

  case class RegrSlope(expressions: (Expression, Expression)) extends AggregateFunctionCall

  case class RegrSXX(expressions: (Expression, Expression)) extends AggregateFunctionCall

  case class RegrSXY(expressions: (Expression, Expression)) extends AggregateFunctionCall

  case class RegrSYY(expressions: (Expression, Expression)) extends AggregateFunctionCall

  case class StddevPop(expression: Expression) extends AggregateFunctionCall

  case class StddevSamp(expression: Expression) extends AggregateFunctionCall

  case class VarPop(expression: Expression) extends AggregateFunctionCall

  case class VarSamp(expression: Expression) extends AggregateFunctionCall

  case class Count(expression: Option[Expression])
      extends AggregateFunctionCall

  case class RowNumber() extends AggregateFunctionCall

  case class Rank(expressions: List[Expression]) extends AggregateFunctionCall

  case class AnalyticFunction(aggregateFunctionCall: AggregateFunctionCall, overExpressions: List[Expression]) extends Expression

  case class FunctionParameterDeclaration(
      name: Identifier,
      datatype: Datatype) extends ParserNode

  case class Extract(part: String, expression: Expression) extends Expression
}

object DDLADT {
  import ADT._
  import Identifiers._
  import Datatypes._

  trait DDLQuery extends Query

  case class DropProcedure(
      name: TableReference) extends DDLQuery

  case class CreateTableAsSelect(
      typ: TableType,
      name: TableReference,
      columns: Option[List[(Identifier, Option[Datatype])]],
      qte: QueryTableExpression) extends DDLQuery

  case class CreateTableQuery(
      table: TableReference,
      columns: List[(Identifier, Datatype)]) extends DDLQuery

  case class CreateTableLike(
      table: TableReference,
      like: TableReference,
      as: Option[QueryTableExpression]) extends DDLQuery

  case class DropTable(
      typ: TableType,
      name: TableReference) extends DDLQuery

  case class AlterTableRenameTable(
      nameFrom: TableReference,
      nameTo: TableReference) extends DDLQuery

  case class AlterTableChangeColumn(
      name: TableReference,
      column: Identifier,
      newName: Identifier,
      datatype: Datatype) extends DDLQuery

  case class AlterTableIgnored( name: TableReference ) extends DDLQuery

  case class AlterTableDropColumns(
      table: TableReference,
      columns: List[Identifier]) extends DDLQuery

  case class AlterTableSetOptions(
    typ: TableType,
    name: TableReference) extends DDLQuery

  case class AlterTableAddColumns(
      table: TableReference,
      columns: List[(Identifier, Datatype)]) extends DDLQuery

  case class CreateDatabase(name: Identifier) extends DDLQuery
  case class DropDatabase(name: Identifier) extends Statement //tbd DDLQuery

  case class CreateTableFunction(
      name: TableReference,
      returns: Option[List[(Identifier, Datatype)]],
      parameters: List[FunctionParameterDeclaration],
      as: QueryTableExpression) extends DDLQuery

}

object DMLADT {
  import ADT._
  import Identifiers._

  case class InsertValues(
      target: FromItem,
      optTargetColumns: Option[List[ColumnReference]],
      values: List[List[Expression]]) extends Query

  case class InsertSelect(
      targetTable: FromItem,
      optTargetColumns: Option[List[Identifier]],
      qte: QueryTableExpression) extends Query

  case class UpsertValues(
      targetTable: TableReference,
      optTargetColumns: Option[List[ColumnReference]],
      values: List[List[Expression]]) extends Query

  case class UpsertSelect(
      targetTable: TableReference,
      optTargetColumns: Option[List[Identifier]],
      qte: QueryTableExpression) extends Query
}

object DateTimeAST {
  import ADT._

  case class Interval(expr: Expression) extends Expression

  // Define case classes for date and time functions
  case class CurrentDate() extends Expression
  case class CurrentTimestamp() extends Expression
  case class DateAdd(date: Expression, days: Expression) extends Expression
  case class DateSub(date: Expression, days: Expression) extends Expression
  case class DateDiff(endDate: Expression, startDate: Expression) extends Expression
  case class DateFormat(date: Expression, format: Expression) extends Expression
  case class FromUnixTime(unixTimestamp: Expression, format: Expression) extends Expression
  case class ToDate(date: Expression) extends Expression
  case class Year(date: Expression) extends Expression
  case class Quarter(date: Expression) extends Expression
  case class Month(date: Expression) extends Expression
  case class Day(date: Expression) extends Expression
  case class Hour(timestamp: Expression) extends Expression
  case class Minute(timestamp: Expression) extends Expression
  case class Second(timestamp: Expression) extends Expression
  case class Now() extends Expression
  case class TimestampDiff(
    unit: Expression,
    startTimestamp: Expression,
    endTimestamp: Expression) extends Expression
  case class TimestampTzDiff(
    unit: Expression,
    startTimestamp: Expression,
    endTimestamp: Expression) extends Expression
}
