/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.common.adt

import scala.collection.immutable._

import io.circe

object TreeComponentADT {
  val chunkSize = 50000
  val rootKey = "_OBJECT_BROWSER_DATA_ROOT_"

  object ObjectType extends Enumeration {
    type ObjectType = Value

    val DBColumn = Value(1, "Database Column")
    val DBTable = Value(2, "Database Table")
    val DBSchema = Value(3, "Schema")
    val DBDatabase = Value(4, "Database")
    val DBInstance = Value(5, "Instance")

    val Source = Value(6, "Source Code")
    val Directory = Value(7, "Source Directory")

    val Query = Value(8, "SQL Query")
  }

  object PropertyName extends Enumeration {
    type PropertyName = Value

    val AutoCreated = Value(1, "Is autocreated")
    val DataType = Value(2, "Data type")
    val AnalyzerError = Value(3, "Analyzer error")
    val ParserError = Value(4, "Parser error")

    val ObjectType = Value(5, "Object type")
    val ObjectName = Value(6, "Object name")
    val ObjectKey = Value(7, "Object key")
    val CodePosition = Value(8, "Code position")

    val TableKey = Value(9, "Table key")
    val ColumnKey = Value(10, "Column key")
    val GroupKey = Value(11, "Group")

    val FromNode = Value(12, "From node/table")
    val ToNode = Value(13, "To node/table")
    val FromPort = Value(14, "From port/column")
    val ToPort = Value(15, "To port/column")
  }

  case class TreeNode(
      `type`: Int,
      key: Option[String],
      name: String,
      properties: Map[Int, String],
      children: Option[List[TreeNode]] = None,
        //if None, we don't know if it has children - loading needed when parent is expanded
        //if Some(empty list), we know it has no children (leaf) - no other loading
      position: Option[ADT.Range] = None,
  ) {

    def isLeaf: Boolean = children match {
      case None => false
      case Some(children) => children.isEmpty
    }
  }
}
