package com.dalineage.client.old.explorer.template

import scala.collection.immutable._

import typings.gojs.{mod => go}
import scala.scalajs.js
import org.scalablytyped.runtime.StringDictionary

import org.scalajs.dom
import dom.html.Div

import com.dalineage.client.old.explorer
import explorer.adt.ExplorerADT.{ BatchNode, ViewNode, DirNode}

import com.dalineage.client
import client.diagram.DiagramOps
import client.UserActions.UserAction
import client.UserActions

object BatchTreeTemplate {

  case class ClickEvent(data: StringDictionary[_]) extends UserAction

  def apply(div:Div, userActionFn: UserAction => Unit): go.Diagram = {
    val diagramUserActionFn: UserAction => Unit = { action => action match {
      case ClickEvent(data) =>
      case UserActions.KeyboardEvent(event) => userActionFn(action)
    }}

    val diagram: go.Diagram = batchTreeDiagram(div, userActionFn)
    diagram.nodeTemplate = nodeTemplate(userActionFn)
    diagram.linkTemplate = new go.Link
    diagram
  }

  def batchTreeDiagram(div: Div, userActionFn: UserAction => Unit): go.Diagram = {
    val diagram = new go.Diagram(div)

    val layout = new go.TreeLayout
    layout.alignment = go.TreeLayout.AlignmentStart
    layout.angle = 0
    layout.compaction = go.TreeLayout.CompactionNone
    layout.layerSpacing = 16
    layout.layerSpacingParentOverlap = 1
    layout.nodeIndentPastParent = 1.0
    layout.nodeSpacing = 0
    layout.setsPortSpot = false
    layout.setsChildPortSpot = false

    diagram.allowMove = false
    diagram.allowCopy = false
    diagram.allowDelete = false
    diagram.allowHorizontalScroll = false
    diagram.layout = layout

    val doKeyDownFn: js.Function2[go.InputEvent,diagram.type,Unit] = {
      case (inputEvent, param2) =>
        val e = diagram.lastInput.event.asInstanceOf[dom.KeyboardEvent]
        userActionFn(UserActions.KeyboardEvent(e))
    }
    diagram.commandHandler.set("doKeyDown", doKeyDownFn)

    diagram
  }

  def nodeTemplate(userActionFn: UserAction => Unit): go.Node = {
    val opts = typings.gojs.anon.PartialGraphObjectpstring()
    opts ++= Map(
      "ButtonBorder.fill" -> "lightgray",
      "ButtonBorder.stroke" -> "rgba(0,128,255,0.75)",
      "_buttonFillOver" -> "rgba(0,128,255,0.25)",
      "_buttonStrokeOver" -> null)

    val treeExpanderButton = go.GraphObject.make[go.Shape]("TreeExpanderButton", opts)

    val picture = new go.Picture
    picture.width = 18
    picture.height = 18
    picture.margin = new go.Margin(0, 4, 0, 0)
    picture.imageStretch = go.GraphObject.Uniform

    val picFn:scala.scalajs.js.Function2[Any,Any,Any] = {
      case (v1, v2) =>
        val objData = v2.asInstanceOf[go.GraphObject]
                    .part
                    .data
                    .asInstanceOf[go.ObjectData]
        val typ = objData.get("typ").get.toString
        typ match {
          case "ViewNode" => "images/zoom.svg"
          case "BatchNode" => "images/database.svg"
          case "DirNode" =>
            val isTreeExpanded = objData.get("isTreeExpanded").get.asInstanceOf[Boolean]
            if( isTreeExpanded ) "images/openFolder.svg" else "images/closedFolder.svg"
        }
    }
    picture.bind(new go.Binding("source", "isTreeExpanded", picFn))
    picture.bind(new go.Binding("source", "typ", picFn))

    val text = new go.TextBlock
    text.font ="9pt Verdana, sans-serif"
    text.bind( new go.Binding("text", "caption") )
    text.bind( new go.Binding("typ", "typ"))

    val horizontal = new go.Panel("Horizontal")
    horizontal.position = new go.Point(18, 0)
    horizontal.add(picture)
    horizontal.add(text)

    val nodeTemplate = new go.Node
    nodeTemplate.selectionAdorned = true
    nodeTemplate.add(treeExpanderButton)
    nodeTemplate.add(horizontal)
    nodeTemplate.bind( new go.Binding("isTreeExpanded", "isTreeExpanded").makeTwoWay())

    val clickEvent:js.Function2[go.InputEvent,nodeTemplate.type,Unit] = {
      case (inputEvent, param2) =>
        val panel: go.Panel = param2
        val data: StringDictionary[_] = panel.data.asInstanceOf[StringDictionary[_]]
        userActionFn(ClickEvent(data))
    }
    nodeTemplate.click = clickEvent

    nodeTemplate
  }
}
