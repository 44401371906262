package com.dalineage.client.old.explorer.model

import typings.gojs.{mod => go}
import scala.scalajs.js
import org.scalablytyped.runtime.StringDictionary

import com.dalineage.client
import client.old.Toolbox.ToolboxButton
import client.ExplorerUserActions._

object BatchTreeToolboxModel extends client.old.ToolboxModel {

  case object RenameViewButton extends ToolboxButton {
    val (enabled, visible, text, userAction, left) =
      (false, true, "Rename view", RenameSelectedView(), 100)
  }

  case object DeleteButton extends ToolboxButton {
    val (enabled, visible, text, userAction, left) =
      (false, true, "Delete", DeleteSelected(), 220)
  }

  case object DownloadOriginalButton extends ToolboxButton {
    val (enabled, visible, text, userAction, left) =
      (false, true, "Download original", DownloadOriginal(), 290)
  }

  val buttons = RenameViewButton :: DeleteButton :: DownloadOriginalButton :: Nil
}
