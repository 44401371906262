/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client2

import com.dalineage.common

import org.scalajs.dom
import dom.html

object PropertyPanel {

  var contentDiv: html.Div = null

  def init(div: html.Div) : Unit = {
    contentDiv = div
    contentDiv.style.backgroundColor = CSS.backgroundColor("property-page")
  }

  def setProperties(properties: Seq[adt.PropertyPanelADT.Property]): Unit = {
    contentDiv.innerHTML = getProperties(properties)
  }

  def setProperties(str: String): Unit = {
    contentDiv.innerHTML = str
  }

  def getProperties(properties: Seq[adt.PropertyPanelADT.Property]): String = {
    table(properties.map{
      case adt.PropertyPanelADT.KeyValue(name, value, optClass) => tr(name, value)
      case adt.PropertyPanelADT.HelpText(msg) => tr2(msg)
    }.mkString("\n"))
  }

  //tbd private
  def table(rows: String*): String =
    s"""<table class="table-property-window">""" + "\n" +
      rows.toList.mkString("\n") + "</tbody></table>"

  import common.adt.PropertyPanelADT.PropertyName

  //obsolete
  def tr_(
    propertyName: PropertyName.Value,
    value: String,
    id:Option[String] = None,
    bold:Boolean = false): String = tr(propertyName.toString, value, id, bold)

  private
  def tr2(text: String): String =
    s"""<tr><td rowspan=2 class="left-td-property-window">$text</td>""" + "\n"

  //tbd private
  def tr(first: String, second: String, id:Option[String] = None, bold:Boolean = false): String =
    val a = id match {
      case Some(id) => s"""<a href="#" id="$id" class="button" onclick="det('$id') ; false">....</a> """
      case None => ""
    }
    val pn = if (bold) s"""<b>$first</b>""" else first
    s"""<tr><td class="left-td-property-window">$pn</td>""" + "\n" +
    s"""<td class="right-td-property-window">$a$second</td></tr>"""
}

