/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client2.objectbrowser

import io.circe

import com.dalineage.common
import common.adt.ADT

import scala.concurrent.Future
import scala.concurrent.Promise
import scala.concurrent.ExecutionContext.Implicits.global
import scala.util.Success
import scala.util.Failure

import org.scalajs.dom
import scala.scalajs.js

import util.chaining._

object ObjectBrowserData {
  import scala.scalajs.js.JSConverters._
  import common.adt.ObjectBrowserADT.TreeNode
  import common.adt.PropertyPanelADT.ObjectType

  def separator(storeName: String): String = storeName match
    case "image" => "."
    case "sources" => "/"
    case _ => throw new Exception(s"Unknown store $storeName")

  def separatorRE(storeName: String): String = storeName match
    case "image" => "\\."
    case "sources" => "/"
    case _ => throw new Exception(s"Unknown store $storeName")

  def downloadObjectBrowserData(
      db: dom.IDBDatabase,
      user: String,
      batchId: String,
      updateHelpText: String => Unit): Future[Unit] = {

    val promise = Promise[Unit]()

    val url = s"web/image/$user/$batchId"
    val imageChunkSize = 2*common.adt.ObjectBrowserADT.chunkSize
    val sourceChunkSize = 4*common.adt.ObjectBrowserADT.chunkSize
    saveDataToIndexedDB(db, "image", batchId, url, 0, imageChunkSize, updateHelpText).onComplete {
      case Success(()) =>
        val url = s"web/sources/$user/$batchId"
        saveDataToIndexedDB(db, "sources", batchId, url, 0, sourceChunkSize, updateHelpText)
            .onComplete {
          case Success(()) =>
            promise.success(())
          case Failure(e) =>
            val msg = s"Object browser sources downloading error $e"
            println(s"$msg")
            promise.failure(new Exception(msg))
        }
      case Failure(e) =>
        val msg = s"Object browser image downloading error $e"
        println(s"$msg")
        promise.failure(new Exception(msg))
    }

    promise.future
  }


  def saveDataToIndexedDB(
      db: dom.IDBDatabase,
      storePrefix: String,
      batchId: String,
      url: String,
      lineFrom: Int,
      chunkSize: Int,
      updateHelpText: String => Unit): Future[Unit] = {

    val lineTo = lineFrom + chunkSize

    val partUrl = s"$url/$lineFrom/$lineTo"

    val promise = Promise[Unit]()

    val xhr = new dom.XMLHttpRequest()
    xhr.open("GET", partUrl, async = true)
    xhr.responseType = "text"

    println(s"loading objects data ${partUrl}")

    val storeName = IndexedDB.getStoreName(storePrefix, batchId)

    xhr.onload = (_: dom.Event) => {
      if (xhr.status == 200) {
        val lines = xhr.responseText.split("\n")
        val size = lines.size //size of lines, each object is 2 lines
        val transaction = db.transaction(storeName, dom.IDBTransactionMode.readwrite)
        val objectStore = transaction.objectStore(storeName)

        lines.grouped(2).foreach {
          case Array(key, value) =>
            val keys = key.split(separatorRE(storePrefix)).map(_.toUpperCase).toList.toJSArray
            val data = js.Dynamic.literal("key" -> key, "keys" -> keys, "value" -> value)
            objectStore.add(data)
          case _ => // Ignore incomplete pairs
        }

        transaction.oncomplete = (_: dom.Event) => {
          val msg = s"Saved ${lineTo/2/1000}k $storeName objects"
          println(msg)
          updateHelpText(msg)
          println(s"size ${size} chunkSize ${chunkSize}")
          if ( size == chunkSize ) {
            saveDataToIndexedDB(
              db, storePrefix, batchId, url, lineFrom + chunkSize, chunkSize, updateHelpText)
                .onComplete {
              case Success(()) => promise.success(())
              case Failure(e) => promise.failure(e)
            }
          } else
          promise.success(())
        }

        transaction.onerror = (event: dom.Event) => {
          val msg = s"Data saving failed"
          println(s"${msg}")
        }
      } else {
        val msg = s"Data downloading failed, status ${xhr.status}"
        println(s"msg ${msg}")
        promise.failure(new Exception(msg))
      }
    }

    xhr.send()
    promise.future
  }
}
