/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client

import org.scalajs.dom
import org.scalajs.dom.document
import org.scalajs.dom.html.Div
import org.scalajs.dom.html
import org.scalajs.dom.window
import org.scalajs.dom.KeyboardEvent
import org.scalajs.dom.console
import scala.concurrent.ExecutionContext.Implicits.global
import scala.scalajs.js
import js.JSConverters._
import js.timers._
import typings.gojs.{mod => go}

import io.circe._, io.circe.parser._, io.circe.syntax._

import com.dalineage.client

import UserActions.UserAction
import WorkspaceUserActions._
import LineageUserActions._
import ExplorerUserActions._

import com.dalineage.common
import common.adt.ExplorerDataADT._
import common.adt.DiagramADT._

import Window._
import scala.concurrent.Future
import com.dalineage.common.DiagramDataSerializer.GoJSSerializer
import scala.util.{Success, Failure}
import scala.util.chaining._
import org.scalablytyped.runtime.StringDictionary
import cats.syntax.all._

import com.dalineage.client2
import client2.DomOps

object VisualizationApp {

  val msgBox: String => Unit = { msg =>
    Console.msgBox(msg)
  }

  def main(args: Array[String]): Unit = {

    val mainDiv = document.getElementById("main").asInstanceOf[Div]
    mainDiv.innerHTML = ""
    val params = DomOps.parseQueryString()

    DomOps.sendRequest(
    dom.HttpMethod.GET, "web/current_user", { user =>
      js.Dynamic.global.user = user
      params.get("page") match {

        case Some("batchtree") =>
          val userActionFn: UserAction => Unit = { action =>
            action match
              case UserActions.KeyboardEvent(ev) => ()
              case unhandled => dom.console.warn(s"Unhandled action " + pprint.apply(unhandled))
          }

          mainDiv.style.width = s"${dom.window.innerWidth}px"
          mainDiv.style.height = s"${dom.window.innerHeight}px"

          val widthBorder = 15
          val heightBorder = 23

          val batchTreeWindow = Window.SingleWindow()
          batchTreeWindow.updateHelpText("Batch tree help")
          batchTreeWindow.init(mainDiv)
          BatchTree.init(batchTreeWindow, userActionFn)

          dom.document.addEventListener("keydown", { (event: dom.KeyboardEvent) =>
            val wsevent = UserActions.KeyboardEvent(event)
            BatchTree.userActionFn( wsevent )
          })

          loadExplorerData({ data =>
            BatchTree.open(data)
            batchTreeWindow.resize(dom.window.innerWidth - widthBorder, dom.window.innerHeight - heightBorder)
            dom.window.onresize = { (e: dom.Event) =>
              def w = dom.window.innerWidth - widthBorder
              def h = dom.window.innerHeight - heightBorder
              batchTreeWindow.resize(w, h)
            }
          })

        case None =>
          //former https://localhost/?page=workspace

          val userActionFn: UserAction => Unit = { action =>
            action match {
              case UserActions.LoadJson(url, fn) => DomOps.getJson(url, fn)
              case UserActions.LoadExplorerData(fn) => loadExplorerData(fn)
              case UserActions.KeyboardEvent(event) => ()
              case DeleteDir(user, dirs) => deleteDir(user, dirs)
              case DeleteBatch(user, batchId, None) => deleteBatch(user, batchId)
              case DeleteBatch(_, batchId, Some(viewId)) => deleteView(batchId, viewId) // TODO Selected user
              case Logout() => client2.Workspace.loginLogout()
              case _ => println(s"unhandled action " + pprint.apply(action))
            }}

          Workspace.init(mainDiv, userActionFn, params)

        case Some(_) => throw new Exception(s"assertion failed") //TBD 404
      }
    },
    noCache = true)
  }

  def reuploadAll(): Unit = {
    val reupload = dom.window.prompt("Please type exactly: reupload")
    if (reupload == "reupload") {
      DomOps.sendRequest(dom.HttpMethod.GET, "web/reupload_all", { text => msgBox(s"Reupload response: $text") })
      msgBox("Reupload request sent")
    } else msgBox("Reupload failed")
  }

  def loadExplorerData(successFn: ExplorerData => Unit): Unit = {
    val dFn: ExplorerData => Unit = { data =>
      msgBox("User directory data loaded")
      successFn(data)
    }
    DomOps.getJsonObject[ExplorerData]( "web/dashboard", dFn )
  }

  var intervalHandler: SetIntervalHandle = null

  def deleteDir(user: String, dirs: List[String]): Unit = {
    if (dirs.isEmpty) return
    val path = s"web/dir/$user?dirs=${dirs.mkString(",")}"
    msgBox(s"Sending delete dir request, path $path")
    DomOps.sendRequest(dom.HttpMethod.DELETE, path)
  }

  def deleteBatch(user: String, batchId: String): Unit = {
    val path = s"web/batch/$user/$batchId"
    msgBox(s"Sending delete batch request, batchId $batchId")
    DomOps.sendRequest(dom.HttpMethod.DELETE, path)
  }

  def renameView(batchId: String, viewId: String, newName: String): Unit = {
    val path = s"web/view/$batchId/$viewId/$newName"
    msgBox(s"Sending rename to $newName request, batchId/viewId $batchId/$viewId")
    DomOps.sendRequest(dom.HttpMethod.PATCH, path)
  }

  def reuploadBatch(user: String, batchId: String): Unit = {
    val path = s"web/reupload/$user/$batchId"
    msgBox("Sending reupload request, batchId $batchId")
    DomOps.sendRequest(dom.HttpMethod.GET, path)
  }

  def deleteView(batchId: String, viewId: String): Unit = {
    val path = s"web/view/$batchId/$viewId"
    msgBox(s"Sending delete view request, batchId/viewId $batchId/$viewId")
    DomOps.sendRequest(dom.HttpMethod.DELETE, path)
  }
}
