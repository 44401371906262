/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client2

import org.scalajs.dom

trait PopupWindow {

  var popupWindow: dom.Window = null

  val height: Int = 800
  val width: Int = 500

  val url: Option[String]

  def open(): Unit = {
    if( popupWindow == null ) {
      val openUrl = url.map(n => s"${Workspace.domain}/$n").getOrElse("")
      popupWindow = dom.window.open(openUrl, "", s"width=$width,height=$width")
    }
    popupWindow.onkeydown = { (e:dom.KeyboardEvent) =>
      if( e.keyCode == 27 ) popupWindow.close()
    }
    popupWindow.onbeforeunload = { _ =>
      popupWindow = null
    }
    popupWindow.focus()
  }
}
