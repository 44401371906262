package com.dalineage.client.old.explorer.model

import scala.collection.immutable._

import typings.gojs.{mod => go}
import scala.scalajs.js
import org.scalablytyped.runtime.StringDictionary
import typings.gojs.mod.ObjectData

import com.dalineage.client
import client.old.Toolbox.ToolboxButton
import client.ExplorerUserActions._

object ExplorerToolboxModel extends client.old.ToolboxModel {

  case object RefreshButton extends ToolboxButton {
    val (enabled, visible, text, userAction, left) = (true, true, "Refresh", RefreshExplorer(), 100)
  }

  case object LoadButton extends ToolboxButton {
    val (enabled, visible, text, userAction, left) = (false, true, "Load", LoadBatch(), 185)
  }

  case object LoadCloseButton extends ToolboxButton {
    val (enabled, visible, text, userAction, left) =
      (false, true, "Close+Load", LoadBatchClose(), 250)
  }

  case object ReuploadButton extends ToolboxButton {
    val (enabled, visible, text, userAction, left) =
      (false, true, "Reupload", ReuploadSelectedBatch(), 370)
  }

  case object CSVLineageButton extends ToolboxButton {
    val (enabled, visible, text, userAction, left) =
      (false, true, "Lineage CSV", CSVLineage(), 470)
  }

  val buttons =
    RefreshButton :: LoadButton :: LoadCloseButton :: ReuploadButton :: CSVLineageButton :: Nil
}
