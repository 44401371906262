package com.dalineage.client

import org.scalajs.dom
import com.dalineage.client
import client.UserActions.UserAction

trait ResizableElement {
  var rootNode: dom.html.Element = null
  var userActionFn: UserAction  => Unit = null

  def init(rootNode: dom.html.Element, userActionFn: UserAction => Unit): Unit = {
    this.rootNode = rootNode
    this.userActionFn = userActionFn
  }

  def resize(width:Double, height:Double): Unit = {
    rootNode.style.width = px(width)
    rootNode.style.height = px(height)
  }

  def px(d:Double): String = s"${d}px"
}

trait PopupWindow {
  var popupWindow: dom.Window = null
  val name: Option[String]
  val width = 800
  val height = 500
  lazy val url = name.map(n => s"${client.Workspace.domain}/$n").getOrElse("")
  def open(): Unit = {

    popupWindow = dom.window.open(url, "", s"width=$width,height=$height")
    popupWindow.onkeydown = { (e:dom.KeyboardEvent) =>
      if( e.keyCode == 27 ) popupWindow.close()
    }
    popupWindow.onbeforeunload = { _ =>
      popupWindow = null
    }
    popupWindow.focus()
  }
}
