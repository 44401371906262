package com.dalineage.client.old.lineage

import scala.collection.immutable._

import typings.gojs.{mod => go}
import scala.scalajs.js
import org.scalajs.dom
import org.scalablytyped.runtime.StringDictionary
import typings.gojs.mod.ObjectData

import com.dalineage.client
import client.UserActions._

import com.dalineage.client

object LineageToolbox extends client.old.Toolbox {
  import client.old.lineage.model.LineageToolboxModel._

  val model = client.old.lineage.model.LineageToolboxModel

  def updateRestrictButton(restricted: Boolean): Unit = {
    val key = RestrictLineageButton.userAction.key
    diagram.findNodeForKey(key) match {
      case n:go.Node =>
        val data = n.data.asInstanceOf[StringDictionary[_]]
        val text = if (restricted) "Restore lineage" else "Restrict lineage"
        diagram.model.setDataProperty(data, "text", text)
        updateButtons(true, true, RestrictLineageButton)
    }
  }

  def updateLineageButtons(selectedNode:Option[go.Node],selectedColumn:Option[String]): Unit = {
    selectedNode match {
      case None =>
        //no selection or column is selected
        updateButtons(!selectedColumn.isDefined, true, layoutButtons:_*)
        updateButtons(selectedColumn.isDefined, true, RestrictLineageButton)
      case Some(obj) =>
        val key = client.diagram.DiagramOps.getNodeKey(obj)
        key.startsWith("GROUP_") match {
          case true =>
            //group selected
            updateButtons(true, true, layoutButtons:_*)
            updateButtons(false, true, RestrictLineageButton)
          case false =>
            //table selected
            updateButtons(false, true, layoutButtons:_*)
            updateButtons(true, true, RestrictLineageButton)
        }
    }
  }

  def enableCodeButton(): Unit = {
    updateButtons(true, true, ShowEditorButton)
  }

  def enableSaveButton(): Unit = {
    updateButtons(true, true, SaveLineageViewButton)
  }
}
