package com.dalineage.client.old

import scala.collection.immutable._

import typings.gojs.{mod => go}
import scala.scalajs.js
import org.scalajs.dom
import org.scalablytyped.runtime.StringDictionary
import typings.gojs.mod.ObjectData

import com.dalineage.client
import client.old.explorer
import client.UserActions._

object Toolbox {
  import client.UserActions.ToolboxUserAction

  trait ToolboxButton {
    val enabled: Boolean
    val visible: Boolean
    val text: String
    val left: Double
    val userAction: ToolboxUserAction

    lazy val model: StringDictionary[js.Any] = StringDictionary(
      "enabled" -> enabled, "visible" -> visible, "text" -> text, "key" -> userAction.key,
      "location" -> new go.Point(left, 0)
    )
  }

  val toolboxHeight = 50
}

trait ToolboxModel {
  import Toolbox.ToolboxButton

  lazy val model = new go.GraphLinksModel

  val buttons:List[ToolboxButton]

  lazy val buttonModel = buttons.map( _.model )

  lazy val actions = buttons.map( _.userAction )

  def apply() = {
    val nodeDataArray:js.Array[ObjectData] = js.Array( buttons.map( _.model ):_* )
    model.nodeDataArray = nodeDataArray
    model
  }
}

trait Toolbox extends client.ResizableElement {
  var diagram: go.Diagram = null
  val model: ToolboxModel

  override
  def init(div: dom.html.Element, userActionFn: UserAction => Unit): Unit = {
    super.init(div, userActionFn)
    if( diagram != null ) { diagram.div = null ; diagram = null }
    diagram = client.old.template.ToolboxTemplate(div.asInstanceOf[dom.html.Div], model.actions, userActionFn)
    diagram.model = this.model.apply()
  }

  import Toolbox.ToolboxButton
  def updateButtons(enabled: Boolean, visible: Boolean, buttons: ToolboxButton*): Unit = {
    if ( diagram != null ) {
      buttons.foreach{ button =>
        val key = button.userAction.key
        diagram.findNodeForKey(key) match {
          case n:go.Node =>
            val data = n.data.asInstanceOf[StringDictionary[_]]
            diagram.model.setDataProperty(data, "enabled", enabled)
            diagram.model.setDataProperty(data, "visible", visible)
        }
      }
    }
  }
}

import com.dalineage.client
object WorkspaceToolbox extends Toolbox {
  import client.old.model.WorkspaceToolboxModel._
  import client.WorkspaceUserActions._
  import client.LineageUserActions._

  val model = client.old.model.WorkspaceToolboxModel

  override
  def init(div: dom.html.Element, userActionFn: UserAction => Unit): Unit = {
    super.init(div, userActionFn)
    updateButtons(true, true, ShowExplorerButton, ShowConsoleButton)
  }
}
