package com.dalineage.client.old.explorer

import scala.collection.immutable._

import org.scalajs.dom

import typings.gojs.{mod => go}
import scala.scalajs.js
import org.scalablytyped.runtime.StringDictionary

import com.dalineage.common
import common.adt.ExplorerDataADT.ExplorerData

import com.dalineage.client
import client.diagram.DiagramOps.findNodeForKey
import client.UserActions.UserAction
import client.ExplorerUserActions._
import client.UserActions


object BatchTree {

  var diagram: go.Diagram = null

  var userActionFn: UserAction => Unit = null

  def open(explorerData: ExplorerData): Unit = {
    diagram.model = model.BatchTreeModel(explorerData)
  }

  def init(div: dom.html.Div, userActionFn: UserAction => Unit): Unit = {
    val templateUserActionFn: UserAction => Unit = { action => action match {
      case template.BatchTreeTemplate.ClickEvent(data) =>
        userActionFn(UserActions.Explorer.ExplorerNodeSelected(Some(data)))
      case _ => userActionFn(action)
    }}
    diagram = template.BatchTreeTemplate(div, templateUserActionFn) ;
    this.userActionFn = userActionFn
  }

  def selectFirst(): Unit = selectNode("public")

  def findTreeParentNode(node: go.Node): Option[go.Node] =
    node.findTreeParentNode() match {
      case null => None
      case n:go.Node => Some(n)
    }

  def selectNode(key: String): Unit = {
    val node = findNodeForKey(key)(diagram)
    selectNode(node)
  }

  def selectNode(node: go.Node): Unit = {
    diagram.select(node)
    val data = node.data.asInstanceOf[StringDictionary[js.Any]]
    userActionFn(UserActions.Explorer.ExplorerNodeSelected(Some(data)))
  }

  def selectedNode(): go.Node = {
    diagram.selection.first() match {
      case n:go.Node => n
      case e @ _ => throw new Exception(s"selected node $e")
    }
  }

  def colapseSelected(): Unit = {
    val node = selectedNode()
    if( node.isTreeExpanded ) {
      diagram.commandHandler.collapseTree(node)
    }
  }
  def expandSelected(): Unit = {
    val node = selectedNode()
    if( !node.isTreeExpanded ) {
      diagram.commandHandler.expandTree(node)
    }
  }

  def roots(): js.Array[String] = {
    diagram.model.nodeDataArray
      .filter{ obj =>
        val parent = obj.get("parent")
        parent == Some(js.undefined) }
      .map{ obj => obj.get("key").get.toString }
  }

  def children(key: String): js.Array[String] = {
    diagram.model.nodeDataArray.filter { obj =>
      val parent = obj.get("parent")
      parent == Some(key)
    }.map{ obj => obj.get("key").get.toString }
  }

  def siblings(node: go.Node): js.Array[String] = {
    findTreeParentNode(node) match {
      case None => roots()
      case Some(n) => children(n.key.toString)
    }
  }

  def parentDown(node:go.Node): Option[go.Node] = {
    val par = findTreeParentNode(node)
    par match {
      case None =>
        siblingDown(node)
      case Some(parentNode) =>
        val ch = children(parentNode.key.toString)
        assert(ch.size > 0)
        ch.size match {
          case 1 =>
            parentDown(parentNode)
          case _ =>
            val index = ch.indexOf(node.key.toString)
            if ( index + 1 < ch.size ) {
              val nextNodeKey = ch(index+1)
              Some(findNodeForKey(nextNodeKey)(diagram))
            } else {
              parentDown(parentNode)
            }
        }
    }
  }

  def siblingDown(node: go.Node): Option[go.Node] = {
    val sibls = siblings(node)
    val pos = sibls.indexOf(node.key.toString)
    if (pos + 1 < sibls.size) {
      val selectKey = sibls(pos+1)
      Some(findNodeForKey(selectKey)(diagram))
    } else None
  }

  def lastChildDown(node: go.Node): go.Node =
      node.isTreeExpanded match {
        case false => node
        case true =>
          val ch = children(node.key.toString)
          ch.size match {
            case 0 => node
            case _ =>
              val lastChild = findNodeForKey(ch.last)(diagram)
              lastChildDown(lastChild)
          }
      }

  def siblingUp(node: go.Node): Option[go.Node] = {
    val sibls = siblings(node)
    val pos = sibls.indexOf(node.key.toString)
    if (pos > 0) {
      val selectKey = sibls(pos-1)
      Some(findNodeForKey(selectKey)(diagram))
    } else None
  }

  def keyUp(): Unit = {
    siblingUp(selectedNode()) match {
      case Some(node) =>
        selectNode(lastChildDown(node))
      case None =>
        findTreeParentNode(selectedNode()) match {
          case Some(node) => selectNode(node)
          case None => //we are on the top
        }
    }
  }

  def keyDown(): Unit = {
    val node = selectedNode()
    val key = node.key.toString

    if( node.isTreeExpanded ) {
      children(key).size match {
        case 0 =>
          parentDown(selectedNode()).map{ node =>
            selectNode(node)
          }
        case _ =>
          selectNode(children(key).head)
      }
    } else {
      siblingDown(selectedNode()).map{ node =>
        selectNode(node)
      }
    }
  }
}
