/* (c) Miroslav Semora <semora@seznam.cz> 2020-2022, all rights reserved */
package com.dalineage.common.adt

import scala.collection.immutable._

import io.circe._
import io.circe.Codec

import java.io.File

object ExplorerDataADT {

  import BatchInfoADT.BatchInfo

  case class BatchView(
      batchId: String,
      viewId: String
  ) derives Codec.AsObject {
    def fileName = s"$batchId.$viewId.view"
  }

  object BatchView {
    def apply(fileName: String): BatchView =
      fileName.split("\\.").toList match {
        case batchId :: viewId :: "view" :: Nil =>
          BatchView(batchId, viewId)
        case m @ _ => throw new Exception(s"Invalid batch file $fileName $m")
      }
  }

  sealed trait ExplorerNode derives Codec.AsObject

  case class ProcessedBatch(
      id: String,
      info: BatchInfo,
      views: List[BatchView]) extends ExplorerNode derives Codec.AsObject

  case class ExplorerDir(
      name: String,
      nodes: List[ExplorerNode]) extends ExplorerNode derives Codec.AsObject

  case class ExplorerData(
      dirs: List[ExplorerDir]) derives Codec.AsObject

}
