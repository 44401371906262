/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client2

import scala.scalajs.js
import org.scalablytyped.runtime.StObject
import typings.gojs.{mod => go}
  import org.scalajs.dom
  import dom.{
    IDBCreateObjectStoreOptions,
    IDBCreateIndexOptions
  }
object IDBDatabaseTS {
  // IDBDatabase event types
  @js.native
  trait ErrorEventTS extends go.ObjectData {
    val name: js.UndefOr[String] = js.native
    val message: String = js.native
    val code: js.UndefOr[Int] = js.native
  }

  object ErrorEventTS {
    @scala.inline
    def apply(message: String): ErrorEventTS =
      js.Dynamic.literal("message" -> message).asInstanceOf[ErrorEventTS]

    @scala.inline
    implicit class ErrorEventMutableBuilder[Self <: ErrorEventTS](val t: Self) extends AnyVal {
      @scala.inline
      def setName(value: String): Self = StObject.set(t, "name", value.asInstanceOf[js.Any])
      @scala.inline
      def setName: Self = StObject.set(t, "name", js.undefined)

      @scala.inline
      def setMessage(value: String): Self = StObject.set(t, "message", value.asInstanceOf[js.Any])

      @scala.inline
      def setCode: Self = StObject.set(t, "code", js.undefined)
      @scala.inline
      def setCode(value: Int): Self = StObject.set(t, "code", value.asInstanceOf[js.Any])
    }
  }

  object IDBCreateObjectStoreOptions {
    @scala.inline
    def apply(keyPath: String, autoIncrement: Boolean = false): IDBCreateObjectStoreOptions = js.Dynamic.literal(
      "keyPath" -> keyPath,
      "autoIncrement" -> autoIncrement
    ).asInstanceOf[IDBCreateObjectStoreOptions]
  }

  object IDBCreateIndexOptions {
    @scala.inline
    def apply(unique: Boolean = false): IDBCreateIndexOptions = js.Dynamic.literal(
      "unique" -> unique
    ).asInstanceOf[IDBCreateIndexOptions]
  }
}
