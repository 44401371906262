/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client

import com.dalineage.common
import com.dalineage.client

import org.scalajs.dom

import client.UserActions._
import client.UserActions.Explorer._
import client.Window

object PropertyPanel {

  val propertyWindow = Window.SingleWindow()

  propertyWindow.updateHelpText( "Select object to see properties" )
  propertyWindow.contentDiv.innerHTML = "property window"
  propertyWindow.contentDiv.setAttribute("class", "property-page")
  propertyWindow.contentDiv.id = "propertyWindow"

  propertyWindow.contentDiv.style.overflowX = "auto"

  propertyWindow.contentDiv.addEventListener("myCustomEvent", (e: dom.CustomEvent) => {

    val popupWindow = dom.window.open("", "_blank", "width=1000,height=250")

    // Check if the popup was blocked
    if (popupWindow == null || popupWindow.closed) {
      dom.window.alert("Popup blocked by browser!")
    }

    val id = e.detail.toString
    val textContent = dom.document
      .getElementById(id).asInstanceOf[dom.html.Element]
      .parentElement.textContent

    // Write text to the popup window
    val popupDocument = popupWindow.document
    val body = popupDocument.body
    val paragraph = popupDocument.createElement("p").asInstanceOf[dom.html.Paragraph]
    paragraph.textContent = textContent
    body.appendChild(paragraph)


    // Add an event listener to close the popup when Esc key is pressed
    popupWindow.addEventListener("keydown", { (event: dom.KeyboardEvent) =>
      if (event.key == "Escape") {
        popupWindow.close()
      }
    })

    e.stopPropagation()
  })

  def setProperties(str: String): Unit = {
    propertyWindow.contentDiv.innerHTML = str
  }

  def table(rows: String*): String =
    s"""<table class="table-property-window">""" + "\n" +
      rows.toList.mkString("\n") + "</tbody></table>"

  import common.adt.TreeComponentADT.PropertyName

  def tr_(
    propertyName: PropertyName.Value,
    value: String,
    id:Option[String] = None,
    bold:Boolean = false): String = tr(propertyName.toString, value, id, bold)

  //obsolete
  def tr(first: String, second: String, id:Option[String] = None, bold:Boolean = false): String =
    val a = id match {
      case Some(id) => s"""<a href="#" id="$id" class="button" onclick="det('$id') ; false">....</a> """
      case None => ""
    }
    val pn = if (bold) s"""<b>$first</b>""" else first
    s"""<tr><td class="left-td-property-window">$pn</td>""" + "\n" +
    s"""<td class="right-td-property-window">$a$second</td></tr>"""
}

