/* (c) Miroslav Semora <semora@seznam.cz> 2020-2022, all rights reserved */
package com.dalineage.client

import scala.collection.immutable._

import com.dalineage.common
import common.adt.DiagramADT._

import com.dalineage.client

import UserActions._
import WorkspaceUserActions._
import LineageUserActions._

import org.scalajs.dom
import dom.Window
import dom.html.Div

import typings.gojs.{mod => go}

import com.dalineage.client

object Workspace {

  val location = dom.window.location
  val domain = s"${location.protocol}//${location.hostname}:${location.port}"

  import client.Window
  import scala.scalajs.js
  import dom.html
  import dom.document
  import dom.window

  import client.objectbrowser.ObjectBrowser

  import PropertyPanel.{table,tr}
  import common.adt.TreeComponentADT.PropertyName

  def a(txt: String, code:Int, tooltip: String): String =
    s"""<a class="button" href="javascript:but($code);">$txt<span class="tooltiptext">$tooltip</span></a>"""

  def init(rootDiv: html.Div, userActionFn: UserAction => Unit, queryParams: Map[String, String]): Unit = {

    ObjectBrowser.objectBrowserWindow.hide()

    val help = "Use <b>arrow keys</b>, <b>Enter/ESC</b>, <b>Space</b> to navigate diagram."
    val lineageWindow: Window.WindowPair = Window.WindowPair(client.Editor.codeWindow, diagram.GoJSDiagram.diagramWindow)
    lineageWindow.updateHelpText(help)


    val loginAction = s"${js.Dynamic.global.user}" match
      case "public" => "login"
      case _ => "logout"
    val user = s"Logged in user: ${js.Dynamic.global.user} " +
     s"Pres <b>l</b> to ${a(loginAction,76, "Login or logout")}"

    val objectBrowserPair =
      Window.WindowPair(ObjectBrowser.objectBrowserWindow, lineageWindow)

    val workspaceWindow = Window.WindowPair(
      BatchTree.batchTreeWindow,
      Window.WindowPair(
        PropertyPanel.propertyWindow,
        objectBrowserPair,
      ),
    )
    val pAction = client.Workspace.a(
      "show/hide property page", 80, "Press to show or hide the selected object's properties")
    val bAction = client.Workspace.a(
      "show/hide object browser", 66, "Press to show object browser")
    val sAction = client.Workspace.a(
      "search", 83, "Press to show search form of object browser")
    val oAction = client.Workspace.a(
      "show/hide opening dialog", 79, "Press to show or hide the batch navigation tree")
    val cAction = client.Workspace.a(
      "Show/Hide code", 67, "Show or hide source code of currently loaded batch")
    val wAction = client.Workspace.a(
      "Old Workspace", 87, "Switch back to old workspace")
    val helpText = s"Press <b>p</b> to $pAction, <b>b</b> to $bAction <b>o</b>" +
     s" to $oAction, <b>s</b> to $sAction, <b>c</b> to $cAction, <b>w</b>:go to $wAction"
    workspaceWindow.right.updateHelpText(helpText)

    val uAction = a("Upload", 85, "Show upload dialog with 3 upload format options")
    val gAction = a("view console", 71, "View logging console - in new window")
    workspaceWindow.updateHelpText(
      s"$user, Press <b>u</b> to $uAction, <b>g</b> to $gAction. " +
      "<b>Send feedback</b> to <span>in</span><span>fo</span><span>(o)</span><span>dali</span><span>neage(.)com</span>"
    )

    workspaceWindow.div.id = "workspaceWindow"

    def focusObjectBrowser(): Unit = {
      if (ObjectBrowser.objectBrowserWindow.isVisible()) {
        TreeComponent.focus()
      } else {
        focusBatchTree()
      }
    }

    def focusBatchTree(): Unit = {
      if( BatchTree.batchTreeWindow.isVisible() ) {
        BatchTree.batchTreeWindow.contentDiv.focus()
      } else {
        val canvas = document.getElementsByTagName("canvas")(0)
          .asInstanceOf[html.Canvas]
        canvas.focus()
      }
    }

    def resizeWorkspaceWindow(): Unit = {
      val widthBorder = 15
      val heightBorder = 23
      def windowWidth = window.innerWidth - widthBorder
      def windowHeight = window.innerHeight - heightBorder
      workspaceWindow.resize(windowWidth, windowHeight)
    }

    val workspaceUserActionFn: UserAction => Unit = { action => action match {
      case UserActions.KeyboardEvent(event) if  event.keyCode == 85 => //u
        UploadDialog.open()
      case UserActions.KeyboardEvent(event) if  event.keyCode == 71 => //g
        Console.open()
      case UserActions.KeyboardEvent(event) if  event.keyCode == 66 && !event.ctrlKey =>  //b
        objectBrowserPair.toggleLeft()
        resizeWorkspaceWindow()
        focusObjectBrowser()
      case UserActions.KeyboardEvent(event) if  event.keyCode == 67 && !event.ctrlKey =>  //c
        lineageWindow.toggleLeft()
        resizeWorkspaceWindow()
      case UserActions.KeyboardEvent(event) if  event.keyCode == 87 && !event.ctrlKey =>  //w
        dom.window.location.replace("/?page=old")
      case UserActions.KeyboardEvent(event) if event.keyCode == 80 => //p
        workspaceWindow.right.asInstanceOf[Window.WindowPair].toggleLeft()
        resizeWorkspaceWindow()
      case UserActions.KeyboardEvent(event) if event.keyCode == 83 => //s
        SearcheableComponent.toggleSearch()
        event.preventDefault()
      case UserActions.KeyboardEvent(event) if  event.keyCode == 79 => //o
        workspaceWindow.toggleLeft()
        resizeWorkspaceWindow()
        focusBatchTree()
      case client.diagram.GoJSDiagram.ColumnSelected(optData) =>
        optData.map{ dataWithPanel =>
          val data = dataWithPanel._1
          val key = data("key").toString
          val tablekey = data("tablekey").toString
          val typ = "Projection column"
          val name = data("name").toString
          val properties = table(
            tr( PropertyName.ObjectType.toString, typ ),
            tr( PropertyName.ObjectName.toString, name ),
            tr( PropertyName.TableKey.toString, tablekey ),
            tr( PropertyName.ColumnKey.toString, key ),
          )
          PropertyPanel.setProperties( properties )
        }
      case diagram.GoJSDiagram.DiagramNodeSelected(optData) =>
        optData.map{ data =>
          val key = data("key").toString
          val typ = data("type").toString
          val caption = data("caption").toString
          val group = data.get("group").map(_.toString).getOrElse("-")
          val properties = table(
            tr( PropertyName.ObjectType.toString, typ ),
            tr( PropertyName.ObjectKey.toString, key ),
            tr( PropertyName.ObjectName.toString, caption ),
            tr( PropertyName.GroupKey.toString, group ),
          )
          PropertyPanel.setProperties( properties )
        }
      case diagram.GoJSDiagram.DiagramLinkSelected(Some(link)) =>
        val fromKey = link.fromNode.key.toString
        val toKey = link.toNode.key.toString
        val fromPort = link.fromPort match
          case p:go.Panel => s"${p.portId}"
          case _ => "?"
        val toPort = link.toPort match
          case p:go.Panel => s"${p.portId}"
          case _ => "?"
        val properties = table(
          tr( PropertyName.ObjectName.toString, "Link" ),
          tr( PropertyName.FromNode.toString, fromKey ),
          tr( PropertyName.ToNode.toString, toKey ),
          tr( PropertyName.FromPort.toString, fromPort ),
          tr( PropertyName.ToPort.toString, toPort ),
        )
        PropertyPanel.setProperties( properties )
      case UserActions.KeyboardEvent(event) if event.keyCode == 76 && !event.ctrlKey => // l
        userActionFn( Logout() )
      case _ => userActionFn(action)
    }}

    BatchTree.init(BatchTree.batchTreeWindow, workspaceUserActionFn)


    workspaceWindow.div.addEventListener("keydown", { (event: dom.KeyboardEvent) =>
      val wsevent = UserActions.KeyboardEvent(event)
      workspaceUserActionFn( wsevent )
      event.stopPropagation()
    })
    PropertyPanel.propertyWindow.contentDiv.tabIndex = 2
    PropertyPanel.propertyWindow.contentDiv.addEventListener("keydown", {
      (event: dom.KeyboardEvent) =>
        val wsevent = UserActions.KeyboardEvent(event)
        workspaceUserActionFn( wsevent )
    })

    userActionFn(
      LoadExplorerData({ data =>
        Console.msgBox("Explorer data loaded, opening tree explorer")
        BatchTree.open(data)

        queryParams.get("user").flatMap(user => queryParams.get("id").map(id => user -> id))
          .fold
          (BatchTree.selectPublic())
          (BatchTree.selectNodeByQuery)

        focusBatchTree()
      })
    )


    queryParams.get("user").flatMap(user => queryParams.get("id").map(id => user -> id))
      .map{ case (user, id) =>
        ObjectBrowser.open(
          user,
          id,
          workspaceUserActionFn,
          ObjectBrowser.objectBrowserWindow)
        diagram.GoJSDiagram.open(user, id, workspaceUserActionFn)
        client.Editor.init(workspaceUserActionFn)
        queryParams.get("source").fold
          ( Editor.open(user, id, "0") )
          ( Editor.open(user, id, _))
      }


    workspaceWindow.init(rootDiv)
    dom.window.onresize = { (e: dom.Event) => resizeWorkspaceWindow() }
    resizeWorkspaceWindow()
  }
}
