/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */

package com.dalineage.client

import org.scalajs.dom
import cats.syntax.all._
import scala.util.chaining._

object SessionStorage {
  type CurrentSessionId = String
  type SelectedUserName = String
  type SelectedBatchId = String
  type SelectedViewId = String

  trait SessionStorageKey {
    def keyStr: String = this match {
      case SessionId => "sessionId"
      case BatchId => "batchId"
      case ViewId => "viewId"
      case UserName => "userName"
    }
  }

  case object SessionId extends SessionStorageKey
  case object BatchId extends SessionStorageKey
  case object ViewId extends SessionStorageKey
  case object UserName extends SessionStorageKey

  private def setItem(key: SessionStorageKey, value: String): Option[Unit] =
    dom.window.sessionStorage.setItem(key.keyStr, value)
      .pipe(Option(_))

  private def getItem(key: SessionStorageKey): Option[String] =
    dom.window.sessionStorage.getItem(key.keyStr)
      .pipe(Option(_))

  private def removeItem(key: SessionStorageKey): Option[Unit] =
    dom.window.sessionStorage.removeItem(key.keyStr)
      .pipe(Option(_))

  def setSessionId(): Option[CurrentSessionId] =
    Option(java.util.UUID.randomUUID().toString)
      .flatMap(setItem(SessionId, _))
      .>>(getItem(SessionId))

  def getSessionId: Option[CurrentSessionId] =
    getItem(SessionId)
      .recoverWith(_ => setSessionId())

  def removeSessionId(): Option[Unit] =
    removeItem(SessionId)

  def setBatchInfo(user: SelectedUserName, batchId: SelectedBatchId): Option[(SelectedUserName, SelectedBatchId)] =
    setItem(UserName, user)
      .>> (setItem(BatchId, batchId))
      .>> (getBatchInfo)

  def getBatchInfo: Option[(SelectedUserName, SelectedBatchId)] =
    ((userName: SelectedUserName) => (batchId: SelectedBatchId) => userName -> batchId)
    .pure[Option]
    .<*>(getItem(UserName))
    .<*>(getItem(BatchId))

  def setViewInfo(viewId: SelectedViewId, batchId: SelectedBatchId): Option[(SelectedViewId, SelectedBatchId)] =
    setItem(BatchId, batchId)
      >> setItem(ViewId, viewId)
      >> getViewInfo

  def getViewInfo: Option[(SelectedViewId, SelectedBatchId)] =
    ((viewId: SelectedViewId) => (batchId: SelectedBatchId) => viewId -> batchId)
    .pure[Option]
    .<*>(getItem(ViewId).recoverWith(_ => Some("last")))
    .<*>(getItem(BatchId))
}