/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client

import org.scalajs.dom
import io.circe._, io.circe.parser._, io.circe.syntax._

import scala.concurrent.ExecutionContext.Implicits.global

object DomOps {
  def parseQueryString(): Map[String, String] = {
    dom.window.location.search
      .drop(1) // Remove the leading question mark
      .split("&")
      .map { param =>
        val parts = param.split("=")
        val key = parts(0)
        val value = parts(1)
        (key, value)
      }
      .toMap
  }

  def postJson(content: String, path: String): Unit = {
    val httpHeaders = new dom.Headers()
    httpHeaders.append("Content-Type", "application/json")
    httpHeaders.append("Accept", "text/plain")

    val init = new dom.RequestInit {
      method = dom.HttpMethod.POST
      headers = httpHeaders
      body = content
    }
    dom.fetch(path, init).toFuture
      .flatMap(res => res.ok match
        case true => res.text().toFuture.map(Console.msgBox)
        case false => res.text().toFuture.map(text => Console.msgBox(s"Faild to fetch ${res.`type`} ${res.url}: ${res.status} - ${res.statusText} ${text}"))
      )
  }

  def getFile(path: String, successFn: String => Unit): Unit =
    sendRequest(dom.HttpMethod.GET, path, successFn)

  def getJsonObject[T](path: String, successFn: T => Unit)(implicit d:Decoder[T]): Unit = {
    val fn: Json => Unit = { json =>
      json.as[T] match
        case Left(err) => Console.msgBox(s"Error decoding JSON: " + "\n" + err)
        case Right(t) => successFn(t)
    }
    DomOps.getJson(path, fn)
  }

  def getJson(path: String, successFn: Json => Unit): Unit = {
    val fn: String => Unit = { responseText =>
      val contentDiv = parse(responseText) match {
        case Left(err) => Console.msgBox(s"Error decoding JSON: " + "\n" + err)
        case Right(json) => successFn(json)
      }
    }
    DomOps.getFile(path, fn)
  }

  def sendRequest(
    httpMethod: dom.HttpMethod.GET.type | dom.HttpMethod.DELETE.type | dom.HttpMethod.PATCH.type ,
    path: String,
    successFn: String => Unit = Console.msgBox,
    failureFn: dom.Response => String => Unit = res => text => Console.msgBox(s"Faild to fetch ${res.url}: ${res.status} - ${res.statusText} ${text}"),
    noCache: Boolean = false,
    httpHeaders: dom.Headers = new dom.Headers()
  ): Unit = {
    if (!httpHeaders.has("Accept")) {
      httpHeaders.append("Accept", "text/plain")
    }
    if (noCache) {
      httpHeaders.append("Cache-Control", "no-cache, no-store")
    }

    val init = new dom.RequestInit {
      method = httpMethod
      headers = httpHeaders
    }

    dom.fetch(path, init).toFuture
      .flatMap(res => res.ok match
        case true => res.text().toFuture.map(successFn)
        case false => res.text().toFuture.map(failureFn(res))
      )
  }
}
