/* (c) Miroslav Semora <semora@seznam.cz> & co. 2020-2023, all rights reserved */
package com.dalineage.client

import org.scalajs.dom

object CSS {
  val styleSheet = dom.document.styleSheets(0).asInstanceOf[dom.CSSStyleSheet]

  def cssStyleRule(elementName: String): Option[dom.CSSStyleRule] =
    styleSheet.cssRules
      .filter(_.asInstanceOf[dom.CSSStyleRule].selectorText == s".$elementName")
      .map(_.asInstanceOf[dom.CSSStyleRule])
      .headOption

  def cssStyle(elementName: String): Option[dom.CSSStyleDeclaration] =
    cssStyleRule(elementName).map(_.style)

  def backgroundColor(elementName: String): String =
    cssStyle(elementName).map(_.backgroundColor).getOrElse("white")
}
