/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.common.adt

import io.circe.Codec

object Dialects {

  sealed trait Dialect

  case object Oracle   extends Dialect
  case object BigQuery extends Dialect
  case object TSQL     extends Dialect
  case object Snowflake extends Dialect
  case object Teradata extends Dialect
  case object Impala   extends Dialect

  object Dialect {
    def apply(str: String): Dialect = str.toLowerCase match {
      case "oracle"   => Oracle
      case "bigquery" => BigQuery
      case "tsql"     => TSQL
      case "snowflake" => Snowflake
      case "teradata" => Teradata
      case "impala"   => Impala
      case _          => throw new Exception(s"Invalid dialect: $str")
    }
  }
}

object BatchInfoADT {
  import Dialects._

  case class BatchInfoQueryLineage(
      expandTables: Boolean = false,
      expandGroups: Boolean = false,
      groupExpandLevel: Int = 0,
      showQueryLineage: Boolean = false,
      constants: BatchInfoQLConstants = BatchInfoQLConstants(),
      showColumnStruct: String = null,
      groupCodeBlocks: Boolean = false,
      groupProcedures: Boolean = false,
      showColumnTypes: Boolean = false,
      showExpressions: String = null,
      showUnresolvedVariants: Boolean = false,
      showVariables: Boolean = false,
      showProcedureLineage: Boolean = false,
      showUnusedTableReferences: Boolean = true,
      showConditionBlocks: Boolean = false,
  ) derives Codec.AsObject

  case class BatchInfoAnalyzer(
      strict: Boolean = false,
      skipParsingErrors: Boolean = false,
      skipAnalyzerErrors: Boolean = false,
      skipDatatypeErrors: Boolean = false,
      skipUnresolvedHostVariables: Boolean = false,//tbd teradata only
      showSemidirectRelations: Boolean = false,
      extraRelations: List[ExtraRelation] = Nil,
      showIndirectRelations: Boolean = false,
      readDbModelViewColumns: Boolean = false, //tbd write doc
  ) derives Codec.AsObject

  case class BatchInfoQLConstants(
      showConstantRelations: Boolean = false,
      groupConstants: String = "SUBQUERY")

  case class BatchInfoDBCLConstants(
      showConstantRelations: Boolean = false)

  case class BatchInfoDatabaseImage(
      expandTables: Boolean = false,
      expandGroups: Boolean = false,
      groupExpandLevel: Int = 0,
      showDatabaseImage: Boolean = false,
      showColumnLineage: Boolean = false,
      groupTables: String = "NONE",
      showTableVersions: String = "NONE",
      constants: BatchInfoDBCLConstants = BatchInfoDBCLConstants(),
  ) derives Codec.AsObject

  case class BatchInfoOutput(
      generateGoJsOutput: Boolean = false,
      generateGoJsView: Boolean = false,
      generateDawisoOutput: Boolean = false,
      saveObjectBrowserData: Boolean = false,
      generateEvalTreeDump: Boolean = false,
      saveSourceQueries: Boolean = false,
      traceColumnLineage: Option[String] = None,
      dumpGraph: Option[String] = None,
      dumpLineage: Option[String] = None,
      dumpParsingErrors: Option[String] = None,
      dumpAnalyzerErrors: Option[String] = None,
      dumpEvalTree: Option[String] = None,
      dumpInitialEvalTree: Option[String] = None,
      dumpAstDir: Option[String] = None,
  ) derives Codec.AsObject

  case class BatchInfoAuthorization(
      authUsers: List[String] = Nil
  ) derives Codec.AsObject

  case class BatchInfoTSQLOptions(
    scriptingVariables: Map[String,String] = Map.empty
  ) derives Codec.AsObject

  case class BatchInfo(
      id: String,
      name: String,
      dialect: Dialect,
      sources: List[ProcessingInfoADT.SourceSpec] = Nil,
      databaseModel: List[String] = Nil,
      user: String = "",
      description: String = "",
      replace: Boolean = false,
      autoCreate: Boolean = false,
      multiPass: Boolean = false,
      uploaded: Option[Long] = None,
      defaultDatabase: Option[String] = None,
      defaultSchema: Option[String] = None,
      processingStart: Option[Long] = None,
      processingError: Option[String] = None,
      nrPasses: Option[Int] = None,
      maxPasses: Option[Int] = None,
      dir: List[String] = Nil,
      showConstantRelations: Boolean = false, // internal only
      queryLineage: BatchInfoQueryLineage = BatchInfoQueryLineage(),
      databaseImage: BatchInfoDatabaseImage = BatchInfoDatabaseImage(),
      analyzer: BatchInfoAnalyzer = BatchInfoAnalyzer(),
      output: BatchInfoOutput = BatchInfoOutput(),
      authorization: BatchInfoAuthorization = BatchInfoAuthorization(),
      dump: List[(Int, String)] = Nil,
      tsqlOptions: BatchInfoTSQLOptions = BatchInfoTSQLOptions(),
      batchProcessingStats: Option[ProcessingStatsADT.BatchProcessingStats] = None,
      version: Int = 8
  ) derives Codec.AsObject

  case class ExtraRelation(
    fileName: String,
    source: (String, String, String, String, String),
    target: (String, String, String, String, String),
    comment: Option[String] = None,
  ) derives Codec.AsObject
}
