/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client

import org.scalajs.dom
import org.scalajs.dom.document
import org.scalajs.dom.html.Element
import scala.scalajs.js.annotation.{JSExportTopLevel, JSExport}
import UserActions.UserAction

import com.dalineage.common
import common.adt.ADT

import util.chaining._

import com.dalineage.client2
import client2.PropertyPanel
import PropertyPanel.{table,tr}

object TreeExplorer {

  import client2.adt.TreeExplorerADT.NodeType
  import client2.adt.TreeExplorerADT.TreeNode

  case class NodeSelected(node: TreeNode) extends UserAction
  case class NodeOpened(node: TreeNode) extends UserAction

  case class TreeExplorer(container: dom.html.Div, updateHelpText: String => Unit) {

    updateHelpText("Use Arrow keys to navigate")
    container.style.overflowY = "auto"
    container.tabIndex = 10

    var userActionFn: UserAction => Unit = null
    var treeData: TreeNode = null

    var lastSelectedNode: Option[Element] = None

    def handleKeyDown(e: dom.KeyboardEvent): Unit = {
      lastSelectedNode.map { selectedNode =>
        val parentNode = selectedNode.parentElement
        val hasChildren = parentNode.querySelector("ul") != null
        if (e.key == "ArrowRight") {
          // Expand node if it has children
          if (hasChildren) {
            parentNode.classList.add("expanded")
            val expandCollapseSign = parentNode.querySelector(".expand-collapse-sign")
            if (expandCollapseSign != null) expandCollapseSign.textContent = "-"
          }
        } else if (e.key == "ArrowLeft") {
          // Collapse node if it has children, or select parent if already collapsed
          if (hasChildren && parentNode.classList.contains("expanded")) {
            parentNode.classList.remove("expanded")
            val expandCollapseSign = parentNode.querySelector(".expand-collapse-sign")
            if (expandCollapseSign != null) expandCollapseSign.textContent = "+"
          } else {
            val parentLabel = parentNode.parentElement.closest("li") match {
              case null => document.querySelector("span.selected-node")
              case parent => parent.querySelector("span:nth-child(2)")
            }
            if (parentLabel != null) {
              selectNode(parentLabel.asInstanceOf[Element])
            }
          }
        } else if (e.key == "ArrowDown") {
          // Select first child if expanded, otherwise select next sibling if exists, or next sibling of parent node if it exists
          if (parentNode.classList.contains("expanded") && hasChildren) {
            val firstChild = parentNode.querySelector("li").querySelector("span:nth-child(2)")
            if (firstChild != null) {
              selectNode(firstChild.asInstanceOf[Element])
            }
          } else {
            val nextSibling = parentNode.nextElementSibling
            if (nextSibling != null) {
              val nextSiblingLabel = nextSibling.querySelector("span:nth-child(2)")
              if (nextSiblingLabel != null) {
                selectNode(nextSiblingLabel.asInstanceOf[Element])
              }
            } else {
              val parentNextSibling = parentNode.parentElement.closest("li").nextElementSibling
              if (parentNextSibling != null) {
                val parentNextSiblingLabel = parentNextSibling.querySelector("span:nth-child(2)")
                if (parentNextSiblingLabel != null) {
                  selectNode(parentNextSiblingLabel.asInstanceOf[Element])
                }
              }
            }
          }
        } else if (e.key == "ArrowUp") {
          // Select previous sibling if exists, otherwise select parent, including root
          val previousSibling = parentNode.previousElementSibling
          if (previousSibling != null) {
            val previousSiblingLabel = previousSibling.querySelector("span:nth-child(2)")
            if (previousSiblingLabel != null) {
              if (previousSibling.classList.contains("expanded")) {
                val lastChild = previousSibling.querySelector("ul").lastElementChild.querySelector("span:nth-child(2)")
                if (lastChild != null) {
                  selectNode(lastChild.asInstanceOf[Element])
                }
              } else {
                selectNode(previousSiblingLabel.asInstanceOf[Element])
              }
            }
          } else {
            val parentLabel = parentNode.parentElement.closest("li") match {
              case null => document.querySelector("span.selected-node")
              case parent => parent.querySelector("span:nth-child(2)")
            }
            if (parentLabel != null) {
              selectNode(parentLabel.asInstanceOf[Element])
            }
          }
        } else if (e.key == "Enter") {
          val key = selectedNode.nextElementSibling.textContent
          userActionFn(NodeOpened(findNodeByKey(treeData, key).get))
        } else {
          userActionFn(UserActions.KeyboardEvent(e))
        }
      }.getOrElse(userActionFn(UserActions.KeyboardEvent(e)))
      e.stopPropagation()
      e.preventDefault()
    }

    // Add event listener for keydown events
    container.addEventListener("keydown", handleKeyDown _)


    def createTreeNode(node: TreeNode): Element = {
      val nodeElement = document.createElement("li").asInstanceOf[Element]
      nodeElement.className = "tree-node"

      val expandCollapseSign = document.createElement("span").asInstanceOf[Element]
      expandCollapseSign.className = if (node.children.nonEmpty) "expand-collapse-sign" else "no-expand-collapse-sign"
      expandCollapseSign.textContent = if (node.children.nonEmpty) "+" else ""
      nodeElement.appendChild(expandCollapseSign)

      val nodeLabel = document.createElement("span").asInstanceOf[Element]
      nodeLabel.textContent = node.name
      nodeElement.appendChild(nodeLabel)

      // key is stored as text in invisible third span element of li
      val keyNode = document.createElement("span").asInstanceOf[Element]
      keyNode.textContent = node.key
      keyNode.style.display = "none"
      nodeElement.appendChild(keyNode)

      if (node.children.nonEmpty) {
        val childrenContainer = document.createElement("ul").asInstanceOf[Element]
        node.children.foreach { childData =>
          val childNode = createTreeNode(childData)
          childrenContainer.appendChild(childNode)
        }
        nodeElement.appendChild(childrenContainer)

        val toggleExpandCollapse: (dom.MouseEvent) => Unit = { (_: dom.MouseEvent) =>
          val isExpanded = nodeElement.classList.toggle("expanded")
          expandCollapseSign.textContent = if (isExpanded) "-" else "+"
        }

        expandCollapseSign.addEventListener("click", toggleExpandCollapse)
      }

      val selectNode: (dom.MouseEvent) => Unit = { (_: dom.MouseEvent) =>
        lastSelectedNode.foreach(_.classList.remove("selected-node"))
        nodeLabel.classList.add("selected-node")
        lastSelectedNode = Some(nodeLabel)
        nodeLabel.scrollIntoView(false)
        userActionFn(NodeSelected(node))
      }

      nodeLabel.addEventListener("click", selectNode)

      nodeElement
    }

    def renderTree(
      treeData: TreeNode,
      userActionFn: UserAction => Unit
    ): Unit = {

      this.userActionFn = userActionFn
      this.treeData = treeData

      val treeRoot = document.createElement("ul").asInstanceOf[Element]
      val rootNode = createTreeNode(treeData)
      treeRoot.appendChild(rootNode)

      this.container.innerHTML = ""
      this.container.appendChild(treeRoot)
    }

    def selectNode(element: Element): Unit = {
      lastSelectedNode.foreach(_.classList.remove("selected-node"))
      element.classList.add("selected-node")
      element.scrollIntoView(false)
      lastSelectedNode = Some(element)
      val key = element.nextElementSibling.textContent
      val optNode = findNodeByKey(treeData, key)
      optNode.foreach { node =>
        userActionFn(NodeSelected(node))
      }
    }

    def findNodeByKey(treeNode: TreeNode, key: String): Option[TreeNode] = {
      if (treeNode.key == key) {
        Some(treeNode)
      } else {
        treeNode.children.to(LazyList).flatMap(findNodeByKey(_, key)).headOption
      }
    }

    def findElementByKey(key: String): Option[Element] =
      findElementByKey(container.children(0).asInstanceOf[Element], key, false)
        .map( _.querySelector("span:nth-child(2)").asInstanceOf[Element])
    def expandElementsByKey(key: String): Option[Element] =
      findElementByKey(container.children(0).asInstanceOf[Element], key, true)
        .map( _.querySelector("span:nth-child(2)").asInstanceOf[Element])

    def findElementByKey(element: Element, key: String, expand: Boolean): Option[Element] = {
      val nodeKey = element.querySelector("span:nth-child(3)").textContent
      nodeKey == key match {
        case true => Some(element)
        case false =>
          element.querySelector("ul") match
            case null => None
            case ul =>
              ul.children
                .to(LazyList)
                .flatMap(elem => findElementByKey(elem.asInstanceOf[Element], key, expand))
                .headOption
                .tap{ opt =>
                  opt.map{
                    _ =>
                      Option( ul.asInstanceOf[Element].parentElement ).map{ elem =>
                        elem.classList.add("expanded")
                          Option( elem.querySelector(".expand-collapse-sign") )
                            .map(_.textContent = "-")
                      }
                  }
                }
      }
    }
  }
  object NoDataavailableType extends NodeType
  val noDataAvailable = TreeNode( NoDataavailableType, "no-data", "No data available", Nil)
}
