package com.dalineage.client.old.model

import scala.collection.immutable._

import typings.gojs.{mod => go}
import scala.scalajs.js
import org.scalablytyped.runtime.StringDictionary
import typings.gojs.mod.ObjectData

import com.dalineage.client
import com.dalineage.client.old.explorer

object WorkspaceToolboxModel extends client.old.ToolboxModel {
  import client.WorkspaceUserActions._
  import client.LineageUserActions._
  import client.old.Toolbox.ToolboxButton

  case object ShowExplorerButton extends ToolboxButton {
    val (enabled, visible, text, userAction, left) = (true, true, "Open...", ShowExplorer(),100)
  }

  case object ShowUploadBatchButton extends ToolboxButton {
    val (enabled, visible, text, userAction, left) =
      (true, true, "Upload batch (ZIP)...", ShowUploadBatch(),190)
  }

  case object ShowUploadCodeButton extends ToolboxButton {
    val (enabled, visible, text, userAction, left) =
      (true, true, "Upload code (plain text)...", ShowUploadCode(),385)
  }

  case object ShowUploadSippetZipButton extends ToolboxButton {
    val (enabled, visible, text, userAction, left) =
      (true, true, "Upload Snippet ZIP...", ShowUploadSnippetZip(),625)
  }

  case object ShowConsoleButton extends ToolboxButton {
    val (enabled, visible, text, userAction, left) =
      (false, false, "View console...", ShowConsole(),820)
  }

  case object LogoutButton extends ToolboxButton {
    val caption = if (s"${js.Dynamic.global.user}" == "public" ) "Login" else "Logout"
    val (enabled, visible, text, userAction, left) = (true, true, caption, Logout(), 970)
  }

  case object ReuploadAllButton extends ToolboxButton {
    val (enabled, visible, text, userAction, left) =
      (true, true, "Reupload all batches...", ReuploadAllBatches(), 1170)
  }

  val buttons:List[ToolboxButton] = ShowExplorerButton :: ShowUploadBatchButton ::
    ShowUploadCodeButton :: ShowUploadSippetZipButton ::
    ShowConsoleButton :: LogoutButton :: ReuploadAllButton :: Nil
}
