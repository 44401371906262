package com.dalineage.client.old.lineage.model

import scala.collection.immutable._

import com.dalineage.client

import client.old.Toolbox.ToolboxButton

object LineageToolboxModel extends client.old.ToolboxModel {
  import client.LineageUserActions._

  case object ShowEditorButton extends ToolboxButton {
    val (enabled, visible, text, userAction, left) =
      (false, false, "View code...", ShowEditor(), 100)
  }

  case object GridLayoutButton extends ToolboxButton {
    val (enabled, visible, text, userAction, left) = (false, false, "GridLayout", GridLayout(), 220)
  }

  case object CircularLayoutButton extends ToolboxButton {
    val (enabled, visible, text, userAction, left) =
      (false, false, "CircularLayout", CircularLayout(), 330)
  }

  case object LayeredDigraphLayoutButton extends ToolboxButton {
    val (enabled, visible, text, userAction, left) =
       (false, false, "LayeredDigraphLayout", LayeredDigraphLayout(), 466)
  }

  case object RestrictLineageButton extends ToolboxButton {
    val (enabled, visible, text, userAction, left) =
      (false, false, "RestrictLineage", RestrictLineage(), 670)
  }

  case object SaveLineageViewButton extends ToolboxButton {
    val (enabled, visible, text, userAction, left) =
      (false, false, "Save view", SaveLineageView(), 820)
  }

  val layoutButtons = GridLayoutButton :: CircularLayoutButton :: LayeredDigraphLayoutButton :: Nil

  val buttons:List[ToolboxButton] = ShowEditorButton :: GridLayoutButton :: CircularLayoutButton ::
    LayeredDigraphLayoutButton :: RestrictLineageButton :: SaveLineageViewButton :: Nil
}
