/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client.objectbrowser

import io.circe

import com.dalineage.common
import common.adt.ADT

import com.dalineage.client

import client.UserActions.UserAction

import scala.concurrent.Future
import scala.concurrent.Promise
import scala.concurrent.ExecutionContext.Implicits.global
import scala.util.Success
import scala.util.Failure

import org.scalajs.dom
import scala.scalajs.js

import util.chaining._

import client
  .{Workspace, Window, TreeExplorer, SearcheableComponent, TreeComponent,
    SelectableComponent, PropertyPanel, Editor, Console
  }

object ObjectBrowserData {
  import scala.scalajs.js.JSConverters._
  import common.adt.TreeComponentADT.TreeNode
  import common.adt.TreeComponentADT.ObjectType

  case object ErrorMesage extends TreeExplorer.NodeType
  case object Directory extends TreeExplorer.NodeType
  case object DBInstance extends TreeExplorer.NodeType
  case object DBDatabasse extends TreeExplorer.NodeType
  case object DBSchema extends TreeExplorer.NodeType
  case object DBTable extends TreeExplorer.NodeType
  case object DBColumn extends TreeExplorer.NodeType

  def separator(storeName: String): String = storeName match
    case "image" => "."
    case "sources" => "/"
    case _ => throw new Exception(s"Unknown store $storeName")

  def separatorRE(storeName: String): String = storeName match
    case "image" => "\\."
    case "sources" => "/"
    case _ => throw new Exception(s"Unknown store $storeName")

  def getStoreName(storePrefix: String, batchId: String): String = s"${storePrefix}_${batchId}"

  def getStore(db: dom.IDBDatabase, storePrefix: String, batchId: String): dom.IDBObjectStore =
    val storeName = getStoreName(storePrefix, batchId)
    db.transaction(storeName, dom.IDBTransactionMode.readwrite).objectStore(storeName)

  def setKeepAlive(
      batchId: String,
  ): Future[Unit] = {
    val promise = Promise[Unit]()
    println(s"=== opening database === setKeepAlive")
    val f = for {
      db <- IndexedDB.openDatabase()
      _ <- setKeepAlive(db, batchId)

    } yield db
    f.onComplete {
      case Success(db) =>
        println(s"=== closing database === setKeepAlive")
        db.close()
        promise.success(())
      case Failure(e) =>
        println(s"setKeepAlive failure $e")
        promise.failure(e)
    }
    promise.future
  }

  def setKeepAlive(
      db: dom.IDBDatabase,
      batchId: String,
  ): Future[Unit] = {
    val stores = db.objectStoreNames.toSeq.filter(_.contains(batchId)).toJSArray
    val transaction = db.transaction(stores, dom.IDBTransactionMode.readwrite)

    stores.map { storeName =>
      val store = transaction.objectStore(storeName)

      val currentTime = js.Date.now()
      val data = js.Dynamic.literal(
        "key" -> "_keep_alive_",
        "timestamp" -> currentTime
      )

      store.put(data)
    }

    val promise = Promise[Unit]()
    transaction.oncomplete = (_: dom.Event) => {
      promise.success(())
    }
    promise.future
  }

  def downloadObjectBrowserData(
      db: dom.IDBDatabase,
      user: String,
      batchId: String,
      objectBrowserWindow: Window.SingleWindow): Future[Unit] = {

    val promise = Promise[Unit]()

    val url = s"web/image/$user/$batchId"
    val imageChunkSize = 2*common.adt.TreeComponentADT.chunkSize
    val sourceChunkSize = 4*common.adt.TreeComponentADT.chunkSize
    ObjectBrowserData.saveDataToIndexedDB(db, "image", batchId, url, 0, imageChunkSize).onComplete {
      case Success(()) =>
        val url = s"web/sources/$user/$batchId"
        ObjectBrowserData.saveDataToIndexedDB(db, "sources", batchId, url, 0, sourceChunkSize)
            .onComplete {
          case Success(()) =>
            promise.success(())
          case Failure(e) =>
            val msg = s"Object browser sources downloading error $e"
            println(s"$msg")
            promise.failure(new Exception(msg))
        }
      case Failure(e) =>
        val msg = s"Object browser image downloading error $e"
        println(s"$msg")
        promise.failure(new Exception(msg))
    }

    promise.future
  }


  def saveDataToIndexedDB(
      db: dom.IDBDatabase,
      storePrefix: String,
      batchId: String,
      url: String,
      lineFrom: Int,
      chunkSize: Int): Future[Unit] = {

    val lineTo = lineFrom + chunkSize

    val partUrl = s"$url/$lineFrom/$lineTo"

    val promise = Promise[Unit]()

    val xhr = new dom.XMLHttpRequest()
    xhr.open("GET", partUrl, async = true)
    xhr.responseType = "text"

    println(s"loading objects data ${partUrl}")

    val storeName = getStoreName(storePrefix, batchId)

    xhr.onload = (_: dom.Event) => {
      if (xhr.status == 200) {
        val lines = xhr.responseText.split("\n")
        val size = lines.size //size of lines, each object is 2 lines
        val transaction = db.transaction(storeName, dom.IDBTransactionMode.readwrite)
        val objectStore = transaction.objectStore(storeName)

        lines.grouped(2).foreach {
          case Array(key, value) =>
            val keys = key.split(separatorRE(storePrefix)).map(_.toUpperCase).toList.toJSArray
            val data = js.Dynamic.literal("key" -> key, "keys" -> keys, "value" -> value)
            objectStore.add(data)
          case _ => // Ignore incomplete pairs
        }

        transaction.oncomplete = (_: dom.Event) => {
          val msg = s"Saved ${lineTo/2/1000}k $storeName objects"
          println(msg)
          ObjectBrowser.objectBrowserWindow.updateHelpText(msg)
          println(s"size ${size} chunkSize ${chunkSize}")
          if ( size == chunkSize ) {
            saveDataToIndexedDB(db, storePrefix, batchId, url, lineFrom + chunkSize, chunkSize)
                .onComplete {
              case Success(()) => promise.success(())
              case Failure(e) => promise.failure(e)
            }
          } else
          promise.success(())
        }

        transaction.onerror = (event: dom.Event) => {
          val msg = s"Data saving failed"
          println(s"${msg}")
        }
      } else {
        val msg = s"Data downloading failed, status ${xhr.status}"
        println(s"msg ${msg}")
        promise.failure(new Exception(msg))
      }
    }

    xhr.send()
    promise.future
  }
}
