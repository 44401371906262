/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.common.adt

object ObjectBrowserADT {

  val chunkSize = 50000
  val rootKey = "_OBJECT_BROWSER_DATA_ROOT_"

  case class TreeNode(
      `type`: Int,
      key: Option[String],
      name: String,
      properties: Map[Int, String],
      children: Option[List[TreeNode]] = None,
        //if None, we don't know if it has children - loading needed when parent is expanded
        //if Some(empty list), we know it has no children (leaf) - no other loading
      position: Option[ADT.Range] = None,
  ) {
    def isLeaf: Boolean = children match {
      case None => false
      case Some(children) => children.isEmpty
    }
  }
}
