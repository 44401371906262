package com.dalineage.client.old

import scala.collection.immutable._

import com.dalineage.client

import scala.scalajs.js
import org.scalajs.dom
import dom.document
import dom.Window
import dom.html.Div
import dom.html.TextArea

import client.UserActions.UserAction

import scala.scalajs.js
import scala.scalajs.js.timers._
import org.scalablytyped.runtime.StringDictionary


object Editor {

  var code: String = ""

  var editor: js.Dynamic = null

  def open(div: dom.html.Div, userActionFn: UserAction => Unit): Unit = {
    val cm: js.Dynamic = dom.window.asInstanceOf[js.Dynamic].CodeMirror
    editor = cm(div, js.Dynamic.literal(
      "lineNumbers" -> true
    ))
    editor.setSize("100%", "100%");
    editor.getDoc().setValue(code)

    div.addEventListener("keydown", { (event: dom.KeyboardEvent) =>
      userActionFn( client.UserActions.KeyboardEvent(event) )
    })
  }

  def selectCode(lf: Int, cf: Int, lt: Int, ct: Int ): Unit = {
    editor.getDoc().setSelection(js.Dynamic.literal(
      "line" -> lf,
      "ch" -> cf
    ), js.Dynamic.literal(
      "line" -> lt,
      "ch" -> ct
    ))
  }
}

object EditorWindow extends client.PopupWindow {

  val name = Some("editor")

  var code: String = ""

  def viewCode(): Unit = {
    if( popupWindow != null ) {
      Editor.editor.getDoc().setValue(code)
    }
  }

  override def open(): Unit = {
    super.open()

    popupWindow.addEventListener("DOMContentLoaded", { (_: dom.Event) =>
      val popupDocument = popupWindow.document
      val div = popupDocument.getElementById("editordiv").asInstanceOf[dom.html.Div]
      Editor.open(div, _ => {})
    })
  }
}
