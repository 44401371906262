/* (c) Miroslav Semora <semora@seznam.cz> 2020-2022, all rights reserved */
package com.dalineage.client.old.explorer.model

import scala.collection.immutable._

import typings.gojs.{mod => go}
import scala.scalajs.js
import org.scalablytyped.runtime.StringDictionary

import com.dalineage.common
import common.adt.ExplorerDataADT._
import common.adt.BatchInfoADT._

import com.dalineage.client
import client.old.explorer
import client.old.explorer.adt.ExplorerADT.{ BatchNode, ViewNode, DirNode}
import client.PropertyPanel.{table, tr}

object BatchTreeModel {

  val unixTimestemp2String: Option[Long] => String = (timestamp) =>
    timestamp
      .map(java.util.Date(_))
      .map(_.toString())
      .getOrElse(" - ")

  def info(batchInfo: BatchInfo, id:String): String = {

    val passes = batchInfo.nrPasses match {
      case Some(nr) => s"nrPasses: $nr\n  "
      case None => ""
    }
    val dump = batchInfo.dump.map{ case (lvl, str) =>
      ("  " * lvl) + str
    }.mkString("\n")

    val err = batchInfo.processingError.map(_ => " *** ERROR ***").getOrElse("")
    s"""
<pre>
BATCH INFO$err
USER: ${batchInfo.user}
UPLOADED: ${unixTimestemp2String(batchInfo.uploaded)}
PROCESSING: ${unixTimestemp2String(batchInfo.processingStart)}
PROCESSED: ${unixTimestemp2String(batchInfo.batchProcessingStats.flatMap(_.processingEnd))}
NAME:
</pre>
<p>
${batchInfo.name}
</p>
<pre>
ID: $id
DESCRIPTION: ${batchInfo.description}
DIALECT: ${batchInfo.dialect}
AUTOCREATE: ${batchInfo.autoCreate}
MULTIPASS: ${batchInfo.multiPass}
DIR: ${batchInfo.dir.mkString("/")}
RESULT:
</pre>
<p>
${batchInfo.processingError.getOrElse("OK")}
</p>
<pre>

${passes}Diagram options:
$dump

=======================================================
  Press <b>Enter</b> to load and keep this dialog open
  Press <b>Ctrl+Enter</b> to load and close this dialog
  Press <b>Esc</b> to close this dialog
</pre>
    """
  }

  import common.adt.TreeComponentADT.PropertyName
  def treeNodes(node: ExplorerNode, parentKey: Option[String])
      :List[StringDictionary[js.Any]] = {
    node match {
      case ExplorerDir(name, nodes) =>
        val key = parentKey.map(k=>k + "/").getOrElse("") + name
        val properties = table(
          tr( PropertyName.ObjectType.toString, "DIRECTORY" ),
          tr( PropertyName.ObjectName.toString, name ),
          tr( PropertyName.ObjectKey.toString, key ),
        )
        val jsKey:js.Any = parentKey.map(k=>k:js.Any).getOrElse(js.undefined)
        val dirNode:StringDictionary[js.Any] = StringDictionary[js.Any](
          "key"-> key,
          "caption" -> name,
          "typ" -> DirNode.key,
          "properties" -> properties,
          "isTreeExpanded" -> false,
          "parent" -> jsKey)
        dirNode :: nodes.flatMap(treeNodes(_, Some(key)))
      case batch:ProcessedBatch =>
        val batchKey = batch.info.id
        val user = batch.info.user

        val batchProperties = table(
          tr(  "Created:",
            unixTimestemp2String(batch.info.batchProcessingStats.flatMap(_.processingEnd)) ),
          tr(  "Description:",  batch.info.description ),
          tr(  "Dialect:",  batch.info.dialect.toString ),
          tr(  "&nbsp;", "&nbsp;"  ),
        )

        val viewNodes = batch.views.map{ case batchView =>
          val viewKey = batchView.batchId + "/" + batchView.viewId
          val infoText = s"VIEW: $viewKey\n" + info(batch.info, viewKey)
          val caption = s"View: $viewKey"

          val properties = table(
            tr( PropertyName.ObjectType.toString, "BATCH VIEW" ),
            tr( PropertyName.ObjectKey.toString, batchView.viewId ),
          )

          StringDictionary[js.Any](
            "picture" -> "images/closedFolder.svg", "typ" -> ViewNode.key,
            "batchId" -> batchView.batchId, "viewId" -> batchView.viewId,
            "info" -> infoText,
            "properties" -> (properties + batchProperties),
            "user" -> user,
            "key" -> viewKey,
            //This is prasarna for sure. tbd fire event and compose the url in Main.scala
            "caption" -> caption, "parent" -> batchKey)
        }

        val caption = batch.info.name
        val properties = table(
          tr( PropertyName.ObjectType.toString, "BATCH" ),
          tr( PropertyName.ObjectName.toString, caption ),
          tr( PropertyName.ObjectKey.toString, batchKey ),
        )

        val batchNode = StringDictionary[js.Any](
          "picture" -> "images/closedFolder.svg", "typ" -> BatchNode.key,
          "info" -> info(batch.info, batchKey),
          "properties" -> (properties + batchProperties),
          "user" -> user,
          "isTreeExpanded" -> false,
            //This is prasarna for sure. tbd fire event and compose the url in Main.scala
          "key" -> batchKey, "caption" -> caption, "parent" -> parentKey.get)
        batchNode :: viewNodes
    }
  }

  def apply(data: ExplorerData): go.TreeModel = {

    val nodes = data.dirs.flatMap{ case dir => treeNodes(dir, None) }


    val arrNodes:js.Array[typings.gojs.mod.ObjectData] = js.Array(nodes:_*)

    val model = new go.TreeModel( arrNodes )
    println(model.toJson().toString().take(1000))
    model
  }

  def batchInfoToModel(processedBatch: ProcessedBatch, parent: String)
    : StringDictionary[js.Any] =  {
      val key = processedBatch.id
      val caption = processedBatch.info.name
      StringDictionary[js.Any](
        "picture" -> "images/closedFolder.svg", "typ" -> BatchNode.key,
        "info" -> info(processedBatch.info, key),
        "key" -> key, "caption" -> caption, "parent" -> parent)
  }

  def batchViewToModel(
      batchView: BatchView,
      batchInfo: BatchInfo,
      parent: String) : StringDictionary[js.Any] = {
      val key = batchView.batchId + "/" + batchView.viewId
      val infoText = s"VIEW: $key\n" + info(batchInfo, key)
      val properties = table( tr( PropertyName.ObjectType.toString, "VIEW" ) )
      val caption = s"View: $key"
      StringDictionary[js.Any](
        "picture" -> "images/closedFolder.svg", "typ" -> ViewNode.key,
        "info" -> infoText, "properties" -> properties,
        "key" -> key, "caption" -> caption, "parent" -> parent)
  }
}
