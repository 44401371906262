package com.dalineage.client.old.lineage

import scala.collection.immutable._
import scala.scalajs.js
import scala.util.chaining._

import org.scalajs.dom
import org.scalajs.dom.html
import org.scalajs.dom.document
import typings.gojs.{mod => go}

import com.dalineage.common
import common.adt.DiagramADT._

import com.dalineage.client
import client.diagram.DiagramOps
import client.UserActions.UserAction
import client.LineageUserActions._

import com.dalineage.common.DiagramDataSerializer.GoJSSerializer

object Lineage {

  var selectedColumn: Option[(String,go.Panel)] = None

  var restricted: Boolean = false

  def gridLayout(): Unit = setLayout(new go.GridLayout)
  def layeredDigraphLayout(): Unit = setLayout(new go.LayeredDigraphLayout)
  def circularLayout(): Unit = setLayout(new go.CircularLayout)

  def setLayout(layout: go.Layout): Unit =
    client.diagram.GoJSDiagram.setLayout(layout)

  def showView(model: go.GraphLinksModel, userActionFn: UserAction => Unit): Unit =
    showModel(model, userActionFn)
      .tap(_ => updateToolbox())

  def open(diagramData: DiagramData, userActionFn: UserAction => Unit): Unit = {
    // val collapsedTables: Set[String] = diagramData.collapsedTables
    val modelJson: String = diagramData.toGoJS().noSpaces
    val model = go.Model.fromJson(modelJson).asInstanceOf[go.GraphLinksModel]
    showModel(model, userActionFn)
  }

  def openToolbox(): Unit = {
    LineageToolbox.updateLineageButtons(None, None)
  }

  def restrictLineage(): Unit = {
    restricted match {
      case false =>
        selectedColumn match {
          case Some((tableKey, columnPanel)) =>
            val columnKey = columnPanel.data.asInstanceOf[go.ObjectData].get("key").get.toString
            client.diagram.LineageModelOps.restrictColumn(tableKey, columnKey)(client.old.diagram.GoJSDiagram.diagram)
            restricted = true
            LineageToolbox.updateRestrictButton(true)
          case None =>
            val tableData = DiagramOps.toList(client.old.diagram.GoJSDiagram.diagram.selection.iterator).head.data
              .asInstanceOf[go.ObjectData]
            val key = tableData.get("key").get.toString
            client.diagram.LineageModelOps.restrictTable(key)(client.old.diagram.GoJSDiagram.diagram)
            restricted = true
            LineageToolbox.updateRestrictButton(true)
        }
      case true =>
        client.diagram.LineageModelOps.restoreLineage()(client.old.diagram.GoJSDiagram.diagram)
        LineageToolbox.updateRestrictButton(false)
        restricted = false
    }
  }

  def updateToolbox(): Unit = {
    //this remains forever - there is no close functionality on current lineage
    LineageToolbox.enableSaveButton()
    LineageToolbox.enableCodeButton()
    LineageToolbox.updateLineageButtons(None, None)
    LineageToolbox.updateRestrictButton(false)
  }

  def showModel(model: go.Model, userActionFn: UserAction => Unit): Unit = {

    val myUserActionFn: UserAction => Unit = { action =>
    action match {
      case client.diagram.GoJSDiagram.DiagramNodeSelected(optData) =>

        val optNode = optData.map{ data =>
          val key = data.get("key").toString
          if( data.get("linefrom").isDefined ) {
            val linefrom: Int = data.get("linefrom").get.toString.toInt
            val columnfrom: Int = data.get("columnfrom").get.toString.toInt
            val lineto: Int = data.get("lineto").get.toString.toInt
            val columnto: Int = data.get("columnto").get.toString.toInt
            userActionFn(client.Editor.SelectCode(linefrom, columnfrom, lineto, columnto))
          }
          DiagramOps.findNodeForKey(key)(client.diagram.GoJSDiagram.diagram)
        }

        LineageToolbox.updateLineageButtons(optNode, None)

      case client.diagram.GoJSDiagram.ColumnSelected(optData) =>
        val optKey = optData.map( data => data._1 ).map( data => data("key").toString )
        LineageToolbox.updateLineageButtons(None, optKey)

      case client.diagram.GoJSDiagram.ModelChanged(modelJson) => () // TODO save view

      case ev:client.UserActions.KeyboardEvent => userActionFn(ev)

    }}

    client.old.diagram.GoJSDiagram.init(LineageWindow.diagramDiv, myUserActionFn)
    client.old.diagram.GoJSDiagram.showModel(model)
  }
}

object LineageWindow extends client.ResizableElement {
  var lineageToolboxDiv: dom.html.Div = null
  var diagramDiv: dom.html.Div = null

  def initToolbox(rootNode: dom.html.Element, userActionFn: UserAction => Unit): Unit = {
    lineageToolboxDiv = dom.document.createElement("div").asInstanceOf[dom.html.Div]
    lineageToolboxDiv.id = "lineagetoolbox"
    lineageToolboxDiv.style.width = px(300)
    lineageToolboxDiv.style.height = px(300)
    lineageToolboxDiv.style.backgroundColor = client.CSS.backgroundColor("lineage-toolbox")

    rootNode.appendChild(lineageToolboxDiv)
    LineageToolbox.init(lineageToolboxDiv,userActionFn)
  }

  override
  def init(rootNode: dom.html.Element, userActionFn: UserAction => Unit): Unit = {
    super.init(rootNode, userActionFn)

    diagramDiv = dom.document.createElement("div").asInstanceOf[dom.html.Div]
    diagramDiv.id = "container"
    diagramDiv.style.width = px(300)
    diagramDiv.style.height = px(300)
    diagramDiv.style.backgroundColor = client.CSS.backgroundColor("container")

    diagramDiv.innerHTML = s"""
<p> Welcome to <b>DAlineage</b>, your SQL lineage visualization tool.</p>
<p> Start with Ctrl+O (or press Open... button) and inspect the public samples. </p>
<p> Logged in user: <b>${js.Dynamic.global.user}</b>. </p>
<p> <a href="/">Maybe you switch back to our new workspace</a>. </p>
"""

    rootNode.appendChild(diagramDiv)
  }

  override
  def resize(width: Double, height: Double): Unit = {
    super.resize(width, height)

    lineageToolboxDiv.style.width = px(width)
    lineageToolboxDiv.style.height = px(client.old.Toolbox.toolboxHeight)

    diagramDiv.style.width = px(width)
    diagramDiv.style.height = px(height - client.old.Toolbox.toolboxHeight)
  }
}
