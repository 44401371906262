package com.dalineage.client

import scala.collection.immutable._
import scala.util.chaining._
import cats.syntax.all._

import com.dalineage.client

import scala.scalajs.js
import org.scalajs.dom
import dom.document
import dom.Window
import dom.html.Div
import dom.html.TextArea

import client.UserActions.UserAction

import scala.scalajs.js
import scala.scalajs.js.timers._
import org.scalablytyped.runtime.StringDictionary

import UserActions._

object Editor {

  case class SelectCode(rowFrom: Int, columnFrom: Int, rowTo: Int, columnTo: Int)
    extends UserAction

  val codeWindow = Window.SingleWindow()

  codeWindow.div.id = "codeWindow"
  codeWindow.div.style.verticalAlign = "top"

  var editor: Option[js.Dynamic] = None

  var userActionFn: Option[UserAction => Unit] = None

  def init(userActionFn: UserAction => Unit): Unit =
    this.userActionFn = Some(userActionFn)
      .map(_.tap((userActionHandler: UserAction => Unit) =>
        this.codeWindow.div.addEventListener("keydown", { (event: dom.KeyboardEvent) =>
          userActionHandler(client.UserActions.KeyboardEvent(event))
        })
      ))

  def open(code: String): Unit =
    this.editor = Option(dom.window.asInstanceOf[js.Dynamic].CodeMirror)
      .map(_.apply(this.codeWindow.div, js.Dynamic.literal("lineNumbers" -> true)))
      .map(_.tap(_.setSize("100%", "100%")))
      .map(_.tap(_.getDoc().setValue(code)))

  var optUser: Option[String] = None
  var optBatchId: Option[String] = None
  var optSourceId: Option[String] = None

  //workaround for asynchronous loading of source code
  var optSelection: Option[(Int, Int, Int, Int)] = None

  def open( user: String, batchId: String, sourceId: String): Unit = {
    this.userActionFn
      .fold
      { dom.console.error("Editor window has not been initialized!") }
      { userActionHandler =>
        this.optUser = Option(user)
        this.optBatchId = Option(batchId)
        this.optSourceId = Option(sourceId)

        val cFn: String => Unit = {
          code =>
            this.codeWindow.div.innerHTML = ""
            Console.msgBox(s"Source code loaded, batchId $batchId sourceId $sourceId")
            open(code)
            this.optSelection match
              case None => ()
              case Some((lf, cf, lt, ct)) => hilightCode(lf, cf, lt, ct)
        }
        this.codeWindow.div.innerHTML = s"Loading code for $user/$batchId/$sourceId"
        userActionHandler(UserActions.LoadFile(s"web/code/$user/$batchId/$sourceId",cFn))
      }
  }

  def selectCode(sourceId:String, lf: Int, cf: Int, lt: Int, ct: Int ): Unit = {
    (for {
      gUser <- this.optUser
      gBatchId <- this.optBatchId
      gSourceId <- this.optSourceId
    } yield {
      (sourceId != gSourceId) match
        case true =>
          Console.msgBox(s"Loading source user $gUser batchId $gBatchId sourceId $sourceId")
          this.optSelection = Some((lf, cf, lt, ct))
          this.optSourceId = Some(sourceId)
          open(gUser, gBatchId, sourceId)
        case false =>
          hilightCode(lf, cf, lt, ct)
    }).getOrElse(dom.console.error("Editor window has not been initialized!"))
  }

  def hilightCode(lineFrom: Int, columnFrom: Int, lineTo: Int, columnTo: Int): Unit = {
    Console.msgBox(s"Hilighting code [$lineFrom.$columnFrom] to [$lineTo.$columnTo]")
    this.editor
      .map(_.getDoc())
      .map(_.setSelection(
        js.Dynamic.literal("line" -> lineFrom, "ch" -> columnFrom),
        js.Dynamic.literal("line" -> lineTo, "ch" -> columnTo)
      ))
      .as[Unit]
      .getOrElse(dom.console.error("Editor window has not been initialized!"))
  }
}
