/* (c) Miroslav Semora <semora@seznam.cz> 2020-2022, all rights reserved */
package com.dalineage.client.old

import org.scalajs.dom
import com.dalineage.client

object Upload extends client.PopupWindow {
  val name = Some("upload")
}

object UploadCode extends client.PopupWindow {
  val name = Some("upload_code")
  override val height = 850
  override val width = 900
}

object UploadSnippetZip extends client.PopupWindow {
  val name = Some("upload_snippetzip")
  override val height = 850
  override val width = 900
}
