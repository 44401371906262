/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client2

import org.scalajs.dom

object Console extends PopupWindow {

  var messages: List[String] = Nil

  val url = None

  def msgBox(msg: String): Unit = {
    if( popupWindow != null ) {
      popupWindow.document.body.innerHTML =
        popupWindow.document.body.innerHTML + s"$msg</br>"
    }
    messages = msg :: messages
    if( messages.size > 30 ) {
      messages = messages.init
    }
    println(msg)
  }

  override
  def open(): Unit = {
    super.open()
    popupWindow.document.body.innerHTML = messages.map{ msg =>
        s"$msg<br/>"
    }.mkString("\n")
    popupWindow.scrollTo(0, popupWindow.document.body.scrollHeight)
  }
}
