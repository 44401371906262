package com.dalineage.client.diagram

import scala.collection.immutable._

import typings.gojs.{mod => go}

import org.scalajs.dom

import com.dalineage.client

object DiagramOps {
  import scala.scalajs.js
  import org.scalablytyped.runtime.StringDictionary

  def findNodeForKey(key: String)(implicit diagram:go.Diagram): go.Node = {
    val node = diagram.findNodeForKey(key)
    node match {
      case n:go.Node => n
      case null => throw new Exception(s"assertion failed key $key was `null`")
    }
  }

  private
  def outTableRelations(tableKey: String)(implicit diagram:go.Diagram) =
    inOutRelations(tableKey, "from")

  private
  def outColumnRelations(columnKey: String)(implicit diagram:go.Diagram) =
    inOutRelations(columnKey, "fromPort")

  private
  def inTableRelations(tableKey: String)(implicit diagram:go.Diagram) =
    inOutRelations(tableKey, "to")

  private
  def inColumnRelations(columnKey: String)(implicit diagram:go.Diagram) =
    inOutRelations(columnKey, "toPort")

  private
  def inOutRelations(tableKey: String, linkKey: String)(implicit diagram:go.Diagram) = {
    val example = StringDictionary[String](linkKey -> tableKey)
    diagram.findLinksByExample(example)
  }

  private
  def inTableNodes(tableKey: String)(implicit diagram:go.Diagram) =
    inTableRelations(tableKey).map( _.fromNode )

  private
  def inColumnNodes(columnKey: String)(implicit diagram:go.Diagram): List[(go.Link,Any,Any)] =
    toList(inColumnRelations(columnKey).map(link => (link, link.fromNode, link.fromPort)))

  private
  def outColumnNodes(columnKey: String)(implicit diagram:go.Diagram): List[(go.Link,Any,Any)] =
    toList(outColumnRelations(columnKey).map(link => (link, link.toNode, link.toPort )))

  private
  def inTableKeys(tableKey: String)(implicit diagram:go.Diagram) =
    toList(inTableNodes(tableKey)).map{
      case n:go.Node =>
        val data = n.data.asInstanceOf[go.ObjectData]
        data.get("key").get.toString
      case null => throw new Exception(s"assertion failed: `null` in inTableNodes($tableKey)")
    }.distinct


  private
  def outTableNodes(tableKey: String)(implicit diagram:go.Diagram) = {
    outTableRelations(tableKey).map( _.toNode )
  }

  private
  def outTableColumnNodes(columnKey: String)(implicit diagram:go.Diagram) = {
    outTableRelations(columnKey).map(link => (link.toNode, link.toPort) )
  }

  private
  def outTableKeys(tableKey: String)(implicit diagram:go.Diagram) =
    toList(outTableNodes(tableKey)).map{
      case n:go.Node =>
        val data = n.data.asInstanceOf[go.ObjectData]
        data.get("key").get.toString
      case null => throw new Exception(s"assertion failed: `null` in outTableNodes($tableKey)")
    }.distinct

  def setOutTVisible(keys: Map[String, Boolean], tableKey: String)(implicit diagram:go.Diagram)
      :Map[String,Boolean] = {
        keys(tableKey) match {
          case true =>  keys
          case false =>
            val updatedKeys = keys.updated(tableKey, true)
            outTableKeys(tableKey).foldLeft(updatedKeys)(setOutTVisible)
        }
  }

  def setInTVisible(keys: Map[String, Boolean], tableKey: String)(implicit diagram:go.Diagram)
      :Map[String,Boolean] = {

    val updatedKeys = keys.updated(tableKey, true)
    inTableKeys(tableKey).filter( k => !keys(k) ).foldLeft(updatedKeys)(setInTVisible)
  }

  def toStringDictionary(obj:Any): StringDictionary[_] = {
    obj.asInstanceOf[StringDictionary[_]]
  }

  private
  def toReversedList[T](iterator:go.Iterator[T]): List[T] = {
    var list:List[T] = Nil
    while(iterator.next()) {
      list = iterator.value :: list
    }
    list
  }

  def toList[T](iterator:go.Iterator[T]): List[T] = toReversedList(iterator).reverse

  def getNodeData( node: Any ): go.ObjectData =
      node.asInstanceOf[go.Node].data.asInstanceOf[go.ObjectData]

  def getNodeKey( node: Any ): String =  getNodeData( node ).get("key").get.toString

  def setLinkInVisible(
    tableKey: String,
    columnKey: String,
    visibleTables: Set[String],
    visitedColumns: Set[String])(implicit diagram:go.Diagram) =
      setLinkInOutVisible(tableKey, columnKey, visibleTables, visitedColumns){
        columnKey => inColumnNodes(columnKey)
      }

  def setLinkOutVisible(
    tableKey: String,
    columnKey: String,
    visibleTables: Set[String],
    visitedColumns: Set[String])(implicit diagram:go.Diagram) =
      setLinkInOutVisible(tableKey, columnKey, visibleTables, visitedColumns){
        columnKey => outColumnNodes(columnKey)
      }


  private
  def setLinkInOutVisible(
    tableKey: String,
    columnKey: String,
    visibleTables: Set[String],
    visitedColumns: Set[String])(
      fn: String => List[(go.Link, Any,Any)])(
      implicit diagram:go.Diagram):(Set[String], Set[String]) = {

    val list = fn(columnKey)
    list.foldLeft( (visibleTables, visitedColumns) ){
        case ((currVisibleTables, currVisitedCols), (link, tbl, col) ) =>
          diagram.model.setDataProperty(link.data.asInstanceOf[go.ObjectData], "hi", true)
          val tblKey = getNodeKey(tbl)
          val colKey = getNodeKey(col)
          if( currVisitedCols.contains(colKey) ) {
            (currVisibleTables, currVisitedCols)
          } else {
            val (tbls, cols) =
                setLinkInOutVisible(tblKey, colKey, currVisibleTables, currVisitedCols)(fn)
            (tbls + tblKey, cols + colKey)
          }
    }
  }
}
