/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client

import scala.scalajs.js
import js.JSConverters._
import org.scalablytyped.runtime.StObject
import upickle.implicits.key
import org.scalactic.Equality
import typings.gojs.{mod => go}
import scala.scalajs.js.annotation.JSExportTopLevel

object LineageTS {
  sealed trait NodeType extends js.Object
  object NodeType {
    object GROUP extends NodeType {
      override def toString(): String = "GROUP"
    }

    object TABLE extends NodeType {
      override def toString(): String = "TABLE"
    }

    object COLUMN extends NodeType {
      override def toString(): String = "COLUMN"
    }

    object UNKNOWN extends NodeType {
      override def toString(): String = "UNKNOWN"
    }

    def values: Seq[NodeType] = Seq(GROUP, TABLE, COLUMN, UNKNOWN)
  }

  @js.native
  trait LineageItem extends go.ObjectData

  object LineageItem {
    implicit class LineageItemOps(val item: LineageItem) extends AnyVal {
      def toJsonString: String = js.JSON.stringify(item)
      def hasKey(key: String): Boolean = item.hasOwnProperty(key)
    }
  }

  trait LineageNodeSet {
    val nodes: js.Map[Int ,LineageNode]
  }

  object LineageNodeSet {
    def apply(): LineageNodeSet = new LineageNodeSet {
      val nodes = js.Map[Int, LineageNode]()
    }

    implicit class LineageNodeSetOps(val s: LineageNodeSet) extends AnyVal {
      def addUniqueItem(node: LineageNode) = {
        s.nodes += (node.hashNode() -> node)
        s
      }

      def unwrap: js.Array[LineageItem] =
        s.nodes.values.toJSArray
    }
  }

  // node in the diagram
  @js.native
  trait LineageNode extends LineageItem {
    val key: String = js.native
    val `type`: String = js.native
    val expanded: Boolean = js.native
    val visible: Boolean = js.native
    val group: js.UndefOr[String] = js.native
  }

  implicit val customLineageNodeEquality: Equality[LineageNode] = new Equality[LineageNode] {
    override def areEqual(a: LineageNode, b: Any): Boolean =
      b.asInstanceOf[LineageNode].key == a.key
  }

  object LineageNode {
    @scala.inline
    def apply(key: String, t: String, expanded: Boolean, visible: Boolean): LineageNode = {
      val __obj = js.Dynamic.literal(
        key = key.asInstanceOf[js.Any],
        `type` = t.asInstanceOf[js.Any],
        expanded = expanded.asInstanceOf[js.Any],
        visible = visible.asInstanceOf[js.Any],

      )
      __obj.asInstanceOf[LineageNode]
    }

    @scala.inline
    implicit class LineageNodeOps[Self <: LineageNode] (val t: Self with LineageNode) extends AnyVal {
      @scala.inline
      def getLineageNodeType: NodeType = t.`type` match {
        case "Diagram group" => NodeType.GROUP
        case "Lineage projection column" => NodeType.COLUMN
        case "List of constants" => NodeType.TABLE
        case "List of variables" => NodeType.TABLE
        case "Database table" => NodeType.TABLE
        case "Detailed lineage table" => NodeType.TABLE
        case _ => NodeType.UNKNOWN
      }

      @scala.inline
      def isGroup: Boolean = t.getLineageNodeType == NodeType.GROUP

      @scala.inline
      def isTable: Boolean = t.getLineageNodeType == NodeType.TABLE

      @scala.inline
      def isColumn: Boolean = t.getLineageNodeType == NodeType.COLUMN

      def hashNode(): Int =
        (t.keys ++ t.key)
        .map(_.hashCode())
        .foldLeft(0)((a, b) => 31 * a + b)
    }

    @scala.inline
    implicit class LineageNodeMutableBuilder[Self <: LineageNode] (val t: Self with LineageNode) extends AnyVal {
      @scala.inline
      def setKey(value: String): Self = StObject.set(t, "key", value.asInstanceOf[js.Any])

      @scala.inline
      def setExpanded(value: Boolean): Self = StObject.set(t, "expanded", value.asInstanceOf[js.Any])

      @scala.inline
      def setVisible(value: Boolean): Self = StObject.set(t, "visible", value.asInstanceOf[js.Any])

      @scala.inline
      def setGroup(value: String): Self = StObject.set(t, "group", value.asInstanceOf[js.Any])

      @scala.inline
      def setGroupUndefined: Self = StObject.set(t, "group", js.undefined)
    }
  }

  // GROUP node in the diagram
  @js.native
  trait LineageGroup extends LineageNode {
    val caption: String = js.native
    val isGroup: Boolean = js.native
  }

  implicit val customLineageGroupEquality: Equality[LineageGroup] = new Equality[LineageGroup] {
    override def areEqual(a: LineageGroup, b: Any): Boolean =
      customLineageNodeEquality.areEqual(a, b)
  }

  object LineageGroup {
    @scala.inline
    def apply(key: String, caption: String, visible: Boolean, expanded: Boolean): LineageGroup = {
      val __obj = js.Dynamic.literal(
        key = key.asInstanceOf[js.Any],
        `type` = "Diagram group".asInstanceOf[js.Any],
        caption = caption.asInstanceOf[js.Any],
        visible = visible.asInstanceOf[js.Any],
        isGroup = true.asInstanceOf[js.Any],
        expanded = expanded.asInstanceOf[js.Any]
      )
      __obj.asInstanceOf[LineageGroup]
    }

    @scala.inline
    implicit class LineageGroupMutableBuilder[Self <: LineageGroup] (val t: Self with LineageGroup) extends AnyVal {

      @scala.inline
      def setCaption(value: String): Self = StObject.set(t, "caption", value.asInstanceOf[js.Any])

      @scala.inline
      def setExpanded(value: Boolean): Self = StObject.set(t, "expanded", value.asInstanceOf[js.Any])
    }
  }

  // TABLE node in the diagram
  @js.native
  trait LineageTable extends LineageNode {
    val name: String = js.native
    val fields: js.Array[LineageColumn] = js.native
    val caption: String = js.native
    val sourceId: js.UndefOr[Int] = js.native
    val linefrom: js.UndefOr[Int] = js.native
    val columnfrom: js.UndefOr[Int] = js.native
    val lineto: js.UndefOr[Int] = js.native
    val columnto: js.UndefOr[Int] = js.native
  }

  implicit val customLineageTableEquality: Equality[LineageTable] = new Equality[LineageTable] {
    override def areEqual(a: LineageTable, b: Any): Boolean =
      customLineageNodeEquality.areEqual(a, b)
  }

  object LineageTable {
    @scala.inline
    def apply(key: String, t: String, name: String, visible: Boolean, caption: String, expanded: Boolean): LineageTable = {
      val __obj = js.Dynamic.literal(
        `type` = t.asInstanceOf[js.Any],
        name = name.asInstanceOf[js.Any],
        visible = visible.asInstanceOf[js.Any],
        caption = caption.asInstanceOf[js.Any],
        expanded = expanded.asInstanceOf[js.Any],
        key = key.asInstanceOf[js.Any]
      )
      __obj.asInstanceOf[LineageTable]
    }

    @scala.inline
    implicit class LineageTableMutableBuilder[Self <: LineageTable] (val t: Self with LineageTable) extends AnyVal {

      @scala.inline
      def setName(value: String): Self = StObject.set(t, "name", value.asInstanceOf[js.Any])

      @scala.inline
      def setCaption(value: String): Self = StObject.set(t, "caption", value.asInstanceOf[js.Any])

      @scala.inline
      def setExpanded(value: Boolean): Self = StObject.set(t, "expanded", value.asInstanceOf[js.Any])

      @scala.inline
      def setSourceId(value: Int): Self = StObject.set(t, "sourceId", value.asInstanceOf[js.Any])
      @scala.inline
      def setSourceIdUndefined: Self = StObject.set(t, "sourceId", js.undefined)

      @scala.inline
      def setLinefrom(value: Int): Self = StObject.set(t, "linefrom", value.asInstanceOf[js.Any])
      @scala.inline
      def setLinefromUndefined: Self = StObject.set(t, "linefrom", js.undefined)

      @scala.inline
      def setLineto(value: Int): Self = StObject.set(t, "lineto", value.asInstanceOf[js.Any])
      @scala.inline
      def setLinetoUndefined: Self = StObject.set(t, "lineto", js.undefined)

      @scala.inline
      def setColumnfrom(value: Int): Self = StObject.set(t, "columnfrom", value.asInstanceOf[js.Any])
      @scala.inline
      def setColumnfromUndefined: Self = StObject.set(t, "columnfrom", js.undefined)

      @scala.inline
      def setColumnto(value: Int): Self = StObject.set(t, "columnto", value.asInstanceOf[js.Any])
      @scala.inline
      def setColumntoUndefined: Self = StObject.set(t, "columnto", js.undefined)
    }
  }

  // COLUMN node in the diagram
  @js.native
  trait LineageColumn extends LineageItem {
    val key: String = js.native
    val `type`: String = js.native
    val expanded: Boolean = js.native
    val name: String = js.native
    val color: String = js.native
    val isstruct: Boolean = js.native
    val tablekey: String = js.native
    val figure: String = js.native
    val indent: Int = js.native
    val struct: js.Array[LineageColumn] = js.native
  }

  implicit val customLineageColumnEquality: Equality[LineageColumn] = new Equality[LineageColumn] {
    override def areEqual(a: LineageColumn, b: Any): Boolean =
      customLineageNodeEquality.areEqual(a.asInstanceOf[LineageNode], b)
  }

  object LineageColumn {
    @scala.inline
    def apply(key: String, t: String, name: String, color: String, expanded: Boolean, tablekey: String, figure: String, indent: Int, struct: js.Array[LineageColumn]): LineageColumn = {
      val __obj = js.Dynamic.literal(
        key = key.asInstanceOf[js.Any],
        `type` = t.asInstanceOf[js.Any],
        name = name.asInstanceOf[js.Any],
        color = color.asInstanceOf[js.Any],
        isstruct = (struct.length > 0).asInstanceOf[js.Any],
        expanded = expanded.asInstanceOf[js.Any],
        tablekey = tablekey.asInstanceOf[js.Any],
        figure = figure.asInstanceOf[js.Any],
        indent = indent.asInstanceOf[js.Any],
        struct = struct.asInstanceOf[js.Any]
      )
      __obj.asInstanceOf[LineageColumn]
    }

    @scala.inline
    implicit class LineageColumnOps[Self <: LineageColumn] (val t: Self with LineageColumn) extends AnyVal {
      @scala.inline
      def getLineageNodeType: NodeType = NodeType.COLUMN

      @scala.inline
      def isGroup: Boolean = false

      @scala.inline
      def isTable: Boolean = false

      @scala.inline
      def isColumn: Boolean = true
    }

    @scala.inline
    implicit class LineageColumnMutableBuilder[Self <: LineageColumn] (val t: Self with LineageColumn) extends AnyVal {

      @scala.inline
      def setName(value: String): Self = StObject.set(t, "name", value.asInstanceOf[js.Any])

      @scala.inline
      def setColor(value: String): Self = StObject.set(t, "color", value.asInstanceOf[js.Any])

      @scala.inline
      def setIsstruct(value: Boolean): Self = StObject.set(t, "isstruct", value.asInstanceOf[js.Any])

      @scala.inline
      def setExpanded(value: Boolean): Self = StObject.set(t, "expanded", value.asInstanceOf[js.Any])

      @scala.inline
      def setTablekey(value: String): Self = StObject.set(t, "tablekey", value.asInstanceOf[js.Any])

      @scala.inline
      def setFigure(value: String): Self = StObject.set(t, "figure", value.asInstanceOf[js.Any])

      @scala.inline
      def setIndent(value: Int): Self = StObject.set(t, "indent", value.asInstanceOf[js.Any])

      @scala.inline
      def setStruct(value: js.Array[LineageColumn]): Self = StObject.set(t, "struct", value.asInstanceOf[js.Any])

      @scala.inline
      def setKey(value: String): Self = StObject.set(t, "key", value.asInstanceOf[js.Any])
    }
  }

  // link between two nodes
  trait LineageLinkSet {
    val links: js.Map[Int ,LineageLink]
  }

  object LineageLinkSet {
    def apply(): LineageLinkSet = new LineageLinkSet {
      val links = js.Map[Int, LineageLink]()
    }

    implicit class LineageNodeSetOps(val s: LineageLinkSet) extends AnyVal {
      def addUniqueItem(link: LineageLink) = {
        s.links += (link.hashLink() -> link)
        s
      }

      def unwrap: js.Array[LineageLink] =
        s.links.values.toJSArray
    }
  }
  @js.native
  trait LineageLink extends LineageItem {
    val key: String = js.native
    val from: String = js.native
    val to: String = js.native
    val fromPort: String = js.native
    val toPort: String = js.native
    val hi: Boolean = js.native
    val color: String = js.native
  }

  implicit val customLineageLinkEquality: Equality[LineageLink] = new Equality[LineageLink] {
    override def areEqual(a: LineageLink, b: Any): Boolean =
      b.asInstanceOf[LineageLink].hashLink() == a.hashLink()
  }

  object LineageLink {
    @scala.inline
    def apply(from: String, to: String, fromPort: String, toPort: String, hi: Boolean, color: String): LineageLink = {
      val __obj = js.Dynamic.literal(
        from = from.asInstanceOf[js.Any],
        to = to.asInstanceOf[js.Any],
        fromPort = fromPort.asInstanceOf[js.Any],
        toPort = toPort.asInstanceOf[js.Any],
        hi = hi.asInstanceOf[js.Any],
        color = color.asInstanceOf[js.Any]
      )
      __obj.asInstanceOf[LineageLink]
    }

    @scala.inline
    implicit class LineageLinkOps[Self <: LineageLink] (val t: Self with LineageLink) extends AnyVal {
      @scala.inline
      def hashLink(): Int =
        Seq(t.from, t.to, t.fromPort, t.toPort, t.color).map(_.hashCode())
        .foldLeft(0)((a, b) => 31 * a + b)
    }

    @scala.inline
    implicit class LineageLinkMutableBuilder[Self <: LineageLink] (val t: Self with LineageLink) extends AnyVal {

      @scala.inline
      def setFrom(value: String): Self = StObject.set(t, "from", value.asInstanceOf[js.Any])

      @scala.inline
      def setTo(value: String): Self = StObject.set(t, "to", value.asInstanceOf[js.Any])

      @scala.inline
      def setFromPort(value: String): Self = StObject.set(t, "fromPort", value.asInstanceOf[js.Any])

      @scala.inline
      def setToPort(value: String): Self = StObject.set(t, "toPort", value.asInstanceOf[js.Any])

      @scala.inline
      def setHi(value: Boolean): Self = StObject.set(t, "hi", value.asInstanceOf[js.Any])

      @scala.inline
      def setColor(value: String): Self = StObject.set(t, "color", value.asInstanceOf[js.Any])
    }
  }
}
