/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client2

import adt.TreeExplorerADT

object TreeExplorerOps {
  import adt.PropertyPanelADT.KeyValue
  import PropertyPanel.getProperties

  implicit class explorerProperties(node:TreeExplorerADT.TreeNode) {
    def properties: String =
      val props =
        KeyValue("Type: ", s"${node.`type`}", None) ::
        KeyValue("Key: ", node.key, None) ::
        KeyValue("Name : ", node.name, None) ::
        node.data.map( KeyValue( _, _, None) )
      PropertyPanel.getProperties( props )
  }
}
