/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client2.objectbrowser

import com.dalineage.common
import common.adt.ADT

import scala.concurrent.Future
import scala.concurrent.Promise
import scala.concurrent.ExecutionContext.Implicits.global
import scala.util.Success
import scala.util.Failure

import org.scalajs.dom
import scala.scalajs.js

import util.chaining._

import upickle.default._

import cats.syntax.all._

import com.dalineage.client2

import client2.PropertyPanel

object ObjectBrowser {
  import scala.scalajs.js.JSConverters._
  import common.adt.ObjectBrowserADT.TreeNode

  implicit val positionRW: ReadWriter[ADT.Position] = macroRW
  implicit val rangeRW: ReadWriter[ADT.Range] = macroRW
  implicit val treeNodeRW: ReadWriter[TreeNode] = macroRW

  trait ObjectBrowserUserAction

  case class KeyboardEvent(event: dom.KeyboardEvent) extends ObjectBrowserUserAction
  case class SelectCode(range: ADT.Range) extends ObjectBrowserUserAction
  case class LoadQueryLineage(range: ADT.Range) extends ObjectBrowserUserAction
  case class SelectNode(key: String) extends ObjectBrowserUserAction

  val rootKey = common.adt.ObjectBrowserADT.rootKey

  def a(txt: String, code:Int, tooltip: String): String =
    s"""<a class="button" href="javascript:but($code);">$txt<span class="tooltiptext">$tooltip</span></a>"""

  val searchHelpText = s"<b>s</b> to ${a("search", 83, "Search database objects and source files")}"
  val defaultHelpText = s"Use Arrow keys to navigate, $searchHelpText"
  val showCodeHelpText = s"Press ENTER to view code, $searchHelpText"

  def setupDatabase(batchId: String): Future[dom.IDBDatabase] =
    IndexedDB.setupDatabase("dbimage", "image" :: "sources" :: Nil, batchId)

  def open(
      contentDiv: dom.html.Div,
      user: String,
      batchId: String,
      userActionFn: ObjectBrowserUserAction => Unit,
      updateHelpText: String => Unit) : Unit = {
      setupDatabase(batchId).onComplete {
        case Success(db) =>
          IndexedDB.setKeepAlive(db, batchId)
          dom.window.setInterval(() =>
            IndexedDB.setKeepAlive("image" :: "sources" :: Nil, batchId), 60 * 1000)
          println(s"database ok, getting objects for $user/$batchId")
          open(db, contentDiv, user, batchId, userActionFn, updateHelpText).onComplete {
            case Success(_) =>
              println(s"Object browser opened")
              updateHelpText("Object browser data is being loaded...")
              println(s"=== database closed ===")
              contentDiv.focus()
              db.close()
            case Failure(e) =>
              println(s"Object browser error $e")
          }
        case Failure(e) =>
          println(s"database error $e")
      }
  }

  def open(
      db: dom.IDBDatabase,
      contentDiv: dom.html.Div,
      user: String,
      batchId: String,
      userActionFn: ObjectBrowserUserAction => Unit,
      updateHelpText: String => Unit) : Future[Unit] = {

    val objectBrowserUserActionFn: TreeComponent.TreeComponentUserAction => Unit = { action =>
      action match
        case TreeComponent.SelectSearchResult(entry) =>
          val storePrefix = entry.split(":").head match
            case "image object" => "image"
            case "source code" => "sources"
            case e @ _ => throw new Exception(s"Unknown store $e")
          val key = entry.split(":").last
          val splitChar = storePrefix match
            case "image" => "."
            case "sources" => "/"
            case _ => throw new Exception(s"Unknown store $storePrefix")
          val keys = key.split(s"\\$splitChar").toList
          val rootNode = storePrefix match
            case "image" => TreeComponent.rootNodes.head
            case "sources" => TreeComponent.rootNodes.last

          val rootKey = rootNode.getAttribute("data-key")
          SelectableComponent.selectNode(rootNode)
          SelectableComponent.expandSelectedNode().onComplete {
            case Success(()) =>
              TreeComponent.expandNodes(rootNode, keys, splitChar)
              SelectableComponent.expandSelectedNode().onComplete {
                case Success(()) =>
                  updateHelpText("tbd")
                case Failure(e) =>
                  val msg = s"Search result loading error"
                  println(s"$msg $e")
                  updateHelpText(msg)
              }
            case Failure(e) =>
              val msg = s"Search result loading error"
              println(s"$msg $e")
              updateHelpText(msg)
          }
        case TreeComponent.SearchText(searchText) =>
          updateHelpText(s"Starting search $searchText")
          val imageStoreName = IndexedDB.getStoreName("image", batchId)
          val sourceStoreName = IndexedDB.getStoreName("sources", batchId)
          IndexedDB.openDatabase("image" :: "sources" :: Nil).onComplete {
            case Success(db) =>
              IndexedDB.getMatchingKeys(db, imageStoreName, searchText.toUpperCase).onComplete {
                case Success(keys) =>
                  IndexedDB.getMatchingKeys(db, sourceStoreName, searchText.toUpperCase)
                      .onComplete {
                    case Success(srcKeys) =>
                      val allKeys = keys.map("image object:" + _) ++ srcKeys.map("source code:" + _)
                      updateHelpText(s"${keys.size}+${srcKeys.size} objects found")
                      SearcheableComponent.setSearchResult(allKeys)
                    case Failure(e) =>
                      println(s"Search error $e")
                      updateHelpText("Search error")
                  }
                case Failure(e) =>
                  println(s"Search error $e")
                  updateHelpText("Search error")
              }
              println(s"=== database closed ===")
              db.close()
            case Failure(e) =>
              println(s"Search error $e")
              updateHelpText("Search error")
          }
        case TreeComponent.NodeSelected(node) =>
          val properties = node.getAttribute("data-properties")
          if( properties != "")
            PropertyPanel.setProperties(properties)
          val position = node.getAttribute("data-position")
          position match
            case null =>
              updateHelpText(defaultHelpText)
            case _ =>
              updateHelpText(showCodeHelpText)
        case TreeComponent.NodeOpened(node) => {
          Option(node.getAttribute("data-position"))
            .map(read[ADT.Range](_))
            .get
            .tap{ case range => userActionFn(SelectCode(range)) }
            .tap{ case range => userActionFn(LoadQueryLineage(range)) }

          Option(node.getAttribute("data-key"))
            .map(_.split("/"))
            .map(_.last)
            .map(key => key.endsWith("]") match
              case true => key
              case false =>
                Option(node.getAttribute("data-position"))
                  .map(read[ADT.Range](_))
                  .map(_.toString)
                  .map(position => s"$key[$position]")
                  .getOrElse(key)
            )
            .map(_.tap(key => dom.console.warn(s"select node in lineage '$key'")))
            .map(_.tap(key => userActionFn(SelectNode(key))))
        }
        case TreeComponent.KeyboardEvent(event) =>
          userActionFn(this.KeyboardEvent(event))
    }

    val loadChildrenFnDb: (dom.IDBDatabase,String,String) => Future[Seq[(String,TreeNode)]] =
      (db, storePrefix, key) =>
        println(s"loadChildrenFn $storePrefix $key")
        val promise = Promise[Seq[(String,TreeNode)]]()
        var store = IndexedDB.getStore(db, storePrefix, batchId)
        val storeName = IndexedDB.getStoreName(storePrefix, batchId)
        val request = store.get(key)
        request.onsuccess = { (event: dom.Event) =>

          val result = request.result.asInstanceOf[js.Dynamic]
          val node = read[TreeNode](result.value.asInstanceOf[String])

          val childrenAreLeafs = node.children.get.forall(_.isLeaf)

          val separator = ObjectBrowserData.separator(storePrefix)
          val childrenNodes = node.children.get.map { case node =>
            key == rootKey match
              case true => (node.name, node)
              case false => (s"$key$separator${node.name}", node)
          }


          if (childrenAreLeafs) {
            //i.e. node is database table. children(database columns) are leafs
            promise.success(childrenNodes)
          } else {

            //proceed with loading children
            val childrenKeys = childrenNodes.map(_._1)

            println(
              s"childrenKeys ${childrenKeys.size} ${childrenKeys.take(3).mkString(",") }...")

            val childrenRequest = IndexedDB.getMultipleKeys(db, storeName, childrenKeys)
            childrenRequest.onComplete {
              case Success(childrenResult) =>
                val res = childrenResult.zip(childrenKeys).map {
                  case (child, childKey) =>
                    val value = child.asInstanceOf[js.Dynamic].value

                    val childNode = read[TreeNode](value.asInstanceOf[String])
                    (childKey, childNode)
                }
                promise.success(res.toSeq)
              case Failure(e) =>
                promise.failure(new Exception(e.toString))
            }
          }
        }
        request.onerror = { (event: dom.Event) =>
          promise.failure(new Exception(request.error.toString))
        }
        promise.future

    val loadChildrenFn: (String,String) => Future[Seq[(String,TreeNode)]] =
      (storePrefix, key) =>
        val promise = Promise[Seq[(String,TreeNode)]]()
        IndexedDB.openDatabase("image" :: "sources" :: Nil).onComplete {
          case Success(db) =>
            loadChildrenFnDb(db, storePrefix, key).onComplete {
              case Success(children) =>
                db.close()
                promise.success(children)
              case Failure(e) =>
                println(s"loadChildrenFn error $e")
                promise.failure(new Exception(e.toString))
            }
          case Failure(e) =>
            println(s"database error $e")
            promise.failure(new Exception(e.toString))
        }
        promise.future

    def init() : Future[Unit] =
      println(s"Initalizing object browser")
      val promise = Promise[Unit]()
      try {
        val mainElement = contentDiv
        val imageRootNode = TreeComponent.createTreeNode("image", rootKey, "Database image", "")
        val srcRootNode = TreeComponent.createTreeNode("sources", rootKey, "Source codes", "")
        TreeComponent.init(
          loadChildrenFn,
          objectBrowserUserActionFn,
          mainElement,
          imageRootNode :: srcRootNode :: Nil)
        SelectableComponent.init()

        SelectableComponent.selectNode(imageRootNode)
        SelectableComponent.expandSelectedNode().onComplete {
          case Success(()) =>
            updateHelpText(defaultHelpText)
            println(s"Object browser initialized")
            promise.success(())
          case Failure(e) =>
            val msg = s"Object browser sources downloading error"
            println(s"$msg $e")
            updateHelpText(msg)
            promise.failure(new Exception(e.toString))
        }
      } catch {
        case e: Throwable =>
          println(s"Error opening object browser window $e")
          promise.failure(new Exception(e.toString))
      }
      promise.future

    contentDiv.innerHTML = s"""<div class="help">Loading object browser data</div>"""
    ObjectBrowserData.downloadObjectBrowserData(db, user, batchId, updateHelpText)
      .tap { _ => println(s"Object browser data loaded") }
      .flatMap { _ => init() }
  }
}
