package com.dalineage.client.old.explorer

import scala.collection.immutable._

import org.scalajs.dom
import dom.document
import dom.Window
import dom.html.Div
import scala.scalajs.js.timers._


import typings.gojs.{mod => go}
import scala.scalajs.js
import org.scalablytyped.runtime.StringDictionary

import com.dalineage.common
import common.adt.ExplorerDataADT._

import com.dalineage.client
import client.UserActions.{UserAction, ToolboxUserAction}
import client.UserActions
import client.ExplorerUserActions._
import client.WorkspaceUserActions._

import client.old.lineage.Lineage

import client.old.explorer.model.BatchTreeToolboxModel._
import client.old.explorer.model.ExplorerToolboxModel._

import client.old.explorer.adt.ExplorerADT

//Batch exploer - "Open..." dialog

object Explorer {
  import client.old.explorer.BatchTreeToolbox
  import client.old.explorer.ExplorerToolbox

  var selectedDir: Option[(String, List[String])] = None
  var selectedBatch: Option[(String, String)] = None
  var selectedView: Option[(String,  String)] = None

  var userActionFn: UserAction => Unit = null

  def open(explorerData: ExplorerData): Unit = {
    ExplorerWindow.open()
    BatchTree.open(explorerData)
    client.old.explorer.ExplorerToolbox.init(ExplorerWindow.toolboxDiv, userActionFn)
    BatchTreeToolbox.init(ExplorerWindow.batchTreeToolboxDiv, userActionFn)
  }

  def init(explorerDiv: dom.html.Div, userActionFn: UserAction => Unit) : Unit = {

    def openBatch(): Unit = {
      selectedBatch.map{ case (user, key) =>
        userActionFn(OpenBatch(user, key, selectedView.map(_._2))) }
    }

    val explorerUserActionFn: UserAction => Unit = { action =>
      action match {
      case UserActions.KeyboardEvent(event: dom.KeyboardEvent) => event.keyCode match {
        case 13 if event.ctrlKey =>
          close()
          openBatch()
        case 13 =>
          openBatch()
        case 37 | 38 | 39 | 40 if BatchTree.diagram.selection.size == 0 => BatchTree.selectFirst()
        case 37 => BatchTree.colapseSelected()
        case 38 => BatchTree.keyUp()
        case 39 => BatchTree.expandSelected()
        case 40 => BatchTree.keyDown()
        case 27 =>
          close()
          client.old.WorkspaceWindow.resize()
        case _ => println(s"keyboard event ${event.keyCode}")
      }
      case UserActions.Explorer.ExplorerNodeSelected(optSelectedNode) =>
        optSelectedNode match {
          case None =>
            selectedDir = None
            selectedBatch = None
            selectedView = None
            BatchTreeToolbox.updateButtons(false, true, RenameViewButton)
            BatchTreeToolbox.updateButtons(false, true, DeleteButton)
            BatchTreeToolbox.updateButtons(false, true, DownloadOriginalButton)
            ExplorerToolbox.updateButtons(false, true, LoadButton)
            ExplorerToolbox.updateButtons(false, true, LoadCloseButton)
            ExplorerToolbox.updateButtons(false, true, ReuploadButton)
            ExplorerToolbox.updateButtons(false, true, CSVLineageButton)
          case Some(selectedNode) =>
            val key = selectedNode.get("key").get.toString
            val typ = selectedNode.get("typ").get.toString
            typ match {
              case ExplorerADT.ViewNode.key =>
                selectedDir = None
                val parent = selectedNode.get("parent").get.toString
                val user = selectedNode.get("user").get.toString
                selectedBatch = Some((user, parent))
                val viewId = selectedNode.get("viewId").get.toString
                selectedView = Some((user, viewId))
                BatchTreeToolbox.updateButtons(true, true, RenameViewButton)
                BatchTreeToolbox.updateButtons(true, true, DeleteButton)
                BatchTreeToolbox.updateButtons(true, true, DownloadOriginalButton)
                ExplorerToolbox.updateButtons(true, true, LoadButton)
                ExplorerToolbox.updateButtons(true, true, LoadCloseButton)
                ExplorerToolbox.updateButtons(true, true, ReuploadButton)
                ExplorerToolbox.updateButtons(true, true, CSVLineageButton)
              case ExplorerADT.BatchNode.key =>
                selectedDir = None
                val user = selectedNode.get("user").get.toString
                selectedBatch = Some((user, key))
                selectedView = None
                BatchTreeToolbox.updateButtons(false, true, RenameViewButton)
                BatchTreeToolbox.updateButtons(true, true, DeleteButton)
                BatchTreeToolbox.updateButtons(true, true, DownloadOriginalButton)
                ExplorerToolbox.updateButtons(true, true, LoadButton)
                ExplorerToolbox.updateButtons(true, true, LoadCloseButton)
                ExplorerToolbox.updateButtons(true, true, ReuploadButton)
                ExplorerToolbox.updateButtons(true, true, CSVLineageButton)
              case ExplorerADT.DirNode.key =>
                selectedDir = selectedNode.get("key")
                  .map(_.toString)
                  .map(_.split("/").toList)
                  .map(dirs => (dirs.head, dirs.tail))

                selectedBatch = None
                selectedView = None
                BatchTreeToolbox.updateButtons(false, true, RenameViewButton)
                BatchTreeToolbox.updateButtons(selectedDir.map(!_._2.isEmpty).getOrElse(false), true, DeleteButton)
                BatchTreeToolbox.updateButtons(false, true, DownloadOriginalButton)
                ExplorerToolbox.updateButtons(false, true, LoadButton)
                ExplorerToolbox.updateButtons(false, true, LoadCloseButton)
                ExplorerToolbox.updateButtons(false, true, ReuploadButton)
                ExplorerToolbox.updateButtons(false, true, CSVLineageButton)
            }
        }
        client.old.explorer.InfoPanel.updateInfoPanel(ExplorerWindow.infoDiv, optSelectedNode)
      case LoadBatch() =>
        openBatch()
      case LoadBatchClose() =>
        close()
        openBatch()
      case RefreshExplorer() =>
        close()
        userActionFn(ShowExplorer())
      case DeleteSelected() =>
        selectedBatch
          .map((user, batchId) => (user, batchId, selectedView.map(_._2)))
          .fold
            (selectedDir.map((user, dirs) => userActionFn(DeleteDir(user, dirs))))
            ((user, batchId, optViewId) => userActionFn(DeleteBatch(user, batchId, optViewId)))

      case RenameSelectedView() =>
        selectedBatch
          .flatMap((_, batchId) => selectedView.map(batchId -> _._2))
          .map((batchId, viewId) => (batchId, viewId, dom.window.prompt("Enter new view name", viewId)))
          .foreach((batchId, viewId, newViewId) => userActionFn(RenameView(batchId, viewId, newViewId)))

      case ReuploadSelectedBatch() =>
        selectedBatch.map{ case (user, key) => userActionFn(ReuploadBatch(user, key)) }

      case CSVLineage() =>
        selectedBatch
          .map((user, batchId) => s"/web/csv_lineage/$user/$batchId")
          .foreach(downloadFile)

      case DownloadOriginal() =>
        selectedBatch
          .map((user, batchId) => s"/web/download/orig/$user/$batchId")
          .foreach(downloadFile)
      }
    }
    this.userActionFn = explorerUserActionFn

    ExplorerWindow.init(explorerDiv, explorerUserActionFn)
  }

  def downloadFile(filePath: String): Unit = {
    val aa = js.Dynamic.global.a
    aa.download = filePath.substring(filePath.lastIndexOf("/") + 1);
    val a = aa.asInstanceOf[dom.html.Anchor]
    a.href = filePath
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  def close(): Unit = { if (ExplorerWindow.isOpen()) { ExplorerWindow.close() } }
}

object ExplorerWindow extends client.ResizableElement {

  var infoDiv: Div = null
  var batchTreeToolboxDiv: Div = null
  var toolboxDiv: Div = null

  var batchTreeDiv: Div = null
  var leftDiv: Div = null
  var rightDiv: Div = null

  override
  def init(rootNode: dom.html.Element, userActionFn: UserAction => Unit): Unit = {
    this.rootNode = rootNode

    assert(!ExplorerWindow.isOpen()) //init != open

    infoDiv = document.createElement("div").asInstanceOf[Div]
    infoDiv.id = "info"
    infoDiv.style.width = px(300)
    infoDiv.style.height = px(300)
    infoDiv.style.backgroundColor = "white"

    InfoPanel.updateInfoPanel(infoDiv, None)

    batchTreeToolboxDiv = document.createElement("div").asInstanceOf[Div]

    batchTreeToolboxDiv.id = "treebuttons"
    batchTreeToolboxDiv.style.width = px(300)
    batchTreeToolboxDiv.style.height = px(100)
    batchTreeToolboxDiv.style.backgroundColor = client.CSS.backgroundColor("batch-tree")

    toolboxDiv = document.createElement("div").asInstanceOf[Div]

    toolboxDiv.id = "buttons"
    toolboxDiv.style.width = px(300)
    toolboxDiv.style.height = px(100)
    toolboxDiv.style.backgroundColor = client.CSS.backgroundColor("explorer-toolbox")

    client.old.explorer.ExplorerToolbox.init(toolboxDiv, userActionFn)

    batchTreeDiv = document.createElement("div").asInstanceOf[Div]

    batchTreeDiv.id = "explorerdiv"
    batchTreeDiv.style.width = px(300)
    batchTreeDiv.style.height = px(300)
    batchTreeDiv.style.backgroundColor = client.CSS.backgroundColor("batch-tree")
    batchTreeDiv.style.position = "relative"

    leftDiv = document.createElement("div").asInstanceOf[Div]
    leftDiv.style.cssFloat = "left"
    leftDiv.appendChild(batchTreeDiv)
    leftDiv.appendChild(client.old.explorer.ExplorerWindow.batchTreeToolboxDiv)

    rightDiv = document.createElement("div").asInstanceOf[Div]
    rightDiv.style = "display: flex; flex-direction: column;"
    rightDiv.appendChild(infoDiv)
    rightDiv.appendChild(toolboxDiv)

    rootNode.appendChild(leftDiv)
    rootNode.appendChild(rightDiv)


    BatchTree.init(batchTreeDiv, userActionFn)

    assert(!ExplorerWindow.isOpen()) //init != open

    //resize(rootNode.offsetWidth, rootNode.offsetHeight)
  }

  override
  def resize(rootNodeWidth: Double, rootNodeHeight: Double): Unit = {
    super.resize(rootNodeWidth, rootNodeHeight)

    val buttonDivHeight = client.old.Toolbox.toolboxHeight
    val infoHeight = rootNodeHeight - buttonDivHeight
    infoDiv.style.height = px(infoHeight)

    batchTreeToolboxDiv.style.width = ExplorerWindow.batchTreeDiv.style.width
    batchTreeToolboxDiv.style.height = px(client.old.Toolbox.toolboxHeight)

    val batchTreeWidthPenalty = 60
    val widthBorder = 3
    val heightBorder = 5
    var width = rootNodeWidth / 2 - widthBorder
    var height = rootNodeHeight - heightBorder


    infoDiv.style.width = px(width + batchTreeWidthPenalty)

    toolboxDiv.style.width =  px(width + batchTreeWidthPenalty)
    toolboxDiv.style.height = px(buttonDivHeight)

    batchTreeDiv.style.width = px(width - batchTreeWidthPenalty)
    leftDiv.style.width = infoDiv.style.width

    batchTreeDiv.style.height = px(height - client.old.Toolbox.toolboxHeight)
    leftDiv.style.height = px(height)

    leftDiv.style.width = batchTreeDiv.style.width
    leftDiv.style.height = px(height)
  }

  import client.old.WorkspaceWindow

  def isOpen(): Boolean = {
    WorkspaceWindow.rootNode.contains(rootNode)
  }

  def open(): Unit = {
    WorkspaceWindow.rootNode.insertBefore(rootNode, WorkspaceWindow.diagramDiv)
  }

  def close(): Unit = {
    if( isOpen() ) {
      WorkspaceWindow.rootNode.removeChild(rootNode)
    }
  }
}
