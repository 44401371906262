package com.dalineage.client.old.template

import scala.collection.immutable._

import typings.gojs.{mod => go}
import scala.scalajs.js
import org.scalajs.dom
import org.scalablytyped.runtime.StringDictionary

import com.dalineage.client.old.explorer

import com.dalineage.client
import client.UserActions.{UserAction, ToolboxUserAction}
import client.UserActions

object ToolboxTemplate {
  def apply(
      div: dom.html.Div,
      actions: List[ToolboxUserAction],
      userActionFn:UserAction => Unit): go.Diagram = {

    val diagram = new go.Diagram(div)

    val actionKeys = actions.map(a => (a.key, a)).toMap

    val nodeTemplate = new go.Node("Horizontal")

    /*
    val layout = new go.GridLayout()
    diagram.layout = layout
    */

    val fillFn:scala.scalajs.js.Function2[Any,Any,Any] = {
      case (v1, v2) => v1 match {
          case true => "gold"
          case false => "gray"
      }
    }

    val strokeWidthFn:scala.scalajs.js.Function2[Any,Any,Any] = {
      case (v1, v2) => 3
    }

    val strokeFigureFn:scala.scalajs.js.Function2[Any,Any,Any] = {
      case (v1, v2) => "Rectangle"
    }

    val fillBtnFn:scala.scalajs.js.Function2[Any,Any,Any] = {
      case (v1, v2) => v1 match {
          case true => "white"
          case false => "lightgray"
      }
    }

    val button = go.GraphObject.make[go.Panel]("Button")
    button.margin = 2
    button.stretch = go.GraphObject.Fill
    button.bind(new go.Binding("visible", "visible"))
    button.bind(new go.Binding("ButtonBorder.fill", "enabled", fillBtnFn))
    button.bind(new go.Binding("ButtonBorder.stroke", "enabled", fillFn))
    button.bind(new go.Binding("ButtonBorder.strokeWidth", "enabled", strokeWidthFn))
    button.bind(new go.Binding("ButtonBorder.figure", "enabled", strokeFigureFn))

    val textBlock = new go.TextBlock
    textBlock.font = "bold 14px sans-serif"
    textBlock.wrap = go.TextBlock.None
    textBlock.bind(new go.Binding("text", "text"))

    button.add(textBlock)

    nodeTemplate.add(button)
    diagram.nodeTemplate = nodeTemplate

    val clickEvent:js.Function2[go.InputEvent,nodeTemplate.type,Unit] = {
      case (inputEvent, param2) =>
        val panel: go.Panel = param2
        val data: StringDictionary[_] = panel.data.asInstanceOf[StringDictionary[_]]
        val action: String = data.get("key").get.toString
        val userAction = actionKeys(action)
        userActionFn(userAction)
    }
    nodeTemplate.click = clickEvent

    nodeTemplate.bind(new go.Binding("location", "location").makeTwoWay())

    val doKeyDownFn: js.Function2[go.InputEvent,diagram.type,Unit] = {
      case (inputEvent, param2) =>
        val e = diagram.lastInput.event.asInstanceOf[dom.KeyboardEvent]
        userActionFn(UserActions.KeyboardEvent(e))
    }
    diagram.commandHandler.set("doKeyDown", doKeyDownFn)

    diagram
  }
}
