/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client

import org.scalajs.dom
import org.scalajs.dom.document
import org.scalajs.dom.html

import scala.scalajs.js.annotation.JSExportTopLevel

import scala.concurrent.Promise
import scala.concurrent.Future
import scala.util.{Failure, Success}
import scala.concurrent.ExecutionContext.Implicits.global

import com.dalineage.common
import common.adt.TreeComponentADT._
import common.adt.ADT
import PropertyPanel.{table,tr,tr_}
import UserActions.UserAction

import upickle.default._

object TreeComponent {

  implicit val positionRW: ReadWriter[ADT.Position] = macroRW
  implicit val rangeRW: ReadWriter[ADT.Range] = macroRW
  implicit val treeNodeRW: ReadWriter[TreeNode] = macroRW

  var loadChildrenFn: (String, String) => Future[Seq[(String, TreeNode)]] = _
  var userActionFn: UserAction => Unit = _

  var rootNodes: List[html.Element] = _
  def rootNode = rootNodes.head
  var treeRoot: html.Element = _
  var searchDiv: html.Element = _
  var mainElement: html.Element = _

  def init(
    loadChildrenFn: (String, String) => Future[Seq[(String, TreeNode)]],
    useraactionFn: UserAction => Unit,
    mainElement: html.Element,
    rootNodes: List[html.Element]): Unit = {
    this.loadChildrenFn = loadChildrenFn
    this.userActionFn = useraactionFn

    this.rootNodes = rootNodes

    val treeRoot = document.createElement("ul").asInstanceOf[html.Element]
    this.treeRoot = treeRoot
    rootNodes.foreach { rootNode =>
      treeRoot.appendChild(rootNode)
    }

    val searchDiv = document.createElement("div").asInstanceOf[html.Form]
    searchDiv.style.display = "none"
    this.searchDiv = searchDiv

    mainElement.innerHTML = ""
    mainElement.appendChild(searchDiv)
    mainElement.appendChild(treeRoot)
    mainElement.style.overflowY = "auto"
    mainElement.tabIndex = 1
    this.mainElement = mainElement
  }

  def focus(): Unit = Option(mainElement).map(_.focus())

  def createTreeNode(store: String, key: String, caption: String, properties: String)
      : html.Element = {
    val node = document.createElement("li").asInstanceOf[html.Element]
    node.className = "tree-node"
    node.setAttribute("store", store)
    node.setAttribute("data-key", key)
    node.setAttribute("data-properties", properties)
    node.classList.remove("expanded")

    val expandButton = document.createElement("span").asInstanceOf[html.Span]
    expandButton.className = "expand-collapse-sign"
    expandButton.textContent = "+"
    expandButton.addEventListener("click", { (_: dom.Event) =>
      toggleExpandCollapse(node)
    })

    val captionElement = document.createElement("span").asInstanceOf[html.Span]
    captionElement.textContent = caption

    val childrenContainer = document.createElement("ul").asInstanceOf[html.Element]
    childrenContainer.className = "children-container unloaded"

    node.appendChild(expandButton)
    node.appendChild(captionElement)
    node.appendChild(childrenContainer)

    val selectNode = { (event: dom.Event) =>
      SelectableComponent.selectNode(node)
    }

    captionElement.addEventListener("click", selectNode)

    node
  }

  def isExpanded(node: html.Element): Boolean = {
    node.classList.contains("expanded")
    /*
    val childrenContainer = node.querySelector(".children-container").asInstanceOf[html.Element]
    childrenContainer.style.display == "block"
    */
  }

  def expandNodes(node: html.Element, keys: Seq[String], separator: String): Future[Unit] = {
    val promise = Promise[Unit]()
    val parentKey = node.getAttribute("data-key") match {
      case common.adt.TreeComponentADT.rootKey => ""
      case key => key + separator
    }
    val key = s"${parentKey}${keys.head}"
    expandNode(node, key, node.getAttribute("store"), keys.size == 1).onComplete {
      case Success(childNode) =>
        if (keys.tail.isEmpty) {
          promise.success(())
        } else {
          expandNodes(childNode, keys.tail, separator)
        }
      case Failure(exception) =>
        println(s"Failed to expand nodes: $exception")
        promise.failure(exception)
    }
    promise.future
  }

  def expandNode(
        parentNode: html.Element,
        childKey: String,
        store: String, selectOnly: Boolean) : Future[html.Element] = {
    val promise = Promise[html.Element]()
    val childrenContainer =
      parentNode.querySelector(".children-container").asInstanceOf[html.Element]
    val children = childrenContainer.querySelectorAll(".tree-node")
    val optChildNode = children.find(_.getAttribute("data-key") == childKey)
      .map(_.asInstanceOf[html.Element])

    val parentKey = parentNode.getAttribute("data-key")

    assert(isExpanded(parentNode), s"Parent node $parentKey must be expanded ($childKey)")

    optChildNode match
      case Some(childNode) =>
        SelectableComponent.selectNode(childNode)
        if( selectOnly ) //don't expand leaves
          promise.success(childNode)
        else
          SelectableComponent.expandSelectedNode().onComplete {
            case Success(_) =>
              assert(TreeComponent.isExpanded(childNode),
                s"!!!!Child node $childKey must be expanded")
              promise.success(childNode)
            case Failure(exception) =>
              promise.failure(exception)
          }
      case None => promise.failure(new Exception(s"Child node $childKey not found"))
    promise.future
  }

  def toggleExpandCollapse(node: html.Element): Future[Unit] = {
    val promise = Promise[Unit]()
    val childrenContainer = node.querySelector(".children-container").asInstanceOf[html.Element]
    val expandButton = node.querySelector(".expand-collapse-sign").asInstanceOf[html.Span]

    node.classList.toggle("expanded")

    val isUnloaded = childrenContainer.classList.contains("unloaded")

    import common.adt.TreeComponentADT.PropertyName

    if (isUnloaded) {
      val key = node.getAttribute("data-key")
      val store = node.getAttribute("store")
      val childrenf = loadChildrenFn(store, key)
      childrenf.onComplete {
        case Success(children) =>
          if (children.isEmpty) {
            expandButton.classList.remove("expand-collapse-sign")
            expandButton.classList.add("no-expand-collapse-sign")
          } else {
            children.foreach { case (childKey, TreeNode(typ, _, childCaption,
                                     properties, optGrandChilds, pos)) =>
              val propList =
                (PropertyName.ObjectType, ObjectType(typ).toString) ::
                (PropertyName.ObjectKey, childKey) ::
                (PropertyName.ObjectName, childCaption) ::
                (PropertyName.CodePosition, pos.map(p => s"[${p.toString}]").getOrElse("N/A")) ::
                properties.toList.map { case (k, v) => (PropertyName(k), v) }

              val trList =
                propList
                  .map {
                    case (k, v) if k.id == PropertyName.AnalyzerError.id => (k, v, Some("aerr"))
                    case (k, v) if k.id == PropertyName.ParserError.id => (k, v, Some("perr"))
                    case (k, v) => (k, v, None)
                  }
                  .map { case (k,v, id) => tr_(k, v, id, true) }

              val position = pos match
                case Some(range) => Some(range)
                case None =>
                  import scala.util.matching.Regex
                  val pattern: Regex = """([\w-]+)(?:\[(\d+);(\d+)\|(\d+)-(\d+)\|(\d+)\])""".r
                  childCaption match
                    case pattern(name, sourceId, startRow, startCol, endRow, endCol)
                      if sourceId == null =>
                        println(s"name $name")
                        println(s"sourceId $sourceId")
                        println(s"startRow $startRow")
                        println(s"startCol $startCol")
                        println(s"endRow $endRow")
                        println(s"endCol $endCol")
                        None

                    case pattern(name, sourceId, startRow, startCol, endRow, endCol) =>
                      println(s"name $name")
                      println(s"sourceId $sourceId")
                      println(s"startRow $startRow")
                      println(s"startCol $startCol")
                      println(s"endRow $endRow")
                      println(s"endCol $endCol")
                      val from = ADT.Position(startRow.toInt, startCol.toInt)
                      val to = ADT.Position(endRow.toInt, endCol.toInt)
                      val range = ADT.Range(sourceId.toInt , from, to)
                      Some(range)

                    case _ =>
                      println(s"no match childKey ${childKey}")
                      None


              val propStr = table( trList:_* )
              val childNode = createTreeNode(store, childKey, childCaption, propStr)
              if( propList.exists{ case (k, v) => k.id == PropertyName.AnalyzerError.id } )
                childNode.children(1).classList.add("analyzer-error")
              if( propList.exists{ case (k, v) => k.id == PropertyName.ParserError.id } )
                childNode.children(1).classList.add("parser-error")
              position.map { range =>
                childNode.setAttribute("data-position", write(range))
              }
              val exBut = childNode.querySelector(".expand-collapse-sign").asInstanceOf[html.Span]
              if (optGrandChilds.map(_.isEmpty).getOrElse(false)) {
                exBut.textContent = ""
                exBut.classList.remove("expand-collapse-sign")
                exBut.classList.add("no-expand-collapse-sign")
              }
              childrenContainer.appendChild(childNode)
            }
          }
          childrenContainer.classList.remove("unloaded")
          promise.success(())
        case Failure(exception) =>
          println(s"Failed to load children for node $key: $exception")
          promise.failure(exception)
      }
    }

    val isExpanded = node.className.contains("expanded")
    val isEmpty = expandButton.className.contains("no-expand-collapse-sign")
    if (isEmpty) {
      expandButton.textContent = ""
      childrenContainer.style.display = "none"
    } else {
      if (isExpanded) {
        expandButton.textContent = "-"
      } else {
        expandButton.textContent = "+"
      }
    }
    promise.future
  }
}

object Main {
  import TreeComponent._

  val typ = common.adt.TreeComponentADT.ObjectType.DBColumn.id
  val nodeA = TreeNode(typ, Some("Node A"), "Node A", Map.empty, None)
  val nodeB = TreeNode(typ, Some("Node B"), "Node B", Map.empty, None)

  val loadChildrenFn: (String, String) => Future[Seq[(String,TreeNode)]] = (store, key) => {
    Future {
      if (key.contains("2"))
        Seq()
      else
        Seq(
          (s"$key-1", TreeNode(typ, Some(s"Child of $key - 1"), s"Child of $key - 1", Map.empty, Some(List(nodeA,nodeB)))),
          (s"$key-2", TreeNode(typ, Some(s"Child of $key - 2"), s"Child of $key - 2", Map.empty, Some(List()))),
          (s"$key-3", TreeNode(typ, Some(s"Child of $key - 3"), s"Child of $key - 3", Map.empty, Some(List(nodeA,nodeB))))
        )
    }
  }

  var userActionFn: UserAction => Unit = {
    action => println(s"User action: $action")
  }

  def _main(args: Array[String]): Unit = {
    document.addEventListener("DOMContentLoaded", { (_: dom.Event) =>
      val mainElement = document.getElementById("main").asInstanceOf[html.Element]
      val rootNode = createTreeNode("image", "root", "Root Node", "Root Node Properties")
      TreeComponent.init(loadChildrenFn, userActionFn, mainElement, rootNode :: Nil)
      SelectableComponent.init()

      SelectableComponent.selectNode(TreeComponent.rootNode)
      SelectableComponent.expandSelectedNode()
    })
  }
}

object SelectableComponent {
  import TreeComponent._

  case class NodeSelected(node: html.Element) extends UserAction
  case class NodeOpened(node: html.Element) extends UserAction

  var selectedNode: Option[html.Element] = None

  def labelNode(key: String): html.Span = {
    val node = document.querySelector(s".tree-node[data-key='$key']").asInstanceOf[html.Element]
    labelNode(node)
  }

  def labelNode(node: html.Element): html.Span = {
    node.querySelector("span:nth-of-type(2)").asInstanceOf[html.Span]
  }

  def selectNode(node: html.Element): Unit = {
    selectedNode.foreach { prevNode =>
      labelNode(prevNode).classList.remove("selected-node")
    }
    selectedNode = Some(node)
    labelNode(node).classList.add("selected-node")
    labelNode(node).scrollIntoView(true)
    userActionFn(NodeSelected(node))
  }

  def expandSelectedNode(): Future[Unit] = {
    selectedNode.map { node =>
      if( isExpanded(node) )
        Promise.successful(()).future
      else
        toggleExpandCollapse(node)
    }.getOrElse(Promise.successful(()).future)
  }

  def collapseSelectedNode(): Future[Unit] = {
    selectedNode.map { node =>
      if (isExpanded(node))
        toggleExpandCollapse(node)
      else
        Promise.successful(()).future
    }.getOrElse(Promise.successful(()).future)
  }


  def parentNode(node: html.Element): Option[html.Element] = {
    if(node == treeRoot) None
    else {
      val parent = node.parentElement // must exist
      Option(parent.parentElement)    // most probably also exists
    }
  }

  def nextParentSibling(node: html.Element): Option[html.Element] = {
    parentNode(node).filter(_ != treeRoot).flatMap{ parent =>
      Option(parent.nextElementSibling).orElse(nextParentSibling(parent))
        .map(_.asInstanceOf[html.Element])
    }
  }

  def lastChild(node: html.Element): html.Element = {
    val childrenContainer = node.querySelector(".children-container").asInstanceOf[html.Element]
    childrenContainer.lastElementChild.asInstanceOf[html.Element]
  }

  def deepestExpandedNode(node: html.Element): html.Element = {
    val key = node.getAttribute("data-key")
    if (isExpanded(node)) {
      val child = lastChild(node)
      val key = child.getAttribute("data-key")
      deepestExpandedNode(child)
    } else {
      node
    }
  }

  def init(): Unit = {
    TreeComponent.mainElement.addEventListener("keydown", { (ev: dom.KeyboardEvent) =>
      ev.key match
        case "Enter" =>
          selectedNode.map { node =>
            TreeComponent.userActionFn(NodeOpened(node))
          }
          ev.preventDefault()
        case "ArrowRight" =>
          println(s"ArrowRight")
          expandSelectedNode()
          ev.preventDefault()
        case "ArrowLeft" =>
          println(s"ArrowLeft")
          collapseSelectedNode()
          ev.preventDefault()
        case "ArrowDown" =>
          println(s"ArrowDown")
          selectedNode.map { node =>
            if (isExpanded(node)) {
              Option(node.querySelector(".children-container .tree-node"))
                .map(_.asInstanceOf[html.Element])
                .map( firstChild => selectNode(firstChild) )
            } else {
              Option(node.nextElementSibling.asInstanceOf[html.Element])
                .orElse(nextParentSibling(node))
                .map{
                  nextNode =>
                    nextNode.isInstanceOf[html.Div] match
                      case true =>
                      case false =>
                        //assert(prevNode.isInstanceOf[html.UList])
                        selectNode(nextNode)
                }
            }
          }
          ev.preventDefault()
        case "ArrowUp" =>
          println(s"ArrowUp")
          selectedNode.map{ node =>
            Option(node.previousElementSibling.asInstanceOf[html.Element])
              .map{ prevNode =>
                if (isExpanded(prevNode))
                  deepestExpandedNode(prevNode)
                else
                  prevNode
              }
              .orElse(parentNode(node))
              .map( prevNode =>
                prevNode.isInstanceOf[html.Div] match
                  case true =>
                  case false =>
                    //assert(prevNode.isInstanceOf[html.UList])
                    selectNode(prevNode)
              )
          }
        case _ =>
          userActionFn(UserActions.KeyboardEvent(ev))
          ev.stopPropagation()
    })
  }
}

object SearcheableComponent {
  case class SearchText(query: String) extends UserAction
  case class SelectSearchResult(query: String) extends UserAction

  val dataList = document.createElement("datalist").asInstanceOf[html.DataList]
  dataList.setAttribute("id", "searchOptions")

  def setSearchResult(keys: Seq[String]): Unit = {
    dataList.innerHTML = ""
    keys.foreach { key =>
      val option = document.createElement("option")
      option.setAttribute("value", key)
      dataList.appendChild(option)
    }
  }


  def toggleSearch(): Unit = {
    val searchDiv = TreeComponent.searchDiv
    searchDiv.style.display match {
      case "none" =>
        searchDiv.style.display = "block"
        searchDiv.innerHTML = "<input type='text' placeholder='Search...'>"
        val searchInput = searchDiv.querySelector("input").asInstanceOf[html.Input]

        searchInput.setAttribute("id", "searchInput")
        searchInput.setAttribute("list", "searchOptions")
        searchInput.setAttribute("placeholder",
          "Enter directory, file, database, schema or table name")
        searchInput.style.width = "800px"

        searchInput.appendChild(dataList)

        searchInput.focus()
        searchInput.addEventListener("keydown", { (ev: dom.KeyboardEvent) =>
          ev.key match
            case "Enter" =>
              toggleSearch()
              TreeComponent.focus()
              ev.stopPropagation()
            case "Escape" =>
              toggleSearch()
              TreeComponent.focus()
              ev.stopPropagation()
            case _ =>
              ev.stopPropagation()
        })
        searchInput.addEventListener("change", { (ev: dom.Event) =>
          val query = searchInput.asInstanceOf[dom.html.Input].value
          TreeComponent.userActionFn(SelectSearchResult(query))
          ev.stopPropagation()
        })
        searchInput.addEventListener("input", { (ev: dom.Event) =>
          val query = searchInput.asInstanceOf[dom.html.Input].value
          TreeComponent.userActionFn(SearchText(query))
          ev.stopPropagation()
        })
      case _ => searchDiv.style.display = "none"
    }
  }

}
