/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client

object IDBDatabaseADT {
  opaque type GoJSStoreName <: String = String

  object GoJSStoreName {
    val nodes: GoJSStoreName = "nodes"
    val links: GoJSStoreName = "links"
  }
}