package com.dalineage.client.old.explorer.adt

object ExplorerADT {
  trait SelectedNode

  case class SelectedDir(name: String, path: List[String]) extends SelectedNode
  case class SelectedBatch(batchId: String, user: String) extends SelectedNode

  trait BatchTreeNodeType {
    val key: String = this.getClass.getSimpleName.replace("$", "")
  }

  case object BatchNode extends BatchTreeNodeType
  case object ViewNode extends BatchTreeNodeType
  case object DirNode extends BatchTreeNodeType

  def getType(str: String) = str match {
    case "BatchNode" => BatchNode
    case "ViewNode" => ViewNode
    case "DirNode" => DirNode
  }
}
