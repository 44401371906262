package com.dalineage.client.old.explorer

import scala.collection.immutable._

import org.scalajs.dom
import dom.html.Div

import com.dalineage.client
import BatchTree._

import com.dalineage.common
import common.adt.BatchInfoADT.BatchInfo

import org.scalablytyped.runtime.StringDictionary

import com.dalineage.client
import client.old.explorer.adt.ExplorerADT

object InfoPanel {

  def updateInfoPanel(div: Div, optData:Option[StringDictionary[_]]): Unit = {
    optData match {
      case None =>
        div.innerHTML = "Use arrow keys or mouse to navigate the tree"
      case Some(data) =>
        val id: String = data.get("key").get.toString
        val typ: String = data.get("typ").get.toString
        ExplorerADT.getType(typ) match {
          case ExplorerADT.ViewNode => div.innerHTML = data.get("info").get.toString
          case ExplorerADT.BatchNode => div.innerHTML = data.get("info").get.toString
          case ExplorerADT.DirNode => div.innerHTML =
            "Use arrow keys or mouse to navigate the tree<br>Select batch/view to see info"
        }
    }
  }
}
