/* (c) Dalineage, s.r.o. 2020-2024, all rights reserved */
package com.dalineage.client

import scala.collection.immutable._

import org.scalajs.dom

import typings.gojs.{mod => go}
import scala.scalajs.js
import org.scalablytyped.runtime.StringDictionary

import com.dalineage.common
import common.adt.ExplorerDataADT._

import com.dalineage.client
import client.diagram.DiagramOps.findNodeForKey
import client.UserActions.UserAction
import client.ExplorerUserActions._
import client.Window.SingleWindow


import client.TreeExplorer
import client.TreeExplorer.TreeNode

object BatchTree {

  case object ProcessedBatch extends TreeExplorer.NodeType
  case object SavedView extends TreeExplorer.NodeType
  case object Directory extends TreeExplorer.NodeType

  var selectedObject: Option[TreeExplorer.TreeNode] = None

  var userActionFn: UserAction => Unit = null

  val batchTreeWindow = Window.SingleWindow()
  batchTreeWindow.contentDiv.tabIndex = 5
  batchTreeWindow.div.id="batch-tree"

  var batchBrowserTreeExplorer: TreeExplorer.TreeExplorer = null

  val batchHelpText =
      s"<b>Enter</b>:${Workspace.a("View", 13, "Load and show visualization on the right panel")} diagram, " +
      s"${Workspace.a("Delete",46, "Delete this batch. Don't forget to reload page.")} batch, <b>d</b>:${Workspace.a("Download original", 68, "Download originally uploaded .zip file with Input Definition JSON and source codes.")}"
    val dirHelpText = s"Press <b>Delete key</b> to ${Workspace.a("delete", 46, "Delete allbatches in this tree")} directory recursively"

  val batchTreeDefaultHelpText = "Use <b>arrow keys</b> to navigate the tree"


  def selectPublic(): Unit =
    val node = batchBrowserTreeExplorer.expandElementsByKey("/public")
    batchBrowserTreeExplorer.selectNode(node.get)

  def init(batchTreeWindow: SingleWindow, userActionFn: UserAction => Unit): Unit = {
    val div = batchTreeWindow.contentDiv
    div.style.backgroundColor = client.CSS.backgroundColor("explorer-window")

    val helpText = "Use <b>arrow keys</b> to navigate the tree"
    batchTreeWindow.updateHelpText( helpText )

    val batchBrowserUserActionFn: UserAction => Unit = { action => action match {

      case TreeExplorer.NodeSelected(selectedNode) =>
        val key = selectedNode.key
        selectedNode.`type` match
          case BatchTree.SavedView =>
            batchTreeWindow.updateHelpText(batchHelpText)
          case BatchTree.ProcessedBatch =>
            selectedObject = Some(selectedNode)
            batchTreeWindow.updateHelpText(batchHelpText)
          case BatchTree.Directory =>
            selectedObject = Some(selectedNode)
            batchTreeWindow.updateHelpText(batchTreeDefaultHelpText)
          case _ =>
            selectedObject = None
            batchTreeWindow.updateHelpText(batchTreeDefaultHelpText)
        PropertyPanel.setProperties(selectedNode.properties)
      case UserActions.KeyboardEvent(event) if event.keyCode == 46 => //Delete
        selectedObject match
          case Some(node) if node.`type` == BatchTree.Directory =>
            val user = node.data.toMap[String, String].get("user").get
            userActionFn(WorkspaceUserActions.DeleteDir(user, node.key.split("/").toList))
          case Some(node) if node.`type` == BatchTree.ProcessedBatch =>
            val user = node.data.toMap[String, String].apply("user")
            userActionFn(WorkspaceUserActions.DeleteBatch(user, node.key, None))
          case _ =>
        //explorerWindow.updateHelpText("Deletion request sent, please refresh the page.")
      case TreeExplorer.NodeOpened(node) =>
        val properties = node.data.toMap
        val user = properties("user")
        val batchId = node.key
        val url =  s"?&user=$user&id=$batchId"
        println(s"url ${url}")
        dom.window.location.search = url
      case UserActions.KeyboardEvent(event) if event.keyCode == 68 && !event.ctrlKey => // d
        selectedObject match {
          case Some(node) =>
            val properties = node.data.toMap
            val user = properties("user")
            val batchId = node.key
            val file = s"/web/download/orig/$user/$batchId"
            downloadFile(file)
          case _ =>
        }
      case _ =>
        userActionFn(action)
    }}
    this.userActionFn = batchBrowserUserActionFn
  }

  def downloadFile(filePath: String): Unit = {
    val aa = js.Dynamic.global.a
    aa.download = filePath.substring(filePath.lastIndexOf("/") + 1);
    val a = aa.asInstanceOf[dom.html.Anchor]
    a.href = filePath
    dom.document.body.appendChild(a)
    a.click()
    dom.document.body.removeChild(a)
  }

  val unixTimestemp2String: Option[Long] => String = (timestamp) =>
    timestamp
      .map(java.util.Date(_))
      .map(_.toString())
      .getOrElse(" - ")

  def open(explorerData: ExplorerData): Unit = {

    def dirToTree(dir: ExplorerDir, path: String): TreeNode = {
      val children = dir.nodes.map{ child =>
        child match
          case subdir: ExplorerDir =>
            dirToTree(subdir, path + "/" + dir.name)
          case batch: ProcessedBatch =>
            val output = batch.info.output
            val properties = ("user", batch.info.user) :: //keep key "user", used elsewhere
              ("Uploaded", unixTimestemp2String(batch.info.uploaded)) ::
              ("Gojs diagram", output.generateGoJsOutput.toString) ::
              ("Object browser", output.saveObjectBrowserData.toString) ::
              ("Description", batch.info.description) ::
              ("Source codes", batch.info.batchProcessingStats.get.totalFiles.toString) ::
              ("Syntax parsing errors",
                batch.info.batchProcessingStats.get.parserErrors.toString) ::
              ("Lineage analyzer failures",
                batch.info.batchProcessingStats.get.analyzerErrors.toString) ::
              ("Columns found", batch.info.batchProcessingStats.get.columnCount.toString) ::
              ("Relations found", batch.info.batchProcessingStats.get.relationCount.toString) ::
              Nil
            TreeNode(ProcessedBatch, batch.info.id, batch.info.name, properties, Nil)
          //tbd views
      }
      TreeNode(Directory, path + "/" + dir.name, dir.name, Nil, children)
    }
    val children = explorerData.dirs.map{ child => dirToTree(child, "") }
    val treeData = TreeNode(Directory, "root", "Select batch", Nil, children)
    this.batchBrowserTreeExplorer = TreeExplorer.TreeExplorer(batchTreeWindow)
    batchBrowserTreeExplorer.renderTree(treeData, this.userActionFn)
  }

  def selectNodeByQuery(user: String, id: String): Unit =
    try {
      val node = batchBrowserTreeExplorer.expandElementsByKey(id)
      batchBrowserTreeExplorer.selectNode(node.get)
    } catch {
      case exc:Throwable =>
        println(s"selectNodeByQuery failed " + exc)
    }
}
