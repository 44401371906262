package com.dalineage.client.old.diagram

import org.scalajs.dom
import typings.gojs.{mod => go}

import com.dalineage.common
import common.adt.DiagramADT.DiagramData

import com.dalineage.client
import client.UserActions.UserAction
import client.diagram

import org.scalablytyped.runtime.StringDictionary
import com.dalineage.common.DiagramDataSerializer.GoJSSerializer

import com.dalineage.client.old.{ diagram => gojsdiagram }

object GoJSDiagram {

  var diagram: go.Diagram = null

  var diagramDiv: dom.html.Div = null

  var selectedColumn: Option[(StringDictionary[_],go.Panel)] = None

  def gridLayout(): Unit = setLayout(new go.GridLayout)
  def layeredDigraphLayout(): Unit = setLayout(new go.LayeredDigraphLayout)
  def circularLayout(): Unit = setLayout(new go.CircularLayout)

  def setLayout(layout: go.Layout): Unit = {
    val selection = diagram.selection
    selection.size match {
      case 0 => diagram.layout = layout
      case _ =>
        selection.map{ _ match {
          case group:go.Group => group.layout = layout
          case ee @ _ => println(s"W: no group selected!")
        }}
    }
  }

  var userActionFn: UserAction => Unit = null

  def open(diagramData: DiagramData): Unit = {
    // val collapsedTables: Set[String] = diagramData.collapsedTables
    val modelJson: String = diagramData.toGoJS().noSpaces
    val model: go.GraphLinksModel = go.Model.fromJson(modelJson).asInstanceOf[go.GraphLinksModel]
    showModel(model)
  }

  def init(div: dom.html.Div, userActionFn: UserAction => Unit): Unit = {
    diagramDiv = div

    val diagramUserActionFn: UserAction => Unit = { action =>
      action match {
        case client.diagram.GoJSDiagram.DiagramNodeSelected(optData) =>
          val optNode = optData.map{ data =>
            val key = data.get("key").toString
            if( data.get("linefrom").isDefined ) {
              val sourceId = data.get("sourceId").get.toString
              val linefrom: Int = data.get("linefrom").get.toString.toInt
              val columnfrom: Int = data.get("columnfrom").get.toString.toInt
              val lineto: Int = data.get("lineto").get.toString.toInt
              val columnto: Int = data.get("columnto").get.toString.toInt
              client.Editor.selectCode(sourceId, linefrom, columnfrom, lineto, columnto)
            }
          }

          userActionFn(action)

        case _ => userActionFn(action)
      }
    }


    this.userActionFn = diagramUserActionFn
    assert(diagramDiv != null, "Diagram div not initialized!")
    if( diagram == null ) {
      diagram = new go.Diagram(diagramDiv)
    }
  }

  def highlightColumn(col: go.Panel, hi: Boolean)(implicit diagram: go.Diagram): Unit = {
    col.background = if( hi ) "dodgerblue" else "white"
  }

  def selectNode(key: String): Unit =
    diagram.findNodeForKey(key) match {
      case null => dom.console.warn(s"Node not found '$key'!")
      case node: go.Node => selectNode(node)
    }

  def selectNode(node: go.Node): Unit =
    diagram.select(node)
    diagram.scrollToRect(node.actualBounds)

  def showModel(model: go.Model): Unit = {

    val diagramUserActionFn: UserAction => Unit = { action =>
      action match {
        case client.diagram.GoJSDiagram.DiagramNodeSelected(optData) =>
          optData.map{ _ =>
            selectedColumn.map{ case (_, col) => highlightColumn(col, false)(diagram) }
            selectedColumn = None
          }

        case client.diagram.GoJSDiagram.ColumnSelected(optData) =>
          selectedColumn.map{ case (_, col) => highlightColumn(col, false)(diagram) }
          selectedColumn = optData
          optData.map{ case (_, col) => highlightColumn(col, true)(diagram) }
          optData.map{ _ => diagram.clearSelection() }

        case client.UserActions.KeyboardEvent(event) if event.keyCode == 13 =>
          val selectedParts = diagram.selection
          selectedParts.each { part =>
            if (part.isInstanceOf[go.Group]) {
              val group = part.asInstanceOf[go.Group]
              group.isSubGraphExpanded = !group.isSubGraphExpanded
            }
          }

        case client.diagram.GoJSDiagram.ModelChanged(modelJson) =>

        case _ =>
      }
      userActionFn(action)
    }

    diagram.maxSelectionCount = 1

    diagram.nodeTemplate = client.diagram.LineageTemplate(diagramUserActionFn)(diagram)

    diagram.groupTemplate = client.diagram.GroupTemplate(diagramUserActionFn)

    diagram.linkTemplate = client.diagram.LinkTemplate()

    diagram.model = model
  }
}
